import React from 'react';
import { Form } from 'antd';
import { PROFILE_PIC_SUPPORTED_EXT } from '../../../utils/constants';
import { SET_PROFILE_PIC } from '../../../utils/functions';
import PhotoPlaceholder from '../../../assets/images/placeholder-image.png';

export default function PhotoUpload(props) {
    // Local states
    const [image, setImage] = React.useState(null);
    // Destructuring
    const {
        mode,
        onFileChange,
        inputName,
        photoBinary,
        photoUrl,
        label = 'Photo',
    } = props;
    // On component mount
    React.useEffect(() => {
        SET_PROFILE_PIC(photoBinary, photoUrl, setImage);
    }, [photoBinary, photoUrl]);
    return (
        <Form.Item label={label}>
            <div className="avatar">
                <div className="avatar--pic">
                    <img src={image || PhotoPlaceholder} alt="Profile" />
                </div>
                {mode !== 'view' && (
                    <div className="avatar--upload-button">
                        <label
                            htmlFor={inputName}
                            style={{
                                display: 'block',
                                maxWidth: '100%',
                                width: '100%',
                            }}
                        >
                            <div className="ant-btn ant-btn--fluid">Upload</div>
                            <input
                                hidden
                                type="file"
                                id={inputName}
                                name={inputName}
                                accept={PROFILE_PIC_SUPPORTED_EXT}
                                onChange={e => onFileChange(e, mode)}
                            />
                        </label>
                    </div>
                )}
            </div>
        </Form.Item>
    );
}
