import React from 'react';
// Import from dependencies
import { Form, Row, Col, Tabs } from 'antd';
// Import utils
// import axios from '../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../utils/functions';
// Import components
import AccountDetail from './AccountDetail/AccountDetail';
import InterestAndKista from './InterestAndKista/InterestAndKista';
import FineAndInterest from './FineAndInterest/FineAndInterest';

const LoanTypesForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        kistaPeriods,
        setKistaPeriods,
        accounts,
        values,
        viewValues,
        onValuesChange,
        onCheckboxChange,
        setDefaultValues,
    } = props;

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <Row gutter={20}>
            <Col span={18}>
                <Form
                    ref={ref}
                    layout="vertical"
                    onValuesChange={val => onValuesChange(val, mode)}
                    fields={[
                        {
                            name: 'acno',
                            value: values?.acno,
                        },
                        {
                            name: 'govCode',
                            value: values?.govCode,
                        },
                        {
                            name: 'typeCode',
                            value: values?.typeCode,
                        },
                        {
                            name: 'formedDate',
                            value:
                                values?.formedDate &&
                                ENG_ISO_TO_NEP(values?.formedDate),
                        },
                        {
                            name: 'acName',
                            value: values?.acName,
                        },
                        {
                            name: 'nepName',
                            value: values?.nepName,
                        },
                        {
                            name: 'serviceCharge',
                            value: values?.serviceCharge,
                        },
                        {
                            name: 'interestRate',
                            value: values?.interestRate,
                        },
                        {
                            name: 'passingRate',
                            value: values?.passingRate,
                        },
                        {
                            name: 'rebateSystem',
                            value: values?.rebateSystem,
                        },
                        {
                            name: 'rebateRate',
                            value: values?.rebateRate,
                        },
                        {
                            name: 'deductRebate',
                            value: values?.deductRebate,
                        },
                        {
                            name: 'interestRate',
                            value: values?.interestRate,
                        },
                        {
                            name: 'ips',
                            value: values?.ips,
                        },
                        {
                            name: 'lps',
                            value: values?.lps,
                        },
                        {
                            name: 'totalKista',
                            value: values?.totalKista,
                        },
                        {
                            name: 'kistaPeriod',
                            value: values?.kistaPeriod,
                        },
                        {
                            name: 'fineOrInt',
                            value: values?.fineOrInt,
                        },
                        {
                            name: 'directFineSystem',
                            value: values?.directFineSystem,
                        },
                        {
                            name: 'calcFineOn',
                            value: values?.calcFineOn,
                        },
                        {
                            name: 'typecode',
                            value: values?.typecode,
                        },
                        {
                            name: 'dd01',
                            value: values?.dd01,
                        },
                        {
                            name: 'dd02',
                            value: values?.dd02,
                        },
                        {
                            name: 'dd03',
                            value: values?.dd03,
                        },
                        {
                            name: 'dd04',
                            value: values?.dd04,
                        },
                        {
                            name: 'dd05',
                            value: values?.dd05,
                        },
                        {
                            name: 'dd06',
                            value: values?.dd06,
                        },
                        {
                            name: 'dd07',
                            value: values?.dd07,
                        },
                        {
                            name: 'dd08',
                            value: values?.dd08,
                        },
                        {
                            name: 'dd09',
                            value: values?.dd09,
                        },
                        {
                            name: 'ai001',
                            value: values?.ai001,
                        },
                        {
                            name: 'ai002',
                            value: values?.ai002,
                        },
                        {
                            name: 'ai003',
                            value: values?.ai003,
                        },
                        {
                            name: 'ai004',
                            value: values?.ai004,
                        },
                        {
                            name: 'ai005',
                            value: values?.ai005,
                        },
                        {
                            name: 'ai006',
                            value: values?.ai006,
                        },
                        {
                            name: 'ai007',
                            value: values?.ai007,
                        },
                        {
                            name: 'ai008',
                            value: values?.ai008,
                        },
                        {
                            name: 'ai009',
                            value: values?.ai009,
                        },
                        {
                            name: 'aA001',
                            value: values?.aA001,
                        },
                        {
                            name: 'aA002',
                            value: values?.aA002,
                        },
                        {
                            name: 'aA003',
                            value: values?.aA003,
                        },
                        {
                            name: 'aA004',
                            value: values?.aA004,
                        },
                        {
                            name: 'aA005',
                            value: values?.aA005,
                        },
                        {
                            name: 'aA006',
                            value: values?.aA006,
                        },
                        {
                            name: 'aA007',
                            value: values?.aA007,
                        },
                        {
                            name: 'aA008',
                            value: values?.aA008,
                        },
                        {
                            name: 'aA009',
                            value: values?.aA009,
                        },
                        {
                            name: 'ad001',
                            value: values?.ad001,
                        },
                        {
                            name: 'ad002',
                            value: values?.ad002,
                        },
                        {
                            name: 'ad003',
                            value: values?.ad003,
                        },
                        {
                            name: 'ad004',
                            value: values?.ad004,
                        },
                        {
                            name: 'ad005',
                            value: values?.ad005,
                        },
                        {
                            name: 'ad006',
                            value: values?.ad006,
                        },
                        {
                            name: 'ad007',
                            value: values?.ad007,
                        },
                        {
                            name: 'ad008',
                            value: values?.ad008,
                        },
                        {
                            name: 'ad009',
                            value: values?.ad009,
                        },
                    ]}
                >
                    <div className="block-general-titled--body">
                        <h3 className="colored-title">Account Detail</h3>

                        <AccountDetail
                            mode={mode}
                            accounts={accounts}
                            values={values}
                            viewValues={viewValues}
                        />

                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane
                                tab="Interest and Kista Detail  "
                                key="1"
                            >
                                <InterestAndKista
                                    mode={mode}
                                    kistaPeriods={kistaPeriods}
                                    setKistaPeriods={setKistaPeriods}
                                    values={values}
                                    onCheckboxChange={onCheckboxChange}
                                />
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                tab="Fine and Additional Interest Setting"
                                key="2"
                            >
                                <FineAndInterest />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Form>
            </Col>
        </Row>
    );
});

export default LoanTypesForm;
