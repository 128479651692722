import React from 'react';

import { Form, Select, Input, Row, Col, Radio, Checkbox } from 'antd';
import {
    GET_MEMBERS_BY_BRANCH,
    GET_STAFFS_BY_BRANCH,
} from '../../../utils/axios';
import Staffs from '../../Common/User/Staffs/Staffs';

export default function DepositRegistrationForm1(props) {
    // Destructuring
    const { mode, members, setMembers, values, owners, staffs, setStaffs } =
        props;

    // On component amount
    React.useEffect(() => {
        if (mode !== 'view')
            (async () => {
                const staffs = await GET_STAFFS_BY_BRANCH(
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                );
                setStaffs(staffs);
                const members = await GET_MEMBERS_BY_BRANCH(
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                );
                setMembers(members);
            })();
    }, [setMembers, mode, setStaffs]);
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Alone/Combined"
                        name="aloneOrCombine"
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value="Alone">Alone</Radio>
                            <Radio value="Combine">Combined</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={16}></Col>

                {values?.aloneOrCombine === 'Combine' && (
                    <>
                        <Col span={24}>
                            <h3 className="colored-title">
                                Additional operators
                            </h3>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="memberNo2">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberNO}
                                        >
                                            {member.memberNO}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="operator2">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberName}
                                        >
                                            {member.memberName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="opAddress2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="mobileNO2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="op2Sign">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="memberNo3">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberNO}
                                        >
                                            {member.memberNO}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="operator3">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberName}
                                        >
                                            {member.memberName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="opAddress3">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="mobileNO3">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="op3Sign">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="memberNo4">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberNO}
                                        >
                                            {member.memberNO}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="operator4">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members.map((member, i) => (
                                        <Select.Option
                                            key={i}
                                            value={member.memberName}
                                        >
                                            {member.memberName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="" name="opAddress4">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="mobileNO4">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="" name="op4Sign">
                                <Radio.Group name="op4Sign">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </>
                )}
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Different Owner"
                        name="isOwner"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                    <Form.Item
                        label=" Owner ID"
                        name="ownerNO"
                        rules={[
                            {
                                required: values?.isOwner,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            disabled={!values?.isOwner}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {owners.map((owner, i) => (
                                <Select.Option key={i} value={owner.ownerNO}>
                                    {owner.ownerNO}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={9}>
                    <Form.Item label="Owner's Name " name="ownerName">
                        <Select
                            placeholder="Select..."
                            disabled={!values?.isOwner}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {owners.map((owner, i) => (
                                <Select.Option key={i} value={owner.ownerName}>
                                    {owner.ownerName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        label="Address "
                        name="ownerFullAddress"
                        disabled={!values?.isOwner}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Staffs
                        staffs={staffs}
                        item="staffID"
                        label="Collector ID"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Staffs
                        staffs={staffs}
                        item="staffName"
                        label="Deposit Collector"
                    />
                </Col>
            </Row>
        </div>
    );
}
