import React from 'react';
// Import from dependencies
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import {
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
    verificationStatus as verificationStatusAtom,
    enterpriseDetail as enterpriseDetailAtom,
} from '../../../recoil/atoms';
import moment from 'moment';

export default function AppLayoutHeader() {
    // Global states
    const setVerificationStatus = useSetRecoilState(verificationStatusAtom);
    const enterpriseDetail = useRecoilValue(enterpriseDetailAtom);

    // Variables and constants
    const history = useHistory();
    const date = moment().format('dddd Do MMMM, YYYY');

    // Destructuring
    const { Header } = Layout;

    // Logout
    const handleLogout = () => {
        // Clear local storage
        localStorage.clear();
        // Set verification status
        setVerificationStatus('unauthorized');
        // Return to login screen
        history.push('/auth/login');
    };

    // Avatar menu
    const avatarMenu = (
        <Menu>
            <Menu.Item key="0" icon={<UserOutlined />}>
                View Profile
            </Menu.Item>
            <Menu.Item key="1" icon={<SettingOutlined />}>
                Settings
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="site-layout-background app-layout-header">
            <div className="app-layout-header__left">
                {enterpriseDetail?.companyName}
            </div>
            <div className="app-layout-header__right">
                <div className="app-layout-header__date-time">{date}</div>
                <div className="app-layout-header__username">Admin</div>
                <Dropdown overlay={avatarMenu} trigger={['click']}>
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                </Dropdown>
            </div>
        </Header>
    );
}
