import React from 'react';
import { Form, Row, Col, Input, Radio, Table } from 'antd';
import {
    CONTAINS_DIGITS_ONLY,
    CONTAINS_DIGITS_OR_DECIMAL_ONLY,
} from '../../../utils/functions';

export default function FineAndInterest(props) {
    const dataSource = [
        {
            key: '1',
            daysLessThan: 'dd01',
            interestDue: 'ai001',
            installmentDue: 'ad001',
            totalDue: 'aA001',
        },
        {
            key: '2',
            daysLessThan: 'dd02',
            interestDue: 'ai002',
            installmentDue: 'ad002',
            totalDue: 'aA002',
        },
        {
            key: '3',
            daysLessThan: 'dd03',
            interestDue: 'ai003',
            installmentDue: 'ad003',
            totalDue: 'aA003',
        },
        {
            key: '4',
            daysLessThan: 'dd04',
            interestDue: 'ai004',
            installmentDue: 'ad004',
            totalDue: 'aA004',
        },
        {
            key: '5',
            daysLessThan: 'dd05',
            interestDue: 'ai005',
            installmentDue: 'ad005',
            totalDue: 'aA005',
        },
        {
            key: '6',
            daysLessThan: 'dd06',
            interestDue: 'ai006',
            installmentDue: 'ad006',
            totalDue: 'aA006',
        },
        {
            key: '7',
            daysLessThan: 'dd07',
            interestDue: 'ai007',
            installmentDue: 'ad007',
            totalDue: 'aA007',
        },
        {
            key: '8',
            daysLessThan: 'dd08',
            interestDue: 'ai008',
            installmentDue: 'ad008',
            totalDue: 'aA008',
        },
        {
            key: '9',
            daysLessThan: 'dd09',
            interestDue: 'ai009',
            installmentDue: 'ad009',
            totalDue: 'aA009',
        },
    ];

    const columns = [
        {
            title: 'Due Days Less Than',
            dataIndex: 'daysLessThan',
            key: 'daysLessThan',
            render: name => (
                <Form.Item
                    name={name}
                    rules={[
                        {
                            max: 5,
                            message: 'Max 5 digits',
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || CONTAINS_DIGITS_ONLY(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'On Interest Due',
            dataIndex: 'interestDue',
            key: 'interestDue',
            render: name => (
                <Form.Item
                    name={name}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (+value < 0 || +value >= 100)
                                    return Promise.reject(
                                        new Error('Between 0 - 99.99')
                                    );
                                if (
                                    !value ||
                                    CONTAINS_DIGITS_OR_DECIMAL_ONLY(value)
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'On Installment Due',
            dataIndex: 'installmentDue',
            key: 'installmentDue',
            render: name => (
                <Form.Item
                    name={name}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (+value < 0 || +value >= 100)
                                    return Promise.reject(
                                        new Error('Between 0 - 99.99')
                                    );
                                if (
                                    !value ||
                                    CONTAINS_DIGITS_OR_DECIMAL_ONLY(value)
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'On Total Due',
            dataIndex: 'totalDue',
            key: 'totalDue',
            render: name => (
                <Form.Item
                    name={name}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (+value < 0 || +value >= 100)
                                    return Promise.reject(
                                        new Error('Between 0 - 99.99')
                                    );
                                if (
                                    !value ||
                                    CONTAINS_DIGITS_OR_DECIMAL_ONLY(value)
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
            ),
        },
    ];
    return (
        <div>
            <div>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            label="Fine/Interest"
                            name="fineOrInt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Radio.Group name="fineOrInt">
                                <Radio value="Fine">Fine</Radio>

                                <Radio value="Interest">
                                    Additional Interest
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={6}>
                        <Form.Item
                            label="Direct Fine System"
                            name="directFineSystem"
                        >
                            <Radio.Group name="directFineSystem">
                                <Radio value={true}>Yes</Radio>

                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}></Col>

                    <Col xs={24} sm={12} md={24}>
                        <Form.Item
                            label="Charge penalty on"
                            name="calcFineOn"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Radio.Group name="calcFineOn">
                                <Radio value="DI">Due Interest</Radio>
                                <Radio value="TP">Total Principle</Radio>
                                <Radio value="DP">Due Installment</Radio>
                                <Radio value="DINT">
                                    Due Installment and Interest
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={24}>
                        <Table
                            className="ant-table-wrapper--collapsed"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            size="middle"
                        />
                    </Col>

                    {/* <Col xs={24} sm={12} md={6}>
                        <Form.Item label="Due Days Less Than." name="dd01">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="On Interest Due." name="ai001">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="On Installement Due." name="ad0001">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="On Total Due." name="aA001">
                            <Input />
                        </Form.Item>
                    </Col> */}
                    {/*
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item name="accountNo">
                            <Input />
                        </Form.Item>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
}
