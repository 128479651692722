import React from 'react';
import { Form, Select, Input, Radio, Checkbox, Row, Col } from 'antd';

export default function DepositTypesSavingDetail(props) {
    // Destructuring
    const { mode, values, onCheckBoxChange } = props;

    const lateFineRsSelection = (
        <Form.Item name="fineType">
            <Select
                value="Amount"
                className="select-after"
                disabled={mode === 'view'}
            >
                <Select.Option value="Percent">%</Select.Option>
                <Select.Option value="Amount">Rs</Select.Option>
            </Select>
        </Form.Item>
    );

    const savingPeriodSelection = (
        <Form.Item
            name="savingPeriodType"
            rules={
                mode !== 'view' && [
                    {
                        required: true,
                        message: 'Required',
                    },
                ]
            }
        >
            <Select className="select-after" disabled={mode === 'view'}>
                <Select.Option value="Not Mentioned">
                    Not mentioned
                </Select.Option>
                <Select.Option value="Day">Day</Select.Option>
                <Select.Option value="Month">Month</Select.Option>
                <Select.Option value="Year">Year</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <div className="deposit-type-general-detail-form-view">
            <Row gutter={10}>
                <Col xs={24} xl={16}>
                    <Form.Item
                        label="Saving Interval"
                        name="savingType"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Radio.Group disabled={mode === 'view'}>
                            <Radio value="OneTime">One Time</Radio>
                            <Radio value="Random">Random</Radio>
                            <Radio value="Regular">Regular</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <div className="secondary-radio">
                        <Form.Item label="" name="regSavingPeriod">
                            <Radio.Group
                                disabled={
                                    values?.savingType !== 'Regular' ||
                                    mode === 'view'
                                }
                            >
                                <Radio value="None">None</Radio>
                                <Radio value="Daily">Daily</Radio>
                                <Radio value="Weekly">Weekly</Radio>
                                <Radio value="Bimonthly">Bimonthly</Radio>
                                <Radio value="Monthly">Monthly</Radio>
                                <Radio value="Quarterly">Quarterly</Radio>
                                <Radio value="HalfYearly">Half Yearly</Radio>
                                <Radio value="Yearly">Yearly</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Col>

                <Col xs={24} xl={8}>
                    <Form.Item
                        label="Style"
                        name="savingStatus"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Radio.Group disabled={mode === 'view'}>
                            <Radio value="Normal">Normal</Radio>
                            <Radio value="Fixed">Fixed</Radio>
                            <Radio value="Compulsory">Compulsory</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Saving Rate"
                        name="savingRate"

                        // labelCol={{ span: 24 }}
                    >
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Last day for payment"
                        name="lastDayForPmt"
                    >
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Late Fine" name="lateFine">
                        <Input
                            addonAfter={lateFineRsSelection}
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Account Opening Rate"
                        name="acOpeningRate"
                    >
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Minimum Balance" name="minBal">
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Minimum Saving Period"
                        name="savingPeriod"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input
                            addonAfter={savingPeriodSelection}
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Minimum Interest Balance"
                        name="minIntBal"
                    >
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Withdraw Limit Per Day"
                        name="maxDrawPerDay"
                    >
                        <Input addonAfter="times" disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Cheque Book System"
                        // name="chequeBookSystem"
                    >
                        <Checkbox
                            checked={values?.chequeBookSystem === 'Yes'}
                            name="chequeBookSystem"
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            {values?.chequeBookSystem}
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Overdraft Facility"
                        // valuePropName="checked"
                        // name="loanable"
                    >
                        <Checkbox
                            checked={values?.loanable}
                            name="loanable"
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            {values?.loanable ? 'Yes' : 'No'}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
