import React from 'react';
import { Form, Row, Col, Input } from 'antd';
import Provinces from '../Common/Geography/Provinces/Provinces';
import Districts from '../Common/Geography/Districts/Districts';
import VdcMun from '../Common/Geography/VdcMun/VdcMun';
import PhotoUpload from '../Common/PhotoUpload/PhotoUpload';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
// import 'nepali-datepicker-reactjs/dist/index.css';
import { ENG_ISO_TO_NEP } from '../../utils/functions';

const OfficeDetailsForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        values,
        onValuesChange,
        mode,
        provinces,
        getProvinces,
        districts,
        getDistricts,
        municipalities,
        getMunicipalities,
        onFileChange,
    } = props;

    // On component mount
    React.useEffect(
        () => {
            if (mode === 'edit') {
                getProvinces();
                getDistricts(values?.provinceName);
                getMunicipalities(values?.districtName);
            }
        },
        [
            // getProvinces,
            // mode,
            // getDistricts,
            // values?.provinceName,
            // getMunicipalities,
            // values?.district,
        ]
    );
    return (
        <div>
            <Row gutter={20}>
                <Col span={20}>
                    <div className="block-general-titled--body">
                        <Form
                            ref={ref}
                            layout="vertical"
                            className="margin-top"
                            onValuesChange={val => onValuesChange(val, mode)}
                            fields={[
                                {
                                    name: 'companyName',
                                    value: values?.companyName,
                                },
                                {
                                    name: 'nepName',
                                    value: values?.nepName,
                                },
                                {
                                    name: 'regNO',
                                    value: values?.regNO,
                                },
                                {
                                    name: 'nepRegNO',
                                    value: values?.nepRegNO,
                                },
                                {
                                    name: 'panno',
                                    value: values?.panno,
                                },
                                {
                                    name: 'provinceName',
                                    value: values?.provinceName,
                                },
                                {
                                    name: 'districtName',
                                    value: values?.districtName,
                                },
                                {
                                    name: 'municipalName',
                                    value: values?.municipalName,
                                },
                                {
                                    name: 'provinceNepali',
                                    value: values?.provinceNepali,
                                },
                                {
                                    name: 'districtNepali',
                                    value: values?.districtNepali,
                                },
                                {
                                    name: 'municipalNepali',
                                    value: values?.municipalNepali,
                                },
                                {
                                    name: 'ward',
                                    value: values?.ward,
                                },
                                {
                                    name: 'tole',
                                    value: values?.tole,
                                },
                                {
                                    name: 'nepTol',
                                    value: values?.nepTol,
                                },
                                {
                                    name: 'estd',
                                    value: ENG_ISO_TO_NEP(values?.estd),
                                },
                                {
                                    name: 'faxNo',
                                    value: values?.faxNo,
                                },
                                {
                                    name: 'email',
                                    value: values?.email,
                                },
                                {
                                    name: 'telephone1',
                                    value: values?.telephone1,
                                },
                            ]}
                        >
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={12}>
                                    <Form.Item
                                        label=" Cooperative Name"
                                        name="companyName"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={12}>
                                    <Form.Item
                                        label="सहकारीको नाम"
                                        name="nepName"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Reg No" name="regNO">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="दर्ता नं" name="nepRegNO">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Panno" name="panno">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Provinces
                                        provinces={provinces}
                                        item="provinceName"
                                    />
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Districts
                                        districts={districts}
                                        item="districtName"
                                    />
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <VdcMun
                                        municipalities={municipalities}
                                        item="municipalName"
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Provinces
                                        provinces={provinces}
                                        item="provinceNepali"
                                    />
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Districts
                                        districts={districts}
                                        item="districtNepali"
                                    />
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <VdcMun
                                        municipalities={municipalities}
                                        item="municipalNepali"
                                    />
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Ward No" name="ward">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Tole" name="tole">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="टोल" name="nepTol">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Estd" name="estd">
                                        <NepaliDatePicker
                                            className="nepali-date-picker--top"
                                            inputClassName="ant-input"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item
                                        label="Telephone "
                                        name="telephone1"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Fax " name="faxNo">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item
                                        label="Email "
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Invalid email',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>

                <Col span={4}>
                    <Col xs={24} sm={12} md={24}>
                        <PhotoUpload
                            label="Logo"
                            onFileChange={onFileChange}
                            mode={mode}
                            inputName="logo"
                            photoBinary={values?.logo}
                            photoUrl={values?.logofile}
                        />
                    </Col>
                </Col>
            </Row>
        </div>
    );
});
export default OfficeDetailsForm;
