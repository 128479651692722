import React from 'react';
import { Row, Col, Form, Tabs } from 'antd';
import { ENG_ISO_TO_NEP } from '../../utils/functions';
import {
    GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS,
    GET_MEMBERS,
    GET_SAVING_ACC_MEM_LIST,
    GET_STAFFS_BY_BRANCH,
    GET_KISTA_PERIODS,
    GET_NEW_LOAN_NO,
    GET_LOAN_DATE,
} from '../../utils/axios';
import LoanRegistrationDetailsForm from './LoanRegistrationDetailsForm/LoanRegistrationDetailsForm';

const LoanRegistrationForm = React.forwardRef((props, ref) => {
    const {
        values,
        onValuesChange,
        mode,
        setMembers,
        setDepositTypeAccounts,
        setSavingAccMemList,
        setStaffs,
        setKistaPeriod,
        setDefaultValues,
        setNewLoanNo,
        updateValuesInState,
        activePanelKey,
        onPanelChange,
        tabPanes,
    } = props;

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
        if (mode !== 'view') {
            (async () => {
                const accounts = await GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS();
                setDepositTypeAccounts(accounts);
                const members = await GET_MEMBERS(values?.branchID);
                setMembers(members);
                const staffs = await GET_STAFFS_BY_BRANCH(values?.branchID);
                setStaffs(staffs);
                const kistaPeriod = await GET_KISTA_PERIODS();
                setKistaPeriod(kistaPeriod);
                if (values?.memberNo && values?.stno) {
                    const memList = await GET_SAVING_ACC_MEM_LIST(
                        values.memberNo,
                        values.stno
                    );
                    setSavingAccMemList(memList);
                }
            })();
        }
        if (mode === 'add') {
            if (
                JSON.parse(localStorage.getItem('ENTERPRISE_DETAIL'))
                    .fetchIDbeforeInsert &&
                values?.branchID &&
                values?.acno
            ) {
                (async () => {
                    const loanNo = await GET_NEW_LOAN_NO(
                        values.branchID,
                        values.acno
                    );
                    setNewLoanNo(loanNo);
                })();
            }
        }
    }, [
        mode,
        setDefaultValues,
        setDepositTypeAccounts,
        values?.memberNo,
        values?.stno,
        values?.branchID,
        setMembers,
        setSavingAccMemList,
        setStaffs,
        setKistaPeriod,
        values?.acno,
        setNewLoanNo,
    ]);

    // Set final date
    React.useEffect(() => {
        if (
            mode !== 'view' &&
            values?.dmy &&
            values?.agreementDate &&
            (values?.installmentInterval || '0') &&
            (values?.totalNoOfKista || '0') &&
            (values?.sameDay === true || values?.sameDay === false)
        ) {
            let period;
            if (values.installmentInterval === 0) period = 'Day';
            else if (values.installmentInterval === 1) period = 'Months';
            else if (values.installmentInterval === 2) period = 'Year';
            const payload = {
                dmy: period,
                startDate: values.agreementDate,
                interval: values.dmy,
                totalNoOfKista: values.totalNoOfKista,
                sameDay: values.sameDay,
            };
            console.log('payload: ', payload);
            (async () => {
                const loanDate = await GET_LOAN_DATE(payload);
                console.log('loanDate: ', loanDate);
                updateValuesInState(loanDate, mode);
            })();
        }
    }, [
        mode,
        values?.dmy,
        values?.agreementDate,
        values?.installmentInterval,
        values?.totalNoOfKista,
        values?.sameDay,
        updateValuesInState,
    ]);

    return (
        <div>
            <Row gutter={20}>
                <Col span={24}>
                    <div className="block-general-titled--body">
                        <Form
                            ref={ref}
                            layout="vertical"
                            className="margin-top"
                            onValuesChange={val => onValuesChange(val, mode)}
                            fields={[
                                {
                                    name: 'branchID',
                                    value: values?.branchID,
                                },
                                {
                                    name: 'branchName',
                                    value: values?.branchName,
                                },
                                {
                                    name: 'entranceDate',
                                    value:
                                        values?.entranceDate &&
                                        ENG_ISO_TO_NEP(values?.entranceDate),
                                },
                                {
                                    name: 'acName',
                                    value: values?.acName,
                                },
                                {
                                    name: 'acno',
                                    value: values?.acno,
                                },
                                {
                                    name: 'loanNo',
                                    value: values?.loanNo,
                                },
                                {
                                    name: 'memberNo',
                                    value: values?.memberNo,
                                },
                                {
                                    name: 'name',
                                    value: values?.name,
                                },
                                {
                                    name: 'address',
                                    value: values?.address,
                                },
                                {
                                    name: 'smsFacility',
                                    value: values?.smsFacility,
                                },
                                {
                                    name: 'mobileNO',
                                    value: values?.mobileNO,
                                },
                                {
                                    name: 'photoloc',
                                    value: values?.photoloc,
                                },
                                {
                                    name: 'onSaving',
                                    value: values?.onSaving,
                                },
                                {
                                    name: 'savingType',
                                    value: values?.savingType,
                                },
                                {
                                    name: 'stno',
                                    value: values?.stno,
                                },
                                {
                                    name: 'sano',
                                    value: values?.sano,
                                },
                                {
                                    name: 'staffID',
                                    value: values?.staffID,
                                },
                                {
                                    name: 'staffName',
                                    value: values?.staffName,
                                },
                                {
                                    name: 'projectName',
                                    value: values?.projectName,
                                },
                                {
                                    name: 'agreedLoan',
                                    value: values?.agreedLoan,
                                },
                                {
                                    name: 'scheduledAmt',
                                    value: values?.scheduledAmt,
                                },
                                {
                                    name: 'agreementDate',
                                    value:
                                        values?.agreementDate &&
                                        ENG_ISO_TO_NEP(values?.agreementDate),
                                },
                                {
                                    name: 'interestStartDate',

                                    value:
                                        values?.interestStartDate &&
                                        ENG_ISO_TO_NEP(
                                            values?.interestStartDate
                                        ),
                                },
                                {
                                    name: 'intCalcMethod',
                                    value: values?.intCalcMethod,
                                },
                                {
                                    name: 'rateOfInterest',
                                    value: values?.rateOfInterest,
                                },
                                {
                                    name: 'totalNoOfKista',
                                    value: values?.totalNoOfKista,
                                },
                                {
                                    name: 'kistaPeriod',
                                    value: values?.kistaPeriod,
                                },
                                {
                                    name: 'installmentInterval',
                                    value: values?.installmentInterval,
                                },
                                {
                                    name: 'dmy',
                                    value: values?.dmy,
                                },
                                {
                                    name: 'lps',
                                    value: values?.lps,
                                },
                                {
                                    name: 'installmentStartDate',
                                    value:
                                        values?.installmentStartDate &&
                                        ENG_ISO_TO_NEP(
                                            values?.installmentStartDate
                                        ),
                                },
                                {
                                    name: 'sameDay',
                                    value: values?.sameDay,
                                },
                                {
                                    name: 'lpInterval',
                                    value: values?.lpInterval,
                                },
                                {
                                    name: 'equalLoanPmt',
                                    value: values?.equalLoanPmt,
                                },
                                {
                                    name: 'intBeforeInstallment',
                                    value: values?.intBeforeInstallment,
                                },
                                {
                                    name: 'pmtFromBegining',
                                    value: values?.pmtFromBegining,
                                },
                                {
                                    name: 'loanTerm',
                                    value: values?.loanTerm,
                                },
                                {
                                    name: 'finalDate',
                                    value:
                                        values?.finalDate &&
                                        ENG_ISO_TO_NEP(values?.finalDate),
                                },
                                {
                                    name: 'modifiedBy',
                                    value: values?.modifiedBy,
                                },
                                {
                                    name: 'termUnit',
                                    value: values?.termUnit,
                                },
                                {
                                    name: 'roundedBy',
                                    value: values?.roundedBy,
                                },
                            ]}
                        >
                            <LoanRegistrationDetailsForm {...props} />
                            <Tabs
                                activeKey={activePanelKey}
                                onChange={onPanelChange}
                            >
                                {tabPanes.map((pane, i) => {
                                    return (
                                        <Tabs.TabPane tab={pane.title} key={i}>
                                            {React.cloneElement(
                                                pane.component,
                                                {
                                                    ...props,
                                                }
                                            )}
                                        </Tabs.TabPane>
                                    );
                                })}
                            </Tabs>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
});

export default LoanRegistrationForm;
