import React from 'react';

export default function BottomActionBarFixed(props) {
    return (
        <div className="bottom-action-bar-fixed">
            <div className="bottom-action-bar-fixed__btn-group">
                {props.children}
            </div>
        </div>
    );
}
