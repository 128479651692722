import React from 'react';
import { Button, Modal, message } from 'antd';
// Import utils
import {
    axios,
    GET_USERS,
    GET_USER_DETAIL,
    GET_STAFFS_BY_BRANCH,
} from '../../utils/axios';
import { SET_DEFAULT_VALUES } from '../../utils/functions';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import UserRegisterForm from '../../components/UserRegisterForm/UserRegisterForm';

export default function UserRegister() {
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [users, setUsers] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // COMMON
    // Get user detail
    const getUserDetail = userID => {
        (async () => {
            const userDetail = await GET_USER_DETAIL(userID);
            console.log('userDetail: ', userDetail);
            setViewValues(userDetail);
        })();
    };
    // Get staffs
    const getStaffs = React.useCallback(() => {
        (async () => {
            const staffs = await GET_STAFFS_BY_BRANCH(
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
            );
            setStaffs(staffs);
        })();
    }, []);

    // ADD MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    ...(addValues?.transactionLimit
                        ? {}
                        : { transactionLimit: 0 }),
                };
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Users/SaveUser`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;

                        setUsers([
                            ...users,
                            {
                                userID: data.userID,
                                userName: data.userName,
                            },
                        ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New user added!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to user account');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On values change
    const onValuesChange = (val, mode) => {
        console.log('val: ', val, mode);
        const key = Object.keys(val)[0];
        const value = val[key];
        // let values;
        // if (mode === 'view') values = addValues;
        // else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('userID') || val.hasOwnProperty('userName')) {
            const user = users.find(user => user[key] === value);
            if (mode === 'view') getUserDetail(user.userID);
            updateValues(user);
            if (val.hasOwnProperty('userName') && mode !== 'view')
                return updateValues({
                    userName: val.userName.toLowerCase().trim(),
                });
        } else if (
            val.hasOwnProperty('staffID') ||
            val.hasOwnProperty('staffName')
        ) {
            const staff = staffs.find(staff => staff[key] === value);
            updateValues(staff);
        } else if (
            val.hasOwnProperty('branchID') ||
            val.hasOwnProperty('branchName')
        ) {
            const branch = branches.find(branch => branch[key] === value);
            return updateValues(branch);
        }
        updateValues(val);
    };

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            setBoundry: false,
            transactionLimit: 0,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            setAddValues
        );
    }, []);

    // Form props
    const formProps = {
        ref: formRef,
        users,
        staffs,
        getStaffs,
        onValuesChange,
        setDefaultValues,
    };

    // On component mount
    React.useEffect(() => {
        (async () => {
            const users = await GET_USERS();
            setUsers(users);
        })();
    }, []);
    return (
        <div className="owner-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">USER REGISTER</h3>
                <UserRegisterForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                />
                {/* Add modal */}
                <Modal
                    title="Add user"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    onOk={onAddModalOk}
                    onCancel={onAddModalCancel}
                    okText="Create"
                    cancelButtonProps={{
                        disabled: addModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1100}
                >
                    <UserRegisterForm
                        {...formProps}
                        mode="add"
                        values={addValues}
                    />
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
