import React from 'react';
// Import from dependencies
import { Form, Input, Row, Col, Select } from 'antd';

export default function MemberRegistrationStep04(props) {
    return (
        <>
            <h2 className="member-registration-step__header">{props.header}</h2>
            <div className="member-registration-step__form">
                <Form layout="vertical">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Name">
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Member ID">
                                <Input placeholder="Member ID" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Name">
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Father/Husband">
                                <Input placeholder="Father/Husband" />
                            </Form.Item>
                            <Form.Item label="District">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Kathmandu</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="VDC">
                                <Input placeholder="VDC" />
                            </Form.Item>
                            <Form.Item label="Ward No.">
                                <Input placeholder="Ward No." />
                            </Form.Item>
                            <Form.Item label="Tole/Gaun">
                                <Input placeholder="Tole/Gaun" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}
