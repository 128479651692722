import React from 'react';
// Import components
import Logo from '../../Common/Logo/Logo';

export default function DashboardHeader() {
	return (
		<div className="dashboard-header">
			<div className="block-general">
				<div className="dashboard-header__logo">
					<Logo />
				</div>
				<div className="dashboard-header__title">
					<span className="color-secondary">Cooperator gold </span>
					<span className="color-primary">plus</span>
				</div>
			</div>
		</div>
	);
}
