import React from 'react';
import { Form, Select, Input, Row, Col, Checkbox } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY } from '../../../utils/functions';

export default function KistaOnDepositForm(props) {
    // Destructuring
    const { depositTypeAccounts, savingAccMemList, staffs, values } = props;
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Deposit Type"
                        name="onSaving"
                        valuePropName="checked"
                    >
                        <Checkbox>Kista on Deposit</Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={18}></Col>
                {values?.onSaving && (
                    <>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label="Deposit Type" name="savingType">
                                <Select placeholder="Select..." v>
                                    {depositTypeAccounts?.map((acc, i) => (
                                        <Select.Option
                                            key={i}
                                            value={acc.acName}
                                        >
                                            {acc.acName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="ACNO" name="stno">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depositTypeAccounts?.map((acc, i) => (
                                        <Select.Option key={i} value={acc.acno}>
                                            {acc.acno}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={4}>
                            <Form.Item label="Account No" name="sano">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingAccMemList?.map((mem, i) => (
                                        <Select.Option
                                            key={i}
                                            value={mem.accountNo}
                                        >
                                            {mem.accountNo}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={10}></Col>
                    </>
                )}

                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Collector ID" name="staffID">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {staffs.map((staff, i) => (
                                <Select.Option key={i} value={staff.staffID}>
                                    {staff.staffID}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Collector Name " name="staffName">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {staffs.map((staff, i) => (
                                <Select.Option key={i} value={staff.staffName}>
                                    {staff.staffName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={10}></Col>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Approved Amount"
                        name="agreedLoan"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (
                                        CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY(
                                            value
                                        )
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Invalid format')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}></Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Prepare installment For"
                        name="scheduledAmt"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}></Col>

                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Loan Start Date"
                        name="agreementDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <NepaliDatePicker
                            className="nepali-date-picker--top"
                            inputClassName="ant-input"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}></Col>

                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Interest Start Date"
                        name="interestStartDate"
                    >
                        <NepaliDatePicker
                            className="nepali-date-picker--top"
                            inputClassName="ant-input"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
