import React from 'react';
// Import from dependencies
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherAccounts as otherAccountsAtom,
    otherItems as otherItemsAtom,
} from '../../../recoil/atoms';
// Import utils
import axios, {
    GET_COLLECTORS,
    GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS,
    GET_LOAN_TYPE_ACCOUNTS,
} from '../../../utils/axios';
import moment from 'moment';
import {
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
// Import components
import CollectionEntryForm from '../../../components/Savings/CollectionEntry/CollectionEntryForm/CollectionEntryForm';

export default function CollectionEntry() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [isProcessing, setIsProcessing] = React.useState(false);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [accounts, setAccounts] = React.useState([]);
    const [collectors, setCollectors] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [results, setResults] = React.useState([]);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        console.log(key, value);
        let values;
        if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'collectionOf') {
            updateValues(DLT_KEYS_FROM_OBJ(values, ['acno', 'acName']));
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            handleNew();
            updateValues(branch);
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);
            console.log('acc: ', acc);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherName', 'otherNo']),
                otherAcno: acc.acno,
                otherAcName: acc.acName,
                otherMano: acc.mano,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'staffID' || key === 'staffName') {
            const collector = collectors.find(item => item[key] === value);
            return updateValues(collector);
        } else if (key === 'acno' || key === 'acName') {
            const account = accounts.find(item => item[key] === value);
            return updateValues(account);
        } else if (key === 'collectedBy' || key === 'collectedByID') {
            const collectorKey =
                key === 'collectedBy' ? 'staffName' : 'staffID';
            const collector = collectors.find(
                item => item[collectorKey] === value
            );
            return updateValues({
                collectedBy: collector.staffName,
                collectedByID: collector.staffID,
            });
        } else if (key === 'transDate') {
            return updateValues({
                [key]: NEP_TO_ENG_ISO(value),
                collectionDate: NEP_TO_ENG_ISO(value),
            });
        } else if (key === 'collectionDate') {
            return updateValues({
                [key]: NEP_TO_ENG_ISO(value),
            });
        } else if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        } else if (key.includes('collectedAmt')) {
            const i = +key.match(/(\d+)/)[0];
            const tableDataUpdated = [...tableData];
            tableDataUpdated[i] = {
                ...tableDataUpdated[i],
                finalBalance: +value + +tableDataUpdated[i].balance,
                [key]: value,
            };
            setTableData(tableDataUpdated);
        } else if (key.includes('remark')) {
            const i = +key.match(/(\d+)/)[0];
            const tableDataUpdated = [...tableData];
            tableDataUpdated[i] = {
                ...tableDataUpdated[i],
                [key]: value,
            };
            setTableData(tableDataUpdated);
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            collectionDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            isClearance: false,
            collectionOf: 'deposit',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = val => {
        formRef.current
            .validateFields()
            .then(() => {
                setIsProcessing(true);
                const payload = {
                    transDate: viewValues?.transDate,
                    collectionDate: viewValues?.collectionDate,
                    enteredBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    branchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    transactionType: 'Collection Entry',
                    isCollector: viewValues?.collectedBy ? true : false,
                    collectorID: viewValues?.collectedByID,
                    collectorName: viewValues?.collectedBy || 'Office',
                    creditTrans: tableData
                        .filter((item, i) => item[`collectedAmt${i}`])
                        .map((item, i) => {
                            let mano, acno, itemCode, remarks1;
                            if (item.type === 'Loans') {
                                mano = '070';
                                acno = '070.10';
                                itemCode = item.acno.slice(-2) + item.accountNo;
                                remarks1 = item.acno;
                            } else {
                                mano = item.acno.slice(0, 3);
                                acno = item.acno;
                                itemCode = item.accountNo;
                                remarks1 = item[`remark${i}`] || '0';
                            }
                            return {
                                mano,
                                acno,
                                bvrcno: '',
                                itemCode,
                                itemName: item?.accountHolder,
                                itemLocation: item?.address,
                                receivedPaidBy:
                                    viewValues?.collectedBy || 'Office',
                                remarks1,
                                rate: item[`collectedAmt${+item.key}`],
                                interBranchID:
                                    item?.branchID ||
                                    JSON.parse(
                                        localStorage.getItem('USER_CREDENTIALS')
                                    ).branchID,
                                amount: item[`collectedAmt${+item.key}`],
                                quantity: '1',
                                particulars: `Deposit Collection${
                                    viewValues?.collectedBy &&
                                    ` - ${viewValues?.collectedBy}`
                                }`,
                            };
                        }),
                    debitTrans: [
                        ...(viewValues?.isCash
                            ? [
                                  {
                                      mano: '080',
                                      acno: '080.01',
                                      bvrcno: viewValues?.cashBvrcno,
                                      itemCode: viewValues?.cashNo,
                                      itemName: viewValues?.cashName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy:
                                          viewValues?.collectedBy || 'Office',
                                      remarks1: '0',
                                      rate: viewValues?.cashAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.cashAmount,
                                      quantity: '1',
                                      particulars: `Collected By - ${viewValues?.cashName}`,
                                  },
                              ]
                            : []),
                        ...(viewValues?.isBank
                            ? [
                                  {
                                      mano: bankAccounts[0].mano,
                                      acno: bankAccounts[0].acno,
                                      bvrcno: viewValues?.bankBvrcno,
                                      itemCode: viewValues?.bankNo,
                                      itemName: viewValues?.bankName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy:
                                          viewValues?.collectedBy || 'Office',
                                      remarks1: '0',
                                      rate: viewValues?.bankAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.bankAmount,
                                      quantity: '1',
                                      particulars: `Collected By - ${viewValues?.bankName}`,
                                  },
                              ]
                            : []),
                        ...(viewValues?.isOther
                            ? [
                                  {
                                      mano: viewValues.otherMano,
                                      acno: viewValues.otherAcno,
                                      bvrcno: '',
                                      itemCode: viewValues?.otherNo,
                                      itemName: viewValues?.otherName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy:
                                          viewValues?.collectedBy || 'Office',
                                      remarks1: '0',
                                      rate: viewValues?.otherAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.otherAmount,
                                      quantity: '1',
                                      particulars: `Collected By - ${viewValues?.otherName}`,
                                  },
                              ]
                            : []),
                    ],
                };
                console.log('payload: ', payload);
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/AllTransactions/CollectionEntry`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            setIsProcessing(false);
                            return message.error(res.data.statusMessage);
                        }
                        setViewValues(prevValues => ({
                            ...prevValues,
                            ...res.data.data,
                        }));
                        message.success(res.data.statusMessage);
                    })
                    .catch(err => {
                        console.error(err, 'Failed to process share addition');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                        setIsProcessing(false);
                    });
            })
            .catch(err => {
                message.error('Please fill all required fields!');
            });
    };

    // Handle new
    const handleNew = () => {
        setTableData([]);
        setResults([]);
        setViewValues(null);
        setDefaultValues('view');
        setIsProcessing(false);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        tableData,
        setTableData,
        collectors,
        accounts,
        results,
        setResults,
    };

    // Get Collectors
    React.useEffect(() => {
        if (viewValues?.branchID) {
            const branchID = viewValues.branchID;
            (async () => {
                const collectors = await GET_COLLECTORS(branchID);
                setCollectors(collectors);
            })();
        }
    }, [viewValues?.branchID]);

    // Get accounts
    React.useEffect(() => {
        if (viewValues?.collectionOf) {
            const collectionOf = viewValues.collectionOf;
            setAccounts([]);
            if (collectionOf === 'deposit') {
                (async () => {
                    const accounts =
                        await GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS();
                    setAccounts(accounts);
                })();
            } else if (collectionOf === 'loan') {
                (async () => {
                    const accounts = await GET_LOAN_TYPE_ACCOUNTS();
                    setAccounts(accounts);
                })();
            }
        }
    }, [viewValues?.collectionOf]);

    // Get Current share
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.memberNo) {
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${viewValues.branchID}/010/010.01/${viewValues.memberNo}`
                )
                .then(res => {
                    setViewValues(prevValues => ({
                        ...prevValues,
                        currentShare: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to get Current share amount');
                    message.error('Failed to get Current share amount');
                });
        }
    }, [viewValues?.branchID, viewValues?.memberNo]);

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Set pre-total
    React.useEffect(() => {
        const preTotal = tableData.reduce(
            (prevValue, curValue, i) =>
                +prevValue + +curValue[`collectedAmt${i}`],
            0
        );
        setViewValues(prevValues => ({
            ...prevValues,
            preTotal,
        }));
    }, [tableData]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            Collection Entry
                        </h4>
                        <CollectionEntryForm
                            {...formProps}
                            mode="view"
                            values={viewValues}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
