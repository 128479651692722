import React from 'react';
import { Button, message, Modal } from 'antd';
import moment from 'moment';
// Import utils
import axios from '../../utils/axios';
import {
    UPDATE_AXIOS_AUTH,
    NEP_TO_ENG_ISO,
    SET_DEFAULT_VALUES,
} from '../../utils/functions';
// Import components
import MemberGroupForm from '../../components/MemberGroupForm/MemberGroupForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function MemberGroup() {
    // Local states
    const [groups, setGroups] = React.useState([]);
    const [groupTypes, setGroupTypes] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [areas, setAreas] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get groups
    const getGroups = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Group`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setGroups(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get groups');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get group types
    const getGroupTypes = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/GroupType`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setGroupTypes(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get group types');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get staffs
    const getStaffs = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Staff`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffs(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staffs');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get areas
    const getAreas = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Area`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAreas(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get areas');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get group detail
    const getGroupDetail = id => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Group/Detail/${id}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get group details');
                message.error('Something went wrong. Please try again!');
            });
    };

    // VIEW FORM
    const onViewValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (val.hasOwnProperty('groupId')) {
            const group = groups.find(group => group.groupId === val.groupId);
            setViewValues(prevValues => ({
                ...prevValues,
                groupName: group.groupName,
            }));
            getGroupDetail(val.groupId);
        } else if (val.hasOwnProperty('groupName')) {
            const group = groups.find(
                group => group.groupName === val.groupName
            );
            setViewValues(prevValues => ({
                ...prevValues,
                groupId: group.groupId,
            }));
            getGroupDetail(group.groupId);
        } else if (val.hasOwnProperty('formedDate')) {
            return setViewValues(prevValues => ({
                ...prevValues,
                [key]: NEP_TO_ENG_ISO(value),
            }));
        }
        setViewValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // EDIT MODAL
    // Edit form values request
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/Group/Update/${editValues.groupId}`,
                    editValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewValues(res.data.data);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update member group account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };
    // On edit values change
    const onEditValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (val.hasOwnProperty('formedDate')) {
            return setEditValues(prevValues => ({
                ...prevValues,
                [key]: NEP_TO_ENG_ISO(value),
            }));
        } else if (val.hasOwnProperty('groupName')) {
            return setEditValues(prevValues => ({
                ...prevValues,
                [key]: value.toUpperCase(),
            }));
        }
        setEditValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // ADD MODAL
    // Add form request
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .post(`${process.env.REACT_APP_HOST}/api/Group/Add`, addValues)
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    const data = res.data.data;
                    setViewValues(data);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setGroups([
                        ...groups,
                        { gtid: data.gtid, groupType: data.groupType },
                    ]);
                    setAddValues(null);
                    message.success('New account created!');
                })
                .catch(err => {
                    console.error(err, 'Failed to create member group account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On add modal open
    const onAddModalOpen = () => {
        setAddValues(null);
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On edit modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };
    // On add values change
    const onAddValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (val.hasOwnProperty('formedDate')) {
            return setAddValues(prevValues => ({
                ...prevValues,
                [key]: NEP_TO_ENG_ISO(value),
            }));
        } else if (val.hasOwnProperty('groupName')) {
            return setAddValues(prevValues => ({
                ...prevValues,
                [key]: value.toUpperCase(),
            }));
        }
        setAddValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // DELETE MODAL
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Group/Delete/${viewValues?.groupId}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setGroups(
                    [...groups].filter(
                        group => group.groupId !== viewValues.groupId
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete member group account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On edit modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            formedDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);
    // Form props
    const formProps = {
        ref: formRef,
        groups,
        groupTypes,
        staffs,
        areas,
        setDefaultValues,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        // Get areas
        getGroups();
        // Get group types
        getGroupTypes();
        // Get staffs
        getStaffs();
        // Get areas
        getAreas();
    }, []);
    return (
        <div>
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">Member Group</h3>
                <MemberGroupForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                    onValuesChange={onViewValuesChange}
                />
                {/* Edit account modal */}
                <Modal
                    title="Edit account"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <MemberGroupForm
                        {...formProps}
                        mode="edit"
                        values={editValues}
                        onValuesChange={onEditValuesChange}
                    />
                </Modal>
                {/* Add account modal */}
                <Modal
                    title="Create account"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    onOk={onAddModalOk}
                    onCancel={onAddModalCancel}
                    okText="Create"
                    cancelButtonProps={{
                        disabled: addModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <MemberGroupForm
                        {...formProps}
                        mode="add"
                        values={addValues}
                        onValuesChange={onAddValuesChange}
                    />
                </Modal>
                {/* Delete account modal */}
                <Modal
                    title="Delete account?"
                    visible={deleteModal.visible}
                    confirmLoading={deleteModal.confirmLoading}
                    onOk={onDeleteModalOk}
                    onCancel={onDeleteModalCancel}
                    okText="Delete"
                    cancelButtonProps={{
                        disabled: deleteModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                >
                    <p>The account and its data will be deleted forever</p>
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.groupId}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                    <Button
                        type="primary"
                        onClick={onDeleteModalOpen}
                        disabled={!viewValues?.groupId}
                    >
                        Delete
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
