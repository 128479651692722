import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Button,
    Space,
    Radio,
    Checkbox,
} from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import moment from 'moment';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';

const CalculateInterestForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        depAccounts,
        savingAccounts,
        intColSavingAccounts,
        values,
        onValuesChange,
        isProcessing,
        handleNew,
        onFormSubmit,
    } = props;

    // console.log(values, 'values');

    return (
        <div>
            <Form
                ref={ref}
                layout="horizontal"
                className="margin-top"
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'intDate',
                        value:
                            values?.intDate && ENG_ISO_TO_NEP(values.intDate),
                    },
                    {
                        name: 'intStartDate',
                        value:
                            values?.intStartDate &&
                            ENG_ISO_TO_NEP(values.intStartDate),
                    },
                    {
                        name: 'expireDate',
                        value:
                            values?.expireDate &&
                            ENG_ISO_TO_NEP(values.expireDate),
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'accountNo',
                        value: values?.accountNo,
                    },
                    {
                        name: 'disabled',
                        value: values?.disabled,
                    },
                    {
                        name: 'locked',
                        value: values?.locked,
                    },
                    {
                        name: 'style',
                        value: values?.style,
                    },
                    {
                        name: 'memberNo',
                        value: values?.memberNo,
                    },
                    {
                        name: 'rateofInterest',
                        value: values?.rateofInterest,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'fullName',
                        value: values?.fullName,
                    },
                    {
                        name: 'intCalcBasis',
                        value: values?.intCalcBasis,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'address',
                        value: values?.address,
                    },
                    {
                        name: 'taxRate',
                        value: values?.taxRate,
                    },
                    {
                        name: 'intCalcPeriod',
                        value: values?.intCalcPeriod,
                    },
                    {
                        name: 'odFacility',
                        value: values?.odFacility,
                    },
                    {
                        name: 'minIntBal',
                        value: values?.minIntBal,
                    },
                    {
                        name: 'odIntrate',
                        value: values?.odIntrate,
                    },
                    {
                        name: 'odLimit',
                        value: values?.odLimit,
                    },
                    {
                        name: 'balance',
                        value: values?.balance,
                    },
                    {
                        name: 'intCollACNO',
                        value: values?.intCollACNO,
                    },
                    {
                        name: 'calculatedInterest',
                        value: values?.calculatedInterest,
                    },
                    {
                        name: 'odInterest',
                        value: values?.odInterest,
                    },
                    {
                        name: 'intCollACName',
                        value: values?.intCollACName,
                    },
                    {
                        name: 'interest',
                        value: values?.interest,
                    },
                    {
                        name: 'tax',
                        value: values?.tax,
                    },
                    {
                        name: 'odInterest',
                        value: values?.odInterest,
                    },
                    {
                        name: 'description',
                        value: values?.description,
                    },
                    {
                        name: 'calculatedODInterest',
                        value: values?.calculatedODInterest,
                    },
                    {
                        name: 'intSaveType',
                        value: values?.intSaveType,
                    },
                    {
                        name: 'intCollAccountNo',
                        value: values?.intCollAccountNo,
                    },
                    {
                        name: 'intCollName',
                        value: values?.intCollName,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col
                            span={7}
                            className="form-item-inline"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Deposit Type"
                                name="acName"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depAccounts?.length &&
                                        depAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Type No"
                                name="acno"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depAccounts?.length &&
                                        depAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Style"
                                name="style"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="Date"
                                name="tillDate"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col
                            span={7}
                            className="form-item-inline"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Account No"
                                name="accountNo"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingAccounts?.length &&
                                        savingAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.accountNo}
                                            >
                                                {acc.accountNo}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Member ID"
                                name="memberNo"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Int Col Period"
                                name="intCalcPeriod"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="Journal No"
                                name="journalNO"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col
                            span={13}
                            className="form-item-inline"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Account Holder"
                                name="fullName"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingAccounts?.length &&
                                        savingAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.fullName}
                                            >
                                                {acc.fullName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={6} className="form-item-inline"></Col> */}
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Calc Basis"
                                name="intCalcBasis"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="Trans No"
                                name="transNo"
                                labelCol={{ span: 7 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col
                            span={13}
                            className="form-item-inline text-right"
                            style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Form.Item
                                label="Address"
                                name="address"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6} className="form-item-inline"></Col> */}
                        <Col
                            span={6}
                            className="form-item-inline text-right"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Interest Rate"
                                name="rateofInterest"
                                // valuePropName="checked"
                                colon={false}
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline"></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={13}></Col>
                        {/* <Col span={6}> */}
                        {/* <Row gutter={20}>
                                <Col span={12}>
                                    <Form.Item
                                        label=" "
                                        name="disabled"
                                        labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 14 }}
                                        valuePropName="checked"
                                        colon={false}
                                    >
                                        <Checkbox>Disabled</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label=" "
                                        name="locked"
                                        labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 14 }}
                                        valuePropName="checked"
                                        colon={false}
                                    >
                                        <Checkbox>Locked</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        {/* </Col> */}
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Tax Rate"
                                name="taxRate"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                            {/* <Form.Item
                                label=" "
                                name="odFacility"
                                valuePropName="checked"
                                wrapperCol={{ span: 17 }}
                                labelCol={{ span: 7 }}
                                colon={false}
                            >
                                <Checkbox>OD Facility</Checkbox>
                            </Form.Item> */}
                        </Col>
                        <Col span={5} className="form-item-inline"></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={13}>
                            <Form.Item
                                label="Collect Interest On"
                                name="intSaveType"
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 19 }}
                            >
                                <Radio.Group>
                                    <Radio
                                        value="Same"
                                        disabled={values?.style === 'Fixed'}
                                    >
                                        Same Account
                                    </Radio>
                                    <Radio value="Payable">Payable</Radio>
                                    <Radio value="Other">Collect In</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}></Col> */}
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Min Int Balance"
                                name="minIntBal"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="OD Rate"
                                name="odIntrate"
                                wrapperCol={{ span: 17 }}
                                labelCol={{ span: 7 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={7}>
                            <Form.Item
                                label="Deposit Type"
                                name="intCollACName"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required:
                                            values?.intSaveType === 'Other',
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        background: 'red',
                                    }}
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depAccounts?.length &&
                                        depAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ACNO"
                                name="intCollACNO"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required:
                                            values?.intSaveType === 'Other',
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depAccounts?.length &&
                                        depAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Total Balance"
                                name="balance"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="OD Limit"
                                name="odLimit"
                                wrapperCol={{ span: 17 }}
                                labelCol={{ span: 7 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={7}>
                            <Form.Item
                                label="Account No"
                                name="intCollAccountNo"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required:
                                            values?.intSaveType === 'Other',
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {intColSavingAccounts?.length &&
                                        intColSavingAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.accountNo}
                                            >
                                                {acc.accountNo}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Name"
                                name="intCollName"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    {
                                        required:
                                            values?.intSaveType === 'Other',
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {intColSavingAccounts?.length &&
                                        intColSavingAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.fullName}
                                            >
                                                {acc.fullName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Self Calculation"
                                name="selfCalculation"
                                valuePropName="checked"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Checkbox></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="OD Interest"
                                name="odInterest"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                colon={false}
                            >
                                {/* <Input /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={7}></Col>
                        <Col span={6}></Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Calculated Int"
                                name="calculatedInterest"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="Calculated"
                                name="calculatedODInterest"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={7}>
                            <Form.Item
                                label="Int Start From"
                                name="intStartDate"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <Input disabled />
                            </Form.Item>
                            {/* <Form.Item
                                label="Show Statement From"
                                // name=""
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item> */}
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Interest Calc To"
                                name="intDate"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            const intDate = moment(
                                                values?.intDate
                                            ).format('YYYY-MM-DD');
                                            const intStartDate = moment(
                                                values?.intStartDate
                                            ).format('YYYY-MM-DD');
                                            if (
                                                moment(intDate).isBefore(
                                                    intStartDate
                                                )
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Must be greater than Interest start date'
                                                    )
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                            {/* <Form.Item
                                label="Withdraw After"
                                name="expireDate"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item> */}
                        </Col>
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Regular Int"
                                name="interest"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="form-item-inline">
                            <Form.Item
                                label="Regular"
                                name="odInterest"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={13}>
                            <Form.Item
                                label="Description"
                                name="description"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}></Col> */}
                        <Col
                            span={6}
                            className="form-item-inline"
                            style={{ backgroundColor: '#FFFAF8' }}
                        >
                            <Form.Item
                                label="Tax Deduction"
                                name="tax"
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline"></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        onClick={onFormSubmit}
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
});

export default CalculateInterestForm;
