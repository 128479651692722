// MEMBERSHIP - START
export const membershipFirstPanelNames = [
    'branchName',
    'branchID',
    'entryDate',
    'ownerNO',
    'ownerName',
    'unName',
    'dobEnglish',
    'dateOfBirth',
    'gender',
    'area',
    'group',
    'telephone',
    'mobileNO',
    'emailID',
];
export const membershipSecondPanelNames = [
    'province',
    'district',
    'vdcMun',
    'wardNO',
    'unProvince',
    'unDistrict',
    'unvdc',
    'unWardNO',
    'tole',
    'unTole',
    'blockNO',
    'tmpProvince',
    'tmpDistrict',
    'tmpVDCMun',
    'tmpWardNO',
    'untProvince',
    'untDistrict',
    'untvdc',
    'untWardNO',
    'tmpTole',
    'untTole',
    'tmpBlockNO',
];
export const membershipThirdPanelNames = [
    'headName',
    'motherName',
    'grandHeadName',
    'spouse',
    'unFather',
    'unMotherName',
    'unGrandFather',
    'unSpouse',
    'casteGroup',
    'caste',
    'religion',
    'maritalStatus',
    'nominee',
    'unNominee',
    'nomRel',
];
// MEMBERSHIP - END

// DEPOSIT REGISTRATION - START
const depositRegistrationCommonPanelFields = [
    'acName',
    'acno',
    'branchName',
    'branchID',
    'accountNo',
    'entranceDate',
    'memberNo',
    'operator1',
    'opAddress1',
    'mobileNO1',
    'op1Sign',
];
export const depositRegistrationFirstPanelFields = [
    ...depositRegistrationCommonPanelFields,
    'aloneOrCombine',
    'memberNo2',
    'operator2',
    'opAddress2',
    'mobileNO2',
    'op2Sign',
    'memberNo3',
    'operator3',
    'opAddress3',
    'mobileNO3',
    'op3Sign',
    'memberNo4',
    'operator4',
    'opAddress4',
    'mobileNO4',
    'op4Sign',
    'isOwner',
    'ownerNO',
    'ownerName',
    'ownerFullAddress',
    'staffID',
    'staffName',
];
export const depositRegistrationSecondPanelFields = [
    ...depositRegistrationCommonPanelFields,
    'savingPeriod',
    'periodType',
    'startDate',
    'expireDate',
    'interestStartDAte',
    'interestRate',
    'savingRate',
    'minimumBalance',
    'odFacility',
    'odIntrate',
    'oDlimit',
    'smsFacility',
    'mobileNO',
    'confidential',
    'dormant',
    'disabled',
    'locked',
    'lockedAmount',
];
export const depositRegistrationThirdPanelFields = [
    ...depositRegistrationCommonPanelFields,
    'intColDepTypeName',
    'intColDepType',
    'transferInterestToName',
    'transferInterestTo',
    'intTransPeriod',
    'interestRate',
    'savingRate',
    'minimumBalance',
    'odFacility',
    'odIntrate',
    'oDlimit',
    'smsFacility',
    'mobileNO',
    'confidential',
    'dormant',
    'disabled',
    'locked',
    'lockedAmount',
];
// DEPOSIT REGISTRATION - END

// LOAN REGISTRATION - START
const loanRegistrationCommonPanelFields = [
    ...depositRegistrationCommonPanelFields,
    'branchName',
    'branchID',
    'entranceDate',
    'acName',
    'acno',
    'loanNo',
    'memberNo',
    'name',
    'nepName',
    'address',
    'mobileNO',
];
export const loanRegistrationFirstPanelFields = [
    ...loanRegistrationCommonPanelFields,
    'onSaving',
    'savingType',
    'stno',
    'sano',
    'staffID',
    'staffName',
    'projectName',
    'agreedLoan',
    'scheduledAmt',
    'agreementDate',
    'interestStartDate',
];
export const loanRegistrationSecondPanelFields = [
    ...loanRegistrationCommonPanelFields,
    'intCalcMethod',
    'lps',
    'rateOfInterest',
    'totalNoOfKista',
    'kistaPeriod',
    'dmy',
    'installmentInterval',
    'installmentStartDate',
    'sameDay',
    'lpInterval',
    'intBeforeInstallment',
    'equalLoanPmt',
    'pmtFromBegining',
    'loanTerm',
    'finalDate',
    '',
];
// LOAN REGISTRATION - END
