import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';

export default function DepositTypesClosingSetup(props) {
    // Destructuring
    const { mode } = props;

    const untimelyWithdrawFine = (
        <Form.Item name="untimelyWithdrawRateType">
            <Select className="select-after" disabled={mode === 'view'}>
                <Select.Option value="Percent">%</Select.Option>
                <Select.Option value="Amount">Rs</Select.Option>
            </Select>
        </Form.Item>
    );

    const untimelyClosingFine = (
        <Form.Item name="untimeClosingRateType">
            <Select className="select-after" disabled={mode === 'view'}>
                <Select.Option value="Percent">%</Select.Option>
                <Select.Option value="Amount">Rs</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <div className="deposit-type-Closing-Setup-form-view">
            <Row gutter={10}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Untimely Withdraw Fine"
                        name="untimeWithdrawCharge"
                    >
                        <Input
                            addonAfter={untimelyWithdrawFine}
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Untimely Closing Fine"
                        name="untimeClosingRate"
                    >
                        <Input
                            addonAfter={untimelyClosingFine}
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Closing Charge" name="closingCharge">
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
