import React from 'react';
// Import from dependencies
import { message, Modal, Button, Select } from 'antd';
import axios from '../../../utils/axios';
import { useRecoilState } from 'recoil';
import { depositTypesAccounts as depositTypesAccountsAtom } from '../../../recoil/atoms';
import moment from 'moment';
// Import utils
import { USER_CREDENTIALS } from '../../../utils/localStorage';
// Import components
import DepositTypesForm from '../../../components/DepositTypes/DepositTypesForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import FlexibleInterestRate from '../../../components/DepositTypes/FlexibleInterestRate/FlexibleInterestRate';
import { NEP_TO_ENG_ISO } from '../../../utils/functions';

export default function DepositTypes() {
    // Global states
    const [accounts, setAccounts] = useRecoilState(depositTypesAccountsAtom);
    // Local states
    const [viewFormValues, setViewFormValues] = React.useState(null);
    const [editFormValues, setEditFormValues] = React.useState(null);
    const [addFormValues, setAddFormValues] = React.useState(null);
    const [flexRateSets, setFlexRateSets] = React.useState([]);
    const [activeFlexRateSet, setActiveFlexRateSet] = React.useState(null);
    const [flexRateValues, setFlexRateValues] = React.useState(null);
    const [flexRateValuesEdit, setFlexRateValuesEdit] = React.useState(null);
    const [flexRateValuesAdd, setFlexRateValuesAdd] = React.useState(null);
    const [editAccountModal, setEditAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addAccountModal, setAddAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteAccountModal, setDeleteAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [flexRateModal, setFlexRateModal] = React.useState(false);
    const [deleteFlexRateModal, setDeleteFlexRateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [flexRateMode, setFlexRateMode] = React.useState('view');
    const [isUpdatingFlexRate, setIsUpdatingFlexRate] = React.useState(false);

    // Refs
    const formRef = React.useRef();

    // Variables and constants
    const defaultValues = {
        savingType: 'Random',
        regSavingPeriod: 'None',
        savingRate: '0',
        lastDayForPmt: '0',
        lateFine: '0',
        acOpeningRate: '0',
        minBal: '0',
        savingPeriod: '0',
        savingPeriodType: 'Not Mentioned',
        maxDrawPerDay: '0',
        rateOfInterest: '0',
        taxRateorg: '15',
        taxRatePerson: '5',
        closingCharge: '0',
        transferCharge: '0',
        untimeClosingRate: '0',
        untimeClosingRateType: 'Percent',
        savingStatus: 'Normal',
        intCalcBasis: 'DayMinBal',
        intStartDate: moment().format(),
        untimeWithdrawCharge: '0',
        untimelyWithdrawRateType: 'Percent',
        fineType: 'Percent',
        minIntBal: '0',
        loanable: false,
    };

    // Get account detail by account number
    const getAccountDetailByACNO = acno => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/DepositType/${acno}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'Account detail');
                setViewFormValues(res.data.data);
            })
            .catch(err => {
                console.error(
                    err,
                    'Failed to get deposit types account detail'
                );
                message.error('Something went wrong. Please refresh the page!');
            });
    };

    // On view form change
    const onViewFormValuesChange = val => {
        console.log(val);
        if (val.hasOwnProperty('acno')) {
            getAccountDetailByACNO(val.acno);
        } else if (val.hasOwnProperty('acName')) {
            const acno = accounts.find(acc => acc.acName === val.acName).acno;
            getAccountDetailByACNO(acno);
        }
        setViewFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // EDIT ACCOUNT MODAL
    const onEditAccountModalOpen = () => {
        setEditFormValues({
            ...defaultValues,
            ...viewFormValues,
        });
        setEditAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On Edit form change
    const onEditFormValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (key === 'intStartDate') {
            return setEditFormValues(prevValues => ({
                ...prevValues,
                [key]: NEP_TO_ENG_ISO(value),
            }));
        } else if (val.hasOwnProperty('savingType')) {
            if (value !== 'Regular') {
                setEditFormValues(prevValues => ({
                    ...prevValues,
                    regSavingPeriod: 'None',
                }));
            }
        }
        setEditFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    const onEditAccountModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditAccountModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...editFormValues,
                    lastModifiedBy: JSON.parse(
                        localStorage.getItem(USER_CREDENTIALS)
                    ).userName,
                };
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/DepositType/UpdateDepositType/${editFormValues.acno}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setViewFormValues(editFormValues);
                        setEditFormValues(null);
                        setEditAccountModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Account updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update deposit type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditAccountModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    const onEditAccountModalCancel = () => {
        setEditAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add account modal open
    const onAddAccountModalOpen = () => {
        setAddFormValues(defaultValues);
        setAddAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add account modal ok
    const onAddAccountModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddAccountModal(prevVales => ({
                    ...prevVales,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addFormValues,
                    lastModifiedBy: JSON.parse(
                        localStorage.getItem(USER_CREDENTIALS)
                    ).userName,
                };
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/DepositType/AddDepositType/${addFormValues.acno}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setViewFormValues(payload);
                        setAddFormValues(null);
                        setAddAccountModal(prevVales => ({
                            ...prevVales,
                            visible: false,
                        }));
                        message.success('New account created!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to create deposit types account'
                        );
                        message.error(
                            err,
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddAccountModal(prevVales => ({
                            ...prevVales,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill the required fields!');
            });
    };

    // On add account modal cancel
    const onAddAccountModalCancel = () => {
        setAddAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // Add form values change
    const onAddFormValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (val.hasOwnProperty('acName')) {
            setAddFormValues(prevValues => ({
                ...prevValues,
                acName: val.acName.toUpperCase(),
            }));
            return;
        } else if (val.hasOwnProperty('intStartDate')) {
            return setAddFormValues(prevValues => ({
                ...prevValues,
                [key]: NEP_TO_ENG_ISO(value),
            }));
        } else if (val.hasOwnProperty('savingType')) {
            if (value !== 'Regular') {
                setAddFormValues(prevValues => ({
                    ...prevValues,
                    regSavingPeriod: 'None',
                }));
            }
        }
        setAddFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // DELETE ACCOUNT MODAL
    const onDeleteAccountModalOpen = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // Delete account from state
    const deleteAccFromState = () => {
        const accountsUpdated = [...accounts].filter(
            acc => acc.acno !== viewFormValues.acno
        );
        setAccounts(accountsUpdated);
    };

    // On delete account modal ok
    const onDeleteAccountModalOk = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/DepositType/DeleteDepositType/${viewFormValues.acno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                deleteAccFromState();
                setViewFormValues(null);
                setDeleteAccountModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to delete deposit type account');
                message.error('Failed to delete deposit type account');
            })
            .finally(() => {
                setDeleteAccountModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On delete account modal cancel
    const onDeleteAccountModalCancel = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // FLEX RATE MODAL
    const onFlexRateModalOpen = () => {
        setFlexRateModal(true);
        setFlexRateMode('view');
    };

    const onFlexRateModalCancel = () => {
        setFlexRateModal(false);
        setFlexRateValues(null);
        setActiveFlexRateSet(null);
    };

    const onFlexRateEditBtnClick = () => {
        if (flexRateMode === 'view') {
            setFlexRateValuesEdit(flexRateValues);
            setFlexRateMode('edit');
        } else if (flexRateMode === 'edit') {
            setIsUpdatingFlexRate(true);
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/FlexRateSet/UpdateFlexRateSet/${flexRateValuesEdit.setNo}`,
                    flexRateValuesEdit
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setIsUpdatingFlexRate(false);
                    setFlexRateValues(flexRateValuesEdit);
                    setFlexRateMode('view');
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(
                        err,
                        'Failed to update flexible interest rate'
                    );
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {});
        }
    };

    const onFlexRateAddBtnClick = () => {
        if (flexRateMode !== 'add') {
            setFlexRateMode('add');
            setFlexRateValuesAdd(null);
            return;
        }
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/FlexRateSet/InsertFlexRateSet`,
                flexRateValuesAdd
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(flexRateValuesAdd, 'Flex rate values add');
                const data = res.data.data;
                setFlexRateValues(data);
                setFlexRateSets([...flexRateSets, data.setNo]);
                setFlexRateMode('view');
                setActiveFlexRateSet(data.setNo);
                message.success(`Set ${data.setNo} added`);
                setFlexRateValuesAdd(null);
            });
    };

    const onFlexRateCancelBtnClick = () => {
        setFlexRateValuesEdit(null);
        setFlexRateValuesAdd(null);
        setFlexRateMode('view');
    };

    const onFlexRateSetChange = val => {
        setActiveFlexRateSet(val);
        setFlexRateValues(null);
        setFlexRateMode('view');
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/FlexRateSet/FlexRateDetail/${val}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'Set detail');
                setFlexRateValues(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get flexible set detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    const onFlexRateValuesChange = e => {
        const key = e.target.name;
        const val = e.target.value;
        setFlexRateValues(prevVales => ({
            ...prevVales,
            [key]: val,
        }));
    };

    const onFlexRateEditValuesChange = e => {
        const key = e.target.name;
        const val = e.target.value;
        setFlexRateValuesEdit(prevVales => ({
            ...prevVales,
            [key]: val,
        }));
    };

    const onFlexRateAddValuesChange = e => {
        const key = e.target.name;
        const val = e.target.value;
        setFlexRateValuesAdd(prevVales => ({
            ...prevVales,
            [key]: val,
        }));
    };

    // DELETE FLEX RATE SET
    const onFlexRateDltBtnClick = () => {
        setDeleteFlexRateModal(prevVales => ({
            ...prevVales,
            visible: true,
        }));
    };
    // On modal Ok
    const onDeleteFlexRateModalOk = () => {
        setDeleteFlexRateModal(prevVales => ({
            ...prevVales,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/FlexRateSet/DeleteFlexRateSet/${flexRateValues.setNo}`
            )
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0) {
                    setDeleteFlexRateModal(prevVales => ({
                        ...prevVales,
                        confirmLoading: false,
                    }));
                    message.error(res.data.statusMessage);
                    return;
                }
                setDeleteFlexRateModal(prevVales => ({
                    ...prevVales,
                    visible: false,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to delete flex rate');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteFlexRateModal(prevVales => ({
                    ...prevVales,
                    confirmLoading: false,
                }));
            });
    };
    // On modal cancel
    const onDeleteFlexRateModalCancel = () => {
        setDeleteFlexRateModal(prevVales => ({
            ...prevVales,
            visible: false,
        }));
    };

    // On checkbox change
    const onCheckBoxChange = (e, mode) => {
        const key = e.target.name;
        const checked = e.target.checked;

        if (key === 'loanable') formRef.current.validateFields();

        if (key === 'chequeBookSystem') {
            if (mode === 'edit') {
                setEditFormValues(prevValues => ({
                    ...prevValues,
                    [key]: checked ? 'Yes' : 'No',
                }));
            } else if (mode === 'add') {
                setAddFormValues(prevValues => ({
                    ...prevValues,
                    [key]: checked ? 'Yes' : 'No',
                }));
            }
            return;
        }

        if (key === 'flexibleRateSet') {
            if (mode === 'edit') {
                if (checked) {
                    if (flexRateSets?.length) {
                        setEditFormValues(prevValues => ({
                            ...prevValues,
                            [key]: 1,
                        }));
                    } else {
                        message.error(
                            'No rate set found! Please add a rate set first.'
                        );
                        return;
                    }
                } else {
                    setEditFormValues(prevValues => ({
                        ...prevValues,
                        [key]: 0,
                    }));
                }
            } else if (mode === 'add') {
                if (checked) {
                    if (flexRateSets?.length) {
                        setAddFormValues(prevValues => ({
                            ...prevValues,
                            [key]: 1,
                        }));
                    } else {
                        message.error(
                            'No rate set found! Please add a rate set first.'
                        );
                        return;
                    }
                } else {
                    setAddFormValues(prevValues => ({
                        ...prevValues,
                        [key]: 0,
                    }));
                }
            }
        }

        if (mode === 'edit') {
            setEditFormValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        } else if (mode === 'add') {
            setAddFormValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        }
    };

    // On component mount
    React.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/DepositType`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'Accounts');
                setAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get deposit types accounts');
                message.error('Something went wrong. Please refresh the page!');
            });
    }, [setAccounts]);

    return (
        <div className="deposit-types">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">Deposit Type</h3>

                <DepositTypesForm
                    ref={formRef}
                    mode="view"
                    values={viewFormValues}
                    onChange={onViewFormValuesChange}
                    flexRateSets={flexRateSets}
                    setFlexRateSets={setFlexRateSets}
                />
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditAccountModalOpen}
                        disabled={!viewFormValues?.acno}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddAccountModalOpen}>
                        Add
                    </Button>
                    <Button
                        type="primary"
                        disabled={!viewFormValues?.acno}
                        onClick={onDeleteAccountModalOpen}
                    >
                        Delete
                    </Button>
                </BottomActionBarFixed>
            </div>
            {/* Edit account modal */}
            <Modal
                className="ant-modal--xl"
                title="Edit account"
                visible={editAccountModal.visible}
                confirmLoading={editAccountModal.confirmLoading}
                onOk={onEditAccountModalOk}
                onCancel={onEditAccountModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editAccountModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <DepositTypesForm
                    ref={formRef}
                    mode="edit"
                    values={editFormValues}
                    onChange={onEditFormValuesChange}
                    onCheckBoxChange={onCheckBoxChange}
                    onFlexRateModalOpen={onFlexRateModalOpen}
                    flexRateSets={flexRateSets}
                    setFlexRateSets={setFlexRateSets}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                className="ant-modal--xl"
                title="Add account"
                visible={addAccountModal.visible}
                confirmLoading={addAccountModal.confirmLoading}
                onOk={onAddAccountModalOk}
                onCancel={onAddAccountModalCancel}
                okText="Create"
                // okButtonProps={{
                //     disabled: !addFormValues?.acName,
                // }}
                cancelButtonProps={{
                    disabled: addAccountModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <DepositTypesForm
                    ref={formRef}
                    mode="add"
                    values={addFormValues}
                    setValues={setAddFormValues}
                    onChange={onAddFormValuesChange}
                    onCheckBoxChange={onCheckBoxChange}
                    onFlexRateModalOpen={onFlexRateModalOpen}
                    flexRateSets={flexRateSets}
                    setFlexRateSets={setFlexRateSets}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete account?"
                visible={deleteAccountModal.visible}
                confirmLoading={deleteAccountModal.confirmLoading}
                onOk={onDeleteAccountModalOk}
                onCancel={onDeleteAccountModalCancel}
                okText="Delete"
                okButtonProps={{
                    disabled: !viewFormValues?.acno,
                }}
                cancelButtonProps={{
                    disabled: deleteAccountModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Flexible rates modal */}
            <Modal
                title="Flexible Interest Rate"
                visible={flexRateModal}
                onCancel={onFlexRateModalCancel}
                footer={[
                    ...(flexRateMode === 'add'
                        ? []
                        : [
                              <Button
                                  type="primary"
                                  onClick={onFlexRateEditBtnClick}
                                  loading={isUpdatingFlexRate}
                                  disabled={
                                      isUpdatingFlexRate || !activeFlexRateSet
                                  }
                              >
                                  {`${
                                      flexRateMode === 'view'
                                          ? 'Edit set'
                                          : 'Update'
                                  }`}
                              </Button>,
                          ]),
                    ...(flexRateMode !== 'edit'
                        ? [
                              <Button onClick={onFlexRateAddBtnClick}>
                                  {flexRateMode === 'add' ? 'Create' : 'Add'}{' '}
                                  new set
                              </Button>,
                          ]
                        : []),
                    ...(flexRateMode !== 'view'
                        ? [
                              <Button onClick={onFlexRateCancelBtnClick}>
                                  Cancel
                              </Button>,
                          ]
                        : []),
                    ...(flexRateMode === 'view'
                        ? [
                              <Button
                                  onClick={onFlexRateDltBtnClick}
                                  disabled={!activeFlexRateSet}
                              >
                                  Delete set
                              </Button>,
                          ]
                        : []),
                ]}
                destroyOnClose
                maskClosable={false}
            >
                <Select
                    value={activeFlexRateSet}
                    placeholder="Select a set..."
                    onChange={onFlexRateSetChange}
                    disabled={flexRateMode === 'edit' || flexRateMode === 'add'}
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {flexRateSets.map((set, i) => (
                        <Select.Option key={i} value={set}>
                            Set {set}
                        </Select.Option>
                    ))}
                </Select>

                {flexRateMode === 'view' && (
                    <FlexibleInterestRate
                        mode={flexRateMode}
                        values={flexRateValues}
                        onValuesChange={onFlexRateValuesChange}
                    />
                )}
                {flexRateMode === 'edit' && (
                    <FlexibleInterestRate
                        mode={flexRateMode}
                        values={flexRateValuesEdit}
                        onValuesChange={onFlexRateEditValuesChange}
                    />
                )}
                {flexRateMode === 'add' && (
                    <FlexibleInterestRate
                        mode={flexRateMode}
                        values={flexRateValuesAdd}
                        onValuesChange={onFlexRateAddValuesChange}
                    />
                )}
            </Modal>
            {/* Delete flex rate modal */}
            <Modal
                title="Delete interest rate set?"
                visible={deleteFlexRateModal.visible}
                confirmLoading={deleteFlexRateModal.confirmLoading}
                onOk={onDeleteFlexRateModalOk}
                onCancel={onDeleteFlexRateModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteFlexRateModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>
                    The interest rate set and its data will be deleted forever
                </p>
            </Modal>
        </div>
    );
}
