import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';

const { TextArea } = Input;

const CollectionGroupForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        collectionGroups,
        staffs,
        values,
        onValuesChange,
        mode,
        getStaffs,
        setDefaultValues,
    } = props;

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    // Get staffs
    React.useEffect(() => {
        if (values?.branchID) {
            getStaffs(values.branchID);
        }
    }, [getStaffs, values?.branchID]);

    return (
        <div className="collection-group-form">
            <div className="block-general-titled--body">
                <Form
                    ref={ref}
                    className="margin-top"
                    layout="vertical"
                    onValuesChange={val => onValuesChange(val, mode)}
                    fields={[
                        {
                            name: ['cgno'],
                            value: values?.cgno,
                        },
                        {
                            name: ['cgName'],
                            value: values?.cgName,
                        },
                        {
                            name: ['branchID'],
                            value: values?.branchID,
                        },
                        {
                            name: ['branchName'],
                            value: values?.branchName,
                        },
                        {
                            name: ['staffID'],
                            value: values?.staffID,
                        },
                        {
                            name: ['staffName'],
                            value: values?.staffName,
                        },
                        {
                            name: ['description'],
                            value: values?.description,
                        },
                    ]}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Collection Group Name"
                                name="cgName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {collectionGroups?.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.cgName}
                                            >
                                                {group.cgName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Collection Group ID" name="cgno">
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {collectionGroups?.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.cgno}
                                            >
                                                {group.cgno}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input disabled />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Collectors Name" name="staffName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffs.map((staff, i) => (
                                        <Select.Option
                                            key={i}
                                            value={staff.staffName}
                                        >
                                            {staff.staffName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Collectors ID" name="staffID">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffs.map((staff, i) => (
                                        <Select.Option
                                            key={i}
                                            value={staff.staffID}
                                        >
                                            {staff.staffID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Branch Name"
                                name="branchName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {JSON.parse(
                                        localStorage.getItem('BRANCHES')
                                    ).map((branch, i) => (
                                        <Select.Option
                                            key={i}
                                            value={branch.branchName}
                                        >
                                            {branch.branchName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Branch ID"
                                name="branchID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {JSON.parse(
                                        localStorage.getItem('BRANCHES')
                                    ).map((branch, i) => (
                                        <Select.Option
                                            key={i}
                                            value={branch.branchID}
                                        >
                                            {branch.branchID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={18} xl={10}>
                            <Form.Item label="Description" name="description">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default CollectionGroupForm;
