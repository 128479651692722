import React from 'react';
import { Form, Select, Input, Row, Col, DatePicker } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import validator from 'validator';
import {
    GET_EDUCATION,
    GET_OCCUPATION,
    GET_STAFFS_BY_BRANCH,
} from '../../../../utils/axios';

export default function FamilyDetailForm(props) {
    // Destructuring
    const {
        education,
        setEducation,
        occupation,
        setOccupation,
        staffs,
        setStaffs,
    } = props;

    // On component mount
    React.useEffect(() => {
        (async () => {
            const education = await GET_EDUCATION();
            setEducation(education);
            const occupation = await GET_OCCUPATION();
            setOccupation(occupation);
            const staffs = await GET_STAFFS_BY_BRANCH(
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
            );
            setStaffs(staffs);
        })();
    }, [setEducation, setOccupation, setStaffs]);
    return (
        <div className="family-detail-form">
            <h3 className="colored-title" style={{ marginTop: '0' }}>
                Other Detail
            </h3>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Monthly Income"
                        name="monthlyIncome"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Education" name="education">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {education.map((education, i) => (
                                <Select.Option key={i} value={education}>
                                    {education}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Occupation" name="occupation">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {occupation.map((occupation, i) => (
                                <Select.Option key={i} value={occupation}>
                                    {occupation}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Pan No."
                        name="panno"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Passport No."
                        name="passportNO"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Citizenship No." name="citizenshipno">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Citizenship Issued District"
                        name="citDistrict"
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Citizenship Issued Date" name="citDate">
                        <NepaliDatePicker
                            className="nepali-date-picker--top"
                            inputClassName="ant-input"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={24}>
                    <h3 className="colored-title">Referred By</h3>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Staff Name" name="staffName">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {staffs.map((staff, i) => (
                                <Select.Option key={i} value={staff.staffName}>
                                    {staff.staffName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4}>
                    <Form.Item label="Staff ID" name="staffID">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {staffs.map((staff, i) => (
                                <Select.Option key={i} value={staff.staffID}>
                                    {staff.staffID}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
