import React from 'react';
import { Button, Modal, message } from 'antd';
import moment from 'moment';
import {
    axios,
    GET_LOAN_TYPE_ACCOUNTS_FULL,
    GET_LOAN_HOLDER_ACCOUNT_DETAIL,
    GET_MEMBER_DEPOSIT_INFO_SHORT,
    GET_LOAN_TYPE_ACC_INFO,
    GET_LOAN_PAYMENT_SCHEDULE,
} from '../../utils/axios';
import {
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ,
    NEP_TO_ENG_ISO,
    ON_CHECKBOX_CHANGE,
    COMPARE_ARRAYS,
} from '../../utils/functions';
import {
    loanRegistrationFirstPanelFields,
    loanRegistrationSecondPanelFields,
} from '../../utils/helpers';
import LoanRegistrationForm from '../../components/LoanRegistrationForm/LoanRegistrationForm';
import KistaOnDepositForm from '../../components/LoanRegistrationForm/KistaOnDepositForm/KistaOnDepositForm';
import PaymentSystemForm from '../../components/LoanRegistrationForm/PaymentSystemForm/PaymentSystemForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function LoanRegistration() {
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [members, setMembers] = React.useState([]);
    const [loanTypeAccounts, setLoanTypeAccounts] = React.useState([]);
    const [depositTypeAccounts, setDepositTypeAccounts] = React.useState([]);
    const [savingAccMemList, setSavingAccMemList] = React.useState([]);
    const [loanHolderAccounts, setLoanHolderAccounts] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [kistaPeriod, setKistaPeriod] = React.useState([]);
    const [loanNoValidationStatus, setLoanNoValidationStatus] = React.useState(
        []
    );
    const [loanSchedule, setLoanSchedule] = React.useState([]);
    const [activePanelKey, setActivePanelKey] = React.useState('0');
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Variables and constants
    const tabPanes = [
        {
            title: 'Account Detail',
            component: <KistaOnDepositForm />,
        },
        {
            title: 'Rate and Payment System',
            component: <PaymentSystemForm />,
        },
    ];
    const lastPanelKey = tabPanes.length - 1 + '';

    // Utility functions
    // Check if panel is first
    const isPanelFirst = () => {
        return activePanelKey === '0';
    };
    // Check if panel is last
    const isPanelLast = () => {
        return activePanelKey === lastPanelKey;
    };
    // On tabs change
    const onPanelChange = activeKey => {
        setActivePanelKey(activeKey);
    };
    // On Panel Prev
    const onPanelPrev = () => {
        setActivePanelKey(+activePanelKey - 1 + '');
    };
    // Go to next panel
    const goToNextPanel = () => {
        setActivePanelKey(+activePanelKey + 1 + '');
    };
    // On Panel Next
    const onPanelNext = () => {
        formRef.current
            .validateFields()
            .then(() => {
                goToNextPanel();
            })
            .catch(() => {
                message.error('Please fill all required fields!');
                let errorFields = [];
                formRef.current
                    .getFieldsError()
                    .forEach(
                        field =>
                            field.errors.length &&
                            errorFields.push(field.name[0])
                    );
                if (activePanelKey === '0') {
                    if (
                        !COMPARE_ARRAYS(
                            errorFields,
                            loanRegistrationFirstPanelFields
                        )
                    )
                        goToNextPanel();
                } else if (activePanelKey === '1') {
                    if (
                        !COMPARE_ARRAYS(
                            errorFields,
                            loanRegistrationSecondPanelFields
                        )
                    )
                        goToNextPanel();
                }
            });
    };

    // EDIT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...editValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    intBeforeInstallment:
                        editValues?.intBeforeInstallment || false,
                    equalLoanPmt: editValues?.equalLoanPmt || false,
                    pmtFromBegining: editValues?.pmtFromBegining || false,
                };
                console.log('payload: ', payload);
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/LoanHolder/Update/${editValues?.acno}/${editValues?.loanNo}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update loan registration'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setActivePanelKey('0');
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    intBeforeInstallment:
                        addValues?.intBeforeInstallment || false,
                    equalLoanPmt: addValues?.equalLoanPmt || false,
                    pmtFromBegining: addValues?.pmtFromBegining || false,
                };
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/LoanHolder/Insert/${
                            addValues.acno
                        }?LoanNo=${addValues.loanNo || null}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        // setAccounts([
                        //     ...accounts,
                        //     {
                        //         acno: data.acno,
                        //         acName: data.acName,
                        //     },
                        // ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New Loan registration added!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to add loan registration');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            entranceDate: moment().format(),
            agreementDate: moment().format(),
            interestStartDate: moment().format(),
            installmentStartDate: moment().format(),
            sameDay: true,
            intBeforeInstallment: false,
            equalLoanPmt: false,
            pmtFromBegining: false,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            lpInterval: '1',
            lps: 'Installment',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    const updateValuesInState = React.useCallback((valObj, mode) => {
        if (mode === 'view')
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        else if (mode === 'edit')
            setEditValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        else if (mode === 'add')
            setAddValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
    }, []);
    // On values change
    const onValuesChange = (val, mode) => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            updateValuesInState(valObj, mode);
        };
        if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            return updateValues(branch);
        } else if (
            key === 'entranceDate' ||
            key === 'agreementDate' ||
            key === 'interestStartDate' ||
            key === 'installmentStartDate' ||
            key === 'finalDate'
        ) {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'acno' || key === 'acName') {
            const acc = loanTypeAccounts.find(acc => acc[key] === value);
            if (mode === 'add') {
                (async () => {
                    const accInfo = await GET_LOAN_TYPE_ACC_INFO(acc.acno);
                    return updateValues(accInfo);
                })();
            }
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, ['loanNo']),
                ...acc,
            });
        } else if (key === 'loanNo') {
            if (mode === 'view') {
                (async () => {
                    const detail = await GET_LOAN_HOLDER_ACCOUNT_DETAIL(
                        viewValues?.acno,
                        value
                    );
                    console.log('detail: ', detail);
                    return updateValues(detail);
                })();
            } else if (mode === 'add') {
                checkLoanNoAvailability(addValues.acno, value);
            }
        } else if (key === 'savingType' || key === 'stno') {
            const accKey = key === 'savingType' ? 'acName' : 'acno';
            const acc = depositTypeAccounts.find(acc => acc[accKey] === value);
            return updateValues({
                savingType: acc.acName,
                stno: acc.acno,
            });
        } else if (key === 'memberNo' || key === 'name') {
            const memKey = key === 'memberNo' ? 'memberNO' : 'memberName';
            const member = members.find(mem => mem[memKey] === value);
            (async () => {
                const memInfo = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    member.memberNO
                );
                console.log('memInfo: ', memInfo);
                return updateValues({
                    address: memInfo.opAddress,
                    mobileNO: memInfo.mobileNO,
                });
            })();
            return updateValues({
                memberNo: member.memberNO,
                name: member.memberName,
            });
        } else if (key === 'staffID' || key === 'staffName') {
            const staff = staffs.find(staff => staff[key] === value);
            return updateValues(staff);
        } else if (key === 'agreedLoan') {
            updateValues({ scheduledAmt: value });
        }
        updateValues(val);
    };

    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        console.log(e);
        ON_CHECKBOX_CHANGE(e, mode, setEditValues, setAddValues);
    };

    // COMMON
    // Set new loan no
    const setNewLoanNo = React.useCallback(loanNo => {
        setAddValues(prevValues => ({
            ...prevValues,
            loanNo,
        }));
    }, []);
    // Check loan num availability
    const checkLoanNoAvailability = async (acno, loanNo) => {
        if (loanNo) {
            setLoanNoValidationStatus('validating');
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/LoanHolder/CheckValid/${acno}/${loanNo}`
                );
                const isAvailable = res.data.data;
                console.log('isAvailable: ', isAvailable);
                if (isAvailable) setLoanNoValidationStatus('success');
                else setLoanNoValidationStatus('error');
            } catch (err) {
                console.error(err, 'Failed to check member no availability');
                message.error('Something went wrong. Please try again!');
            }
        } else {
            setLoanNoValidationStatus(null);
        }
    };
    // Handle create schedule
    const handleCreateSchedule = () => {
        formRef.current
            .validateFields()
            .then(() => {
                const values = addValues;
                const {
                    scheduledAmt,
                    rateOfInterest,
                    dmy,
                    totalNoOfKista,
                    intCalcMethod,
                    lps,
                    interestStartDate,
                    installmentStartDate,
                    sameDay,
                    intBeforeInstallment,
                    roundedBy,
                    lpInterval,
                    equalLoanPmt,
                    pmtFromBegining,
                } = values;
                console.log('values: ', values);
                const payload = {
                    scheduledAmt,
                    rateOfInterest,
                    dmy: +dmy,
                    totalNoOfKista,
                    intCalcMethod,
                    lps,
                    interestStartDate,
                    installmentStartDate,
                    sameDay,
                    intBeforeInstallment,
                    roundedBy: roundedBy || 0,
                    lpInterval,
                    equalLoanPmt,
                    pmtFromBegining,
                };
                console.log('payload: ', payload);
                (async () => {
                    const schedule = await GET_LOAN_PAYMENT_SCHEDULE(payload);
                    console.log('schedule: ', schedule);
                    setLoanSchedule(schedule);
                })();
            })
            .catch(() => message.error('Please fill all required fields!'));
    };

    // Form props
    const formProps = {
        ref: formRef,
        branches,
        members,
        setMembers,
        loanTypeAccounts,
        depositTypeAccounts,
        setDepositTypeAccounts,
        loanHolderAccounts,
        setLoanHolderAccounts,
        savingAccMemList,
        setSavingAccMemList,
        staffs,
        setStaffs,
        kistaPeriod,
        setKistaPeriod,
        onValuesChange,
        onCheckboxChange,
        setDefaultValues,
        setNewLoanNo,
        loanNoValidationStatus,
        updateValuesInState,
        handleCreateSchedule,
        loanSchedule,
        activePanelKey,
        tabPanes,
        onPanelChange,
    };

    // On component mount
    React.useEffect(() => {
        (async () => {
            const accounts = await GET_LOAN_TYPE_ACCOUNTS_FULL();
            setLoanTypeAccounts(accounts);
        })();
    }, []);
    return (
        <div className="staff-registration">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Loan Registration
                </h3>
                <LoanRegistrationForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                />
                {/* Edit modal */}
                <Modal
                    title="Edit"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <LoanRegistrationForm
                        {...formProps}
                        mode="edit"
                        values={editValues}
                    />
                </Modal>
                {/* Add modal */}
                <Modal
                    title="Add"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    footer={[
                        <Button
                            type="default"
                            onClick={isPanelFirst() ? undefined : onPanelPrev}
                            disabled={isPanelFirst() || addModal.confirmLoading}
                        >
                            Prev
                        </Button>,
                        <Button
                            type="primary"
                            onClick={isPanelLast() ? onAddModalOk : onPanelNext}
                        >
                            {isPanelLast() ? 'Create' : 'Next'}
                        </Button>,
                    ]}
                    onCancel={onAddModalCancel}
                    destroyOnClose
                    maskClosable={false}
                    width={1100}
                >
                    {' '}
                    <LoanRegistrationForm
                        {...formProps}
                        mode="add"
                        values={addValues}
                    />
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.loanNo}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
