import React from 'react';
import { Form, Select } from 'antd';

export default function Users(props) {
    // Destructuring
    const { item, name, required = false } = props;
    return (
        <Form.Item
            label={item === 'userName' ? 'User Name' : 'User ID'}
            name={name || item}
            rules={[
                {
                    required,
                    message: 'Required',
                },
            ]}
        >
            <Select placeholder="Select...">
                {props?.users?.map((user, i) => (
                    <Select.Option key={i} value={user[item]}>
                        {user[item]}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
