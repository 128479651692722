import React from 'react';
import { message } from 'antd';
import moment from 'moment';
import axios, {
    GET_ALL_CASH_ITEMS,
    GET_ITEM_BALANCE,
} from '../../../../utils/axios';
import CashVaultForm from '../../../../components/Closing/DayOpen/CashVault/CashVaultForm/CashVaultForm';

const getCounterDataDummy = (
    key = 1,
    itemCode = '',
    itemName = '',
    balance = '',
    depositBalance = ''
) => {
    return {
        key: key + '',
        [`itemCode${key}`]: itemCode,
        [`itemName${key}`]: itemName,
        [`balance${key}`]: balance,
        [`depositBalance${key}`]: depositBalance,
    };
};

export default function CashVault() {
    // Local states
    const [values, setValues] = React.useState(null);
    const [cashItems, setCashItems] = React.useState([]);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [counterData, setCounterData] = React.useState([
        getCounterDataDummy(),
    ]);

    // On counter data change
    const onCounterDataChange = (e, data) => {
        const key = e.target.name;
        let val = e.target.value;
        console.log(key, val);
        if (key.includes('itemCode')) val = val.toUpperCase();
        const i = counterData.findIndex(item => item.key === data.key);
        const counterDataUpdated = [...counterData];
        counterDataUpdated[i][key] = val;
        setCounterData(counterDataUpdated);
    };

    // Get counter data
    const getCounterData = async (e, data) => {
        e.preventDefault();
        const key = data.key;
        const itemCode = data[`itemCode${+key}`];

        const found = counterData.find(
            (item, i) =>
                item[`itemCode${i + 1}`] === itemCode &&
                item[`itemName${i + 1}`]
        );
        if (found) return message.error('Counter already listed');
        try {
            const res = await axios.get(
                `${
                    process.env.REACT_APP_HOST
                }/api/PayReceipt/CashCounterWithBalance/${
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                }?ItemCode=${itemCode}`
            );
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            const resData = res.data.data[0];
            const i = counterData.findIndex(item => item.key === key);
            const counterDataUpdated = [...counterData];
            counterDataUpdated[i] = {
                ...counterDataUpdated[i],

                [`balance${key}`]: resData.balance,
                [`itemCode${key}`]: resData.itemCode,
                [`itemName${key}`]: resData.itemName,
            };
            setCounterData(counterDataUpdated);
        } catch (err) {
            console.error(err);
            message.error('Something went wrong. Please try again!');
        }
    };

    // Handle counter data add
    const handleCounterDataAdd = e => {
        e.preventDefault();
        const counterDataUpdated = [
            ...counterData,
            getCounterDataDummy(+(counterData.length + 1)),
        ];
        setCounterData(counterDataUpdated);
    };

    // Get current balance
    React.useEffect(() => {
        if (values?.itemCode) {
            (async () => {
                try {
                    const balance = await GET_ITEM_BALANCE(
                        undefined,
                        '080',
                        '080.01',
                        values?.itemCode
                    );
                    setValues(prevValues => ({
                        ...prevValues,
                        currentBalance: balance,
                    }));
                } catch (err) {
                    console.error(err, 'Failed to get item balance');
                    message.error('Something went wrong. Please try again!');
                }
            })();
        }
    }, [values?.itemCode]);

    // On form Submit
    const onFormSubmit = async () => {
        setIsProcessing(true);
        const payload = {
            transDate: moment().format(),
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType: 'Cash Vault Transaction',
            debitTrans: [
                {
                    mano: '080',
                    acno: '080.01',
                    bvrcno: 'CV001',
                    itemCode: values?.itemCode,
                    itemName: values?.itemName,
                    itemLocation: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    receivedPaidBy: 'Office',
                    remarks1: '',
                    rate: 1,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: values?.grandTotal,
                    quantity: 1,
                    particulars: `Cash deposited to vault - ${values?.itemCode}`,
                },
            ],
            creditTrans: counterData
                .filter((item, i) => item[`depositBalance${i + 1}`])
                .map((item, i) => {
                    return {
                        mano: '080',
                        acno: '080.01',
                        bvrcno: 'CV001',
                        itemCode: item[`itemCode${i + 1}`],
                        itemName: item[`itemName${i + 1}`],
                        itemLocation: JSON.parse(
                            localStorage.getItem('USER_CREDENTIALS')
                        ).branchID,
                        receivedPaidBy: 'Office',
                        rate: item[`depositBalance${i + 1}`],
                        interBranchID: JSON.parse(
                            localStorage.getItem('USER_CREDENTIALS')
                        ).branchID,
                        amount: item[`depositBalance${i + 1}`],
                        quantity: '1',
                        particulars: `Cash from - ${item[`itemName${i + 1}`]}`,
                    };
                }),
        };
        console.log('payload: ', payload);
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/CountertoVault`,
                payload
            );

            if (res.data.statusCode !== 0) {
                setIsProcessing(false);
                return message.error(res.data.statusMessage);
            }

            const data = res.data.data;

            setValues(prevValues => ({
                ...prevValues,
                ...data,
            }));
            message.success(res.data.statusMessage);
        } catch (err) {
            console.error(err, 'Failed to post cash to vault');
            setIsProcessing(false);
        }
    };

    // Handle new
    const handleNew = () => {
        setIsProcessing(false);
        setValues(null);
        setCounterData([getCounterDataDummy()]);
    };

    // Handle counter remove
    const handleCounterRemove = key => {
        if (counterData.length === 1)
            return setCounterData([getCounterDataDummy()]);

        const counterDataUpdated = counterData.filter(item => item.key !== key);
        setCounterData(counterDataUpdated);
    };

    // Handle clear all
    const handleFillAll = async () => {
        try {
            const res = await axios.get(
                `${
                    process.env.REACT_APP_HOST
                }/api/PayReceipt/CashCounterWithBalance/${
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                }`
            );
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            const data = res.data.data;
            setCounterData(
                data.map((item, i) =>
                    getCounterDataDummy(
                        +(i + 1),
                        item.itemCode,
                        item.itemName,
                        item.balance,
                        0
                    )
                )
            );
        } catch (err) {
            console.error(err, 'Failed to get all counters data');
            message.error('Something went wrong. Please try again!');
        }
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // console.log(key, value);

        const updateValues = val => {
            setValues(prevValues => ({
                ...prevValues,
                ...val,
            }));
        };

        if (key === 'itemCode' || key === 'itemName') {
            const item = cashItems.find(item => item[key] === value);
            return updateValues(item);
        }
        // Update values
        updateValues();
    };

    // Set total cash
    React.useEffect(() => {
        const preTotal = counterData.reduce((prevVal, curVal, i) => {
            const depositBalance = curVal[`depositBalance${i + 1}`];
            return +prevVal + +depositBalance;
        }, 0);
        setValues(prevValues => ({
            ...prevValues,
            preTotal,
            grandTotal: preTotal,
        }));
    }, [counterData]);

    // Form props
    const formProps = {
        counterData,
        onCounterDataChange,
        getCounterData,
        handleCounterDataAdd,
        values,
        onValuesChange,
        cashItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        handleFillAll,
        handleCounterRemove,
    };

    // Get all cash items
    React.useEffect(() => {
        (async () => {
            const cashItems = await GET_ALL_CASH_ITEMS();
            setCashItems(cashItems);
        })();
    }, []);
    return (
        <div className="owner-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Deposit to Cash Vault
                </h3>
                <div className="container" style={{ marginLeft: 'unset' }}>
                    <CashVaultForm {...formProps} />
                </div>
            </div>
        </div>
    );
}
