import React from 'react';
import { Button, Modal, message } from 'antd';
import {
    axios,
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
    GET_SAVING_TYPE_ACCOUNT_DETAIL,
    GET_MEMBER_DEPOSIT_INFO_SHORT,
    GET_SAVING_ACC_BASIC_INFO,
    GET_NEW_SAVING_TYPE_ACC_NO,
    GET_OWNERS_BY_MEMBER_NO,
} from '../../utils/axios';
import moment from 'moment';
import {
    JSON_TO_FORM_DATA,
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
    DLT_KEYS_FROM_OBJ,
    ON_FILE_CHANGE,
    COMPARE_ARRAYS,
    DLT_KEYS_FROM_OBJ_EXCEPT,
} from '../../utils/functions';
import {
    depositRegistrationFirstPanelFields,
    depositRegistrationSecondPanelFields,
    depositRegistrationThirdPanelFields,
} from '../../utils/helpers';

import DepositRegistrationForm from '../../components/DepositRegistrationForm/DepositRegistrationForm';
import DepositRegistrationForm1 from '../../components/DepositRegistrationForm/DepositRegistrationForm1/DepositRegistrationForm1';
import DepositRegistrationForm2 from '../../components/DepositRegistrationForm/DepositRegistrationForm2/DepositRegistrationForm2';
import DepositRegistrationForm3 from '../../components/DepositRegistrationForm/DepositRegistrationForm3/DepositRegistrationForm3';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import { UPDATE_AXIOS_AUTH } from '../../utils/functions';

export default function DepositRegistration() {
    const [activeDepositTypeAccounts, setActiveDepositTypeAccounts] =
        React.useState([]);
    const [savingTypeAccounts, setSavingTypeAccounts] = React.useState([]);
    const [savingTypeAccountsFull, setSavingTypeAccountsFull] = React.useState(
        []
    );
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [members, setMembers] = React.useState([]);
    const [transferableAccounts, setTransferableAccounts] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [owners, setOwners] = React.useState([]);
    const [accNoValidationStatus, setAccNoValidationStatus] =
        React.useState(null); // validating | success | error
    const [activePanelKey, setActivePanelKey] = React.useState('0');
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Tabs controls
    // Variables and constants
    const tabPanes = [
        {
            title: 'Deposit Registration Form',
            component: <DepositRegistrationForm1 />,
        },
        {
            title: 'Deposit Details',
            component: <DepositRegistrationForm2 />,
        },
        {
            title: 'Interest Collection',
            component: <DepositRegistrationForm3 />,
        },
    ];
    const lastPanelKey = tabPanes.length - 1 + '';

    // Utility functions
    // Check if panel is first
    const isPanelFirst = () => {
        return activePanelKey === '0';
    };
    // Check if panel is last
    const isPanelLast = () => {
        return activePanelKey === lastPanelKey;
    };
    // On tabs change
    const onPanelChange = activeKey => {
        setActivePanelKey(activeKey);
    };
    // On Panel Prev
    const onPanelPrev = () => {
        setActivePanelKey(+activePanelKey - 1 + '');
    };
    // Go to next panel
    const goToNextPanel = () => {
        setActivePanelKey(+activePanelKey + 1 + '');
    };
    // On Panel Next
    const onPanelNext = () => {
        formRef.current
            .validateFields()
            .then(() => {
                goToNextPanel();
            })
            .catch(() => {
                message.error('Please fill all required fields!');
                let errorFields = [];
                formRef.current
                    .getFieldsError()
                    .forEach(
                        field =>
                            field.errors.length &&
                            errorFields.push(field.name[0])
                    );
                if (activePanelKey === '0') {
                    console.log(errorFields, 'errorFields');
                    if (
                        !COMPARE_ARRAYS(
                            errorFields,
                            depositRegistrationFirstPanelFields
                        )
                    ) {
                        goToNextPanel();
                    }
                } else if (activePanelKey === '1') {
                    if (
                        !COMPARE_ARRAYS(
                            errorFields,
                            depositRegistrationSecondPanelFields
                        )
                    )
                        goToNextPanel();
                } else if (activePanelKey === '2') {
                    if (
                        !COMPARE_ARRAYS(
                            errorFields,
                            depositRegistrationThirdPanelFields
                        )
                    )
                        goToNextPanel();
                }
            });
    };

    // COMMON

    // EDIT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...editValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };
                console.log('payload: ', payload);
                const formData = JSON_TO_FORM_DATA(payload);
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/SavingAccount/Update/${editValues?.acno}/${editValues?.accountNo}`,
                        formData
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update deposit registration'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        getSavingTypeAccounts(viewValues?.branchID, viewValues?.acno);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setActivePanelKey('0');
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    lastModifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };

                const formData = JSON_TO_FORM_DATA(payload);
                axios
                    .post(
                        `${
                            process.env.REACT_APP_HOST
                        }/api/SavingAccount/Insert/${
                            addValues?.acno
                        }?AccountNO=${addValues?.accountNo || null}`,
                        formData
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        // setAccounts([
                        //     ...accounts,
                        //     {
                        //         acno: data.acno,
                        //         acName: data.acName,
                        //     },
                        // ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New deposit added!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to add account');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // COMMON
    // Get saving type accounts
    // Get saving type account numbers
    const getSavingTypeAccounts = (branchID, acno) => {
        (async () => {
            const accounts = await GET_SAVING_TYPE_ACCOUNTS(branchID, acno);
            setSavingTypeAccounts(accounts);
        })();
    };
    // Get saving type accounts full
    const getSavingTypeAccountsFull = (branchID, acno) => {
        (async () => {
            const accounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                branchID,
                acno
            );

            setSavingTypeAccountsFull(accounts);
        })();
    };
    // Get account detail
    const getAccountDetail = (acno, accountNo, cb) => {
        (async () => {
            const accDetail = await GET_SAVING_TYPE_ACCOUNT_DETAIL(
                acno,
                accountNo
            );
            console.log('accDetail: ', accDetail);
            cb(accDetail);
        })();
    };

    // On values change
    const onValuesChange = (val, mode) => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];

        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('acName') || val.hasOwnProperty('acno')) {
            const acc = activeDepositTypeAccounts.find(
                acc => acc[key] === val[key]
            );
            if (mode === 'add') {
                (async () => {
                    const info = await GET_SAVING_ACC_BASIC_INFO(acc.acno);
                    updateValues(info);
                })();
            } else {
                getSavingTypeAccounts(values?.branchID, acc.acno);
            }
            updateValues({
                ...DLT_KEYS_FROM_OBJ_EXCEPT(values || {}, [
                    'acno',
                    'acName',
                    'branchID',
                    'branchName',
                ]),
                ...acc,
            });
            setDefaultValues(mode);
            return;
        } else if (val.hasOwnProperty('accountNo')) {
            if (mode === 'add') {
                checkAccNoAvailability(addValues?.acno, val[key]);
            }
        } else if (
            val.hasOwnProperty('branchName') ||
            val.hasOwnProperty('branchID')
        ) {
            const branch = branches.find(branch => branch[key] === val[key]);
            if (mode !== 'view') {
                return updateValues({
                    ...DLT_KEYS_FROM_OBJ_EXCEPT(values, [
                        'branchID',
                        'branchName',
                    ]),
                    ...branch,
                });
            }
            return updateValues(branch);
        } else if (
            val.hasOwnProperty('memberNo') ||
            val.hasOwnProperty('operator1')
        ) {
            if (mode === 'edit') {
                checkIfMemberHasAccount(
                    values?.memberNo,
                    values?.acno,
                    values?.accountNo
                );
            }
            const memberKey = key === 'memberNo' ? 'memberNO' : 'memberName';
            const member = members.find(
                member => member[memberKey] === val[key]
            );
            (async () => {
                const memberInfo = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    member.memberNO
                );
                console.log('memberInfo: ', memberInfo);
                updateValues({
                    memberNo: member.memberNO,
                    operator1: member.memberName,
                    opAddress1: memberInfo.opAddress,
                    mobileNO1: memberInfo.mobileNO,
                    mobileNO: memberInfo.mobileNO,
                    photoLoc: memberInfo.photoloc,
                    signature1: memberInfo.signature,
                });
            })();
        } else if (
            val.hasOwnProperty('memberNo2') ||
            val.hasOwnProperty('operator2')
        ) {
            const memberKey = key === 'memberNo2' ? 'memberNO' : 'memberName';
            const member = members.find(
                member => member[memberKey] === val[key]
            );
            (async () => {
                const memberInfo = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    member.memberNO
                );
                console.log('memberInfo: ', memberInfo);
                updateValues({
                    memberNo2: member.memberNO,
                    operator2: member.memberName,
                    opAddress2: memberInfo.opAddress,
                    mobileNO2: memberInfo.mobileNO,
                });
            })();
        } else if (
            val.hasOwnProperty('memberNo3') ||
            val.hasOwnProperty('operator3')
        ) {
            const memberKey = key === 'memberNo3' ? 'memberNO' : 'memberName';
            const member = members.find(
                member => member[memberKey] === val[key]
            );
            (async () => {
                const memberInfo = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    member.memberNO
                );
                updateValues({
                    memberNo3: member.memberNO,
                    operator3: member.memberName,
                    opAddress3: memberInfo.opAddress,
                    mobileNO3: memberInfo.mobileNO,
                });
            })();
        } else if (
            val.hasOwnProperty('memberNo4') ||
            val.hasOwnProperty('operator4')
        ) {
            const memberKey = key === 'memberNo4' ? 'memberNO' : 'memberName';
            const member = members.find(
                member => member[memberKey] === val[key]
            );

            (async () => {
                const memberInfo = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    member.memberNO
                );
                return updateValues({
                    memberNo4: member.memberNO,
                    operator4: member.memberName,
                    opAddress4: memberInfo.opAddress,
                    mobileNO4: memberInfo.mobileNO,
                });
            })();
        } else if (
            val.hasOwnProperty('staffID') ||
            val.hasOwnProperty('staffName')
        ) {
            const staff = staffs.find(staff => staff[key] === val[key]);
            return updateValues(staff);
        } else if (
            val.hasOwnProperty('intColDepTypeName') ||
            val.hasOwnProperty('intColDepType')
        ) {
            const accKey = key === 'intColDepTypeName' ? 'acName' : 'acno';
            const acc = transferableAccounts.find(
                acc => acc[accKey] === val[key]
            );

            return updateValues({
                intColDepTypeName: acc.acName,
                intColDepType: acc.acno,
            });
        } else if (
            val.hasOwnProperty('transferInterestToName') ||
            val.hasOwnProperty('transferInterestTo')
        ) {
            const accKey =
                key === 'transferInterestToName' ? 'fullName' : 'accountNo';
            const acc = savingTypeAccountsFull.find(
                acc => acc[accKey] === val[key]
            );

            return updateValues({
                transferInterestToName: acc.fullName,
                transferInterestTo: acc.accountNo,
            });
        } else if (
            val.hasOwnProperty('ownerNO') ||
            val.hasOwnProperty('ownerName')
        ) {
            const owner = owners.find(owner => owner[key] === val[key]);
            return updateValues({
                ownerNO: owner.ownerNO,
                ownerName: owner.ownerName,
                ownerFullAddress: owner.fullAddress,
            });
        } else if (
            val.hasOwnProperty('entranceDate') ||
            val.hasOwnProperty('startDate') ||
            val.hasOwnProperty('expireDate') ||
            val.hasOwnProperty('interestStartDAte')
        ) {
            const dateISO = NEP_TO_ENG_ISO(val[key]);
            if (val.hasOwnProperty('startDate')) {
                updateValues({
                    interestStartDAte: dateISO,
                });
            }
            return updateValues({
                [key]: dateISO,
            });
        } else if (val.hasOwnProperty('isOwner')) {
            updateValues(
                DLT_KEYS_FROM_OBJ(values, [
                    'ownerNO',
                    'ownerName',
                    'ownerFullAddress',
                ])
            );
        } else if (val.hasOwnProperty('aloneOrCombine')) {
            if (value === 'Alone') {
                updateValues(
                    DLT_KEYS_FROM_OBJ(values, [
                        'memberNo2',
                        'operator2',
                        'opAddress2',
                        'mobileNO2',
                        'op2Sign',
                        'memberNo3',
                        'operator3',
                        'opAddress3',
                        'mobileNO3',
                        'op3Sign',
                        'memberNo4',
                        'operator4',
                        'opAddress4',
                        'mobileNO4',
                        'op4Sign',
                    ])
                );
            }
        }
        updateValues(val);
    };

    // On file change
    const onFileChange = (e, mode) => {
        ON_FILE_CHANGE(e, mode, setEditValues);
    };

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            entranceDate: moment().format(),
            startDate: moment().format(),
            interestStartDAte: moment().format(),
            aloneOrCombine: 'Alone',
            oDlimit: '0',
            lockedAmount: '0',
            savingPeriod: '0',
            interestRate: '0',
            savingRate: '0',
            minimumBalance: '0',
            odIntrate: '0',
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Set end date
    const setEndDate = React.useCallback((dateISO, mode) => {
        if (mode === 'edit') {
            setEditValues(prevValues => ({
                ...prevValues,
                expireDate: dateISO,
            }));
        } else if (mode === 'add') {
            setAddValues(prevValues => ({
                ...prevValues,
                expireDate: dateISO,
            }));
        }
    }, []);

    // Check member num availability
    const checkAccNoAvailability = async (acno, accountNo) => {
        if (accountNo) {
            setAccNoValidationStatus('validating');
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/SavingAccount/CheckValid/${acno}/${accountNo}`
                );
                const isAvailable = res.data.data;
                if (isAvailable) setAccNoValidationStatus('success');
                else setAccNoValidationStatus('error');
            } catch (err) {
                console.error(err, 'Failed to check account no availability');
                message.error('Something went wrong. Please try again!');
            }
        } else {
            setAccNoValidationStatus(null);
        }
    };

    // Form props
    const formProps = {
        ref: formRef,
        activeDepositTypeAccounts,
        savingTypeAccounts,
        savingTypeAccountsFull,
        staffs,
        setStaffs,
        members,
        setMembers,
        transferableAccounts,
        setTransferableAccounts,
        onValuesChange,
        accNoValidationStatus,
        owners,
        setDefaultValues,
        setEndDate,
        onFileChange,
        onPanelChange,
        activePanelKey,
        tabPanes,
    };

    // Get saving type accounts
    // React.useEffect(() => {
    //     if (viewValues?.branchID && viewValues?.acno) {
    //         getSavingTypeAccounts(viewValues.branchID, viewValues.acno);
    //     }
    //     if (editValues?.branchID && editValues?.acno) {
    //         getSavingTypeAccounts(editValues.branchID, editValues.acno);
    //     }
    //     if (addValues?.branchID && addValues?.acno) {
    //         getSavingTypeAccounts(addValues.branchID, addValues.acno);
    //     }
    // }, [
    //     viewValues?.branchID,
    //     viewValues?.acno,
    //     editValues?.branchID,
    //     editValues?.acno,
    //     addValues?.branchID,
    //     addValues?.acno,
    // ]);

    // Get saving type accounts full (receivable accounts)
    React.useEffect(() => {
        if (viewValues?.intColDepTypeName && viewValues?.intColDepType) {
            getSavingTypeAccountsFull(
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
                viewValues.acno
            );
        }
        if (editValues?.intColDepTypeName && editValues?.intColDepType) {
            getSavingTypeAccountsFull(
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
                editValues.acno
            );
        }
        if (addValues?.intColDepTypeName && addValues?.intColDepType) {
            console.log(addValues?.intColDepTypeName, addValues?.intColDepType);
            getSavingTypeAccountsFull(
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
                addValues.acno
            );
        }
    }, [
        viewValues?.intColDepType,
        viewValues?.intColDepTypeName,
        viewValues?.acno,
        editValues?.intColDepType,
        editValues?.intColDepTypeName,
        editValues?.acno,
        addValues?.intColDepType,
        addValues?.intColDepTypeName,
        addValues?.acno,
    ]);

    // Get new saving account number
    React.useEffect(() => {
        if (addValues?.acno && addValues?.branchID) {
            if (
                JSON.parse(localStorage.getItem('ENTERPRISE_DETAIL'))
                    .fetchIDbeforeInsert
            ) {
                setAddValues(prevValues => ({
                    ...prevValues,
                    accountNo: '',
                }));
                (async () => {
                    const accountNo = await GET_NEW_SAVING_TYPE_ACC_NO(
                        addValues.branchID,
                        addValues.acno
                    );
                    setAddValues(prevValues => ({
                        ...prevValues,
                        accountNo,
                    }));
                })();
            }
        }
    }, [addValues?.acno, addValues?.branchID]);

    // Get saving type account detail
    React.useEffect(() => {
        if (viewValues?.accountNo && viewValues?.acno) {
            getAccountDetail(
                viewValues?.acno,
                viewValues?.accountNo,
                setViewValues
            );
        }
        if (editValues?.accountNo && editValues?.acno) {
            getAccountDetail(
                editValues?.acno,
                editValues?.accountNo,
                setEditValues
            );
        }
    }, [
        viewValues?.acno,
        viewValues?.accountNo,
        editValues?.acno,
        editValues?.accountNo,
    ]);

    // Get owners list
    React.useEffect(() => {
        // if (viewValues?.memberNo) {
        //     (async () => {
        //         const owners = await GET_OWNERS_BY_MEMBER_NO(
        //             viewValues.memberNo
        //         );
        //         setOwners(owners);
        //     })();
        // }
        if (editValues?.memberNo) {
            (async () => {
                const owners = await GET_OWNERS_BY_MEMBER_NO(
                    editValues.memberNo
                );
                setOwners(owners);
            })();
        }
        if (addValues?.memberNo) {
            (async () => {
                const owners = await GET_OWNERS_BY_MEMBER_NO(
                    addValues.memberNo
                );
                setOwners(owners);
            })();
        }
    }, [viewValues?.memberNo, editValues?.memberNo, addValues?.memberNo]);

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        (async () => {
            const accounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setActiveDepositTypeAccounts(accounts);
        })();
    }, []);

    // Check if member has account
    const checkIfMemberHasAccount = (memberNo, acno, accountNo) => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/SavingAccount/MemberhasAccount/${memberNo}/${acno}/${accountNo}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
            });
    };

    // Check if member has account no (Add)
    React.useEffect(() => {
        if (addValues?.acno && addValues?.accountNo && addValues?.memberNo) {
            const memberNo = addValues?.memberNo;
            const acno = addValues?.acno;
            const accountNo = addValues?.accountNo;
            checkIfMemberHasAccount(memberNo, acno, accountNo);
        }
    }, [addValues?.acno, addValues?.accountNo, addValues?.memberNo]);

    return (
        <div className="staff-registration">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Deposit Registration
                </h3>
                <DepositRegistrationForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                />
                <Modal
                    title="Edit"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <DepositRegistrationForm
                        {...formProps}
                        mode="edit"
                        values={editValues}
                    />
                </Modal>
                {/* Add modal */}
                <Modal
                    title="Add"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    footer={[
                        <Button
                            type="default"
                            onClick={isPanelFirst() ? undefined : onPanelPrev}
                            disabled={
                                isPanelFirst() || editModal.confirmLoading
                            }
                        >
                            Prev
                        </Button>,
                        <Button
                            type="primary"
                            onClick={isPanelLast() ? onAddModalOk : onPanelNext}
                        >
                            {isPanelLast() ? 'Create' : 'Next'}
                        </Button>,
                    ]}
                    onCancel={onAddModalCancel}
                    destroyOnClose
                    maskClosable={false}
                    width={1100}
                >
                    <DepositRegistrationForm
                        {...formProps}
                        mode="add"
                        values={addValues}
                    />
                </Modal>
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.accountNo}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
