import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
    ENG_ISO_TO_NEP,
    CONTAINS_DIGITS_ONLY,
    CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY,
} from '../../../utils/functions';

const { TextArea } = Input;

const AssetsRegistrationForm = React.forwardRef((props, ref) => {
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];

    // Destructuring
    const {
        mode,
        mainAccounts,
        accounts,
        assets,
        values,
        onValuesChange,
        setDefaultValues,
    } = props;

    let assetsNoInput;
    switch (mode) {
        case 'view':
            assetsNoInput = (
                <Select
                    placeholder="Select..."
                    disabled={!values?.acno}
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {assets.map((asset, i) => (
                        <Select.Option key={i} value={asset.assetsNo}>
                            {asset.assetsNo}
                        </Select.Option>
                    ))}
                </Select>
            );
            break;
        case 'edit':
            assetsNoInput = <p>{values?.assetsNo}</p>;
            break;
        case 'add':
            assetsNoInput = <Input />;
            break;
        default:
            assetsNoInput = null;
    }

    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <div className="block-general-titled--body">
            <Form
                ref={ref}
                layout="vertical"
                className="margin-top"
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: ['branchID'],
                        value: values?.branchID,
                    },
                    {
                        name: ['branchName'],
                        value: values?.branchName,
                    },
                    {
                        name: ['maName'],
                        value: values?.maName,
                    },
                    {
                        name: ['mano'],
                        value: values?.mano,
                    },
                    {
                        name: ['acno'],
                        value: values?.acno,
                    },
                    {
                        name: ['acName'],
                        value: values?.acName,
                    },
                    {
                        name: ['nepName'],
                        value: values?.nepName,
                    },
                    {
                        name: ['assetsNo'],
                        value: values?.assetsNo,
                    },
                    {
                        name: ['assetName'],
                        value: values?.assetName,
                    },
                    {
                        name: ['brand'],
                        value: values?.brand,
                    },
                    {
                        name: ['asstSize'],
                        value: values?.asstSize,
                    },
                    {
                        name: ['color'],
                        value: values?.color,
                    },
                    {
                        name: ['marketPrice'],
                        value: values?.marketPrice,
                    },
                    {
                        name: ['depreciation'],
                        value: values?.depreciation,
                    },
                    {
                        name: ['description'],
                        value: values?.description,
                    },
                    {
                        name: ['entranceDate'],
                        value:
                            values?.entranceDate &&
                            ENG_ISO_TO_NEP(values.entranceDate),
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                            label="Main Account Name"
                            name="maName"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            <Select
                                placeholder="Select..."
                                // disabled={!values?.branchID}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {mainAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.maName}>
                                        {acc.maName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item
                            label="Main Account No."
                            name="mano"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            <Select
                                placeholder="Select..."
                                // disabled={!values?.branchID}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {mainAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.mano}>
                                        {acc.mano}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item
                            label="Entry Date"
                            name="entranceDate"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            <NepaliDatePicker inputClassName="ant-input" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                            label="Account Name"
                            name="acName"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            <Select
                                placeholder="Select..."
                                disabled={!values?.mano}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {accounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acName}>
                                        {acc.acName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item
                            label="Account No."
                            name="acno"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            <Select
                                placeholder="Select..."
                                disabled={!values?.mano}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {accounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acno}>
                                        {acc.acno}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Branch Name" name="branchName">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {branches.map((branch, i) => (
                                    <Select.Option
                                        key={i}
                                        value={branch.branchName}
                                    >
                                        {branch.branchName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Branch ID" name="branchID">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {branches.map((branch, i) => (
                                    <Select.Option
                                        key={i}
                                        value={branch.branchID}
                                    >
                                        {branch.branchID}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                            label="Asset No."
                            name="assetsNo"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            {assetsNoInput}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                            label="Asset Name"
                            name="assetName"
                            rules={
                                mode !== 'view' && [
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]
                            }
                        >
                            {mode === 'view' ? (
                                <Select
                                    placeholder="Select..."
                                    disabled={!values?.acno}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {assets.map((asset, i) => (
                                        <Select.Option
                                            key={i}
                                            value={asset.assetName}
                                        >
                                            {asset.assetName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Assets Name Nepali">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Brand" name="brand">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Size" name="asstSize">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Color" name="color">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item
                            label="Estimated Market Price"
                            name="marketPrice"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input addonBefore="Rs" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item
                            label="Depreciation"
                            name="depreciation"
                            rules={[
                                {
                                    required: true,
                                    message: '0 or more',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            CONTAINS_DIGITS_ONLY(value)
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input addonBefore="Rs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} lg={6} xl={12}>
                        <Form.Item label="Description" name="description">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
});

export default AssetsRegistrationForm;
