import React from 'react';
// Import from dependencies
import { Form, message } from 'antd';
// Import utils
import axios from '../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../utils/functions';
// Import components
import DepositTypesGeneralDetail from './DepositTypesGeneralDetail/DepositTypesGeneralDetail';
import DepositTypesSavingDetail from './DepositTypesSavingDetail/DepositTypesSavingDetail';
import DepositTypesNotification from './DepositTypesNotification/DepositTypesNotification';
import DepositTypesInterest from './DepositTypesInterest/DepositTypesInterest';
import DepositTypesClosingSetup from './DepositTypesClosingSetup/DepositTypesClosingSetup';

const DepositTypesFormEdit = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        values,
        setValues,
        onChange,
        mode,
        onCheckBoxChange,
        onFlexRateModalOpen,
        flexRateSets,
        setFlexRateSets,
    } = props;

    React.useEffect(() => {
        if (mode === 'add') {
            axios(
                `${process.env.REACT_APP_HOST}/api/DepositType/NewDepositType`
            )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setValues(prevValues => ({
                        ...prevValues,
                        acno: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(
                        err,
                        'Failed to get new account number for deposit types'
                    );
                    message.error('Something went wrong. Please try again!');
                });
        }
        axios
            .get(`${process.env.REACT_APP_HOST}/api/FlexRateSet`)
            .then(res => {
                if (res.data.statusCode !== 0) return;
                // return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setFlexRateSets(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to load flexible rates sets');
                message.error('Something went wrong. Please try again!');
            });
    }, [mode, setValues, setFlexRateSets]);

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={onChange}
            fields={[
                {
                    name: ['acno'],
                    value: values?.acno,
                },
                {
                    name: ['acName'],
                    value: values?.acName,
                },
                {
                    name: ['nepName'],
                    value: values?.nepName,
                },
                {
                    name: ['typeCode'],
                    value: values?.typeCode,
                },
                {
                    name: ['govCode'],
                    value: values?.govCode,
                },
                {
                    name: ['savingType'],
                    value: values?.savingType,
                },
                {
                    name: ['regSavingPeriod'],
                    value: values?.regSavingPeriod,
                },
                {
                    name: ['savingStatus'],
                    value: values?.savingStatus,
                },
                {
                    name: ['savingRate'],
                    value: values?.savingRate,
                },
                {
                    name: ['lastDayForPmt'],
                    value: values?.lastDayForPmt,
                },
                {
                    name: ['lateFine'],
                    value: values?.lateFine,
                },
                {
                    name: ['fineType'],
                    value: values?.fineType,
                },
                {
                    name: ['acOpeningRate'],
                    value: values?.acOpeningRate,
                },
                {
                    name: ['minBal'],
                    value: values?.minBal,
                },
                {
                    name: ['savingPeriod'],
                    value: values?.savingPeriod,
                },
                {
                    name: ['savingPeriodType'],
                    value: values?.savingPeriodType,
                },
                {
                    name: ['minIntBal'],
                    value: values?.minIntBal,
                },
                {
                    name: ['maxDrawPerDay'],
                    value: values?.maxDrawPerDay,
                },
                {
                    name: ['checkBookSystem'],
                    value: true,
                },
                {
                    name: ['rateOfInterest'],
                    value: values?.rateOfInterest,
                },
                {
                    name: ['intRateOD'],
                    value: values?.loanable ? values?.intRateOD : '',
                },
                {
                    name: ['taxRatePerson'],
                    value: values?.taxRatePerson,
                },
                {
                    name: ['taxRateorg'],
                    value: values?.taxRateorg,
                },
                {
                    name: ['intCalcPeriod'],
                    value: values?.intCalcPeriod && values?.intCalcPeriod + '',
                },
                {
                    name: ['intCalcBasis'],
                    value: values?.intCalcBasis,
                },
                {
                    name: ['autoCalcInterest'],
                    value: values?.autoCalcInterest,
                },
                {
                    name: ['untimeWithdrawCharge'],
                    value: values?.untimeWithdrawCharge,
                },
                {
                    name: ['untimelyWithdrawRateType'],
                    value: values?.untimelyWithdrawRateType,
                },
                {
                    name: ['untimeClosingRate'],
                    value: values?.untimeClosingRate,
                },
                {
                    name: ['untimeClosingRateType'],
                    value: values?.untimeClosingRateType,
                },
                {
                    name: ['closingCharge'],
                    value: values?.closingCharge,
                },
                {
                    name: ['flexibleRateSet'],
                    value: values?.flexibleRateSet,
                },
                {
                    name: ['intStartDate'],
                    value:
                        values?.intStartDate &&
                        ENG_ISO_TO_NEP(values?.intStartDate),
                },
            ]}
        >
            <div className="block-general-titled--body">
                <h3 className="colored-title">Deposit type detail</h3>
                <DepositTypesGeneralDetail mode={mode} values={values} />
            </div>
            <div>
                <div className="block-general-titled--body">
                    <h3 className="colored-title">Saving Detail</h3>
                    <DepositTypesSavingDetail
                        mode={mode}
                        values={values}
                        onCheckBoxChange={onCheckBoxChange}
                    />
                </div>
            </div>
            <div className="">
                <div className="block-general-titled--body">
                    <h3 className="colored-title">Deposit Notifications</h3>
                    <DepositTypesNotification
                        mode={mode}
                        values={values}
                        onCheckBoxChange={onCheckBoxChange}
                    />
                </div>
            </div>
            <div className="">
                <div className="block-general-titled--body">
                    <h3 className="colored-title">Set Interest</h3>
                    <DepositTypesInterest
                        mode={mode}
                        values={values}
                        onCheckBoxChange={onCheckBoxChange}
                        onFlexRateModalOpen={onFlexRateModalOpen}
                        flexRateSets={flexRateSets}
                    />
                </div>
            </div>
            <div className="">
                <div className="block-general-titled--body">
                    <h3 className="colored-title">Deposit Closing Setup</h3>
                    <DepositTypesClosingSetup mode={mode} />
                </div>
            </div>
        </Form>
    );
});

export default DepositTypesFormEdit;
