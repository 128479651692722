import React from 'react';
import { Row, Col, Form, Select, Input } from 'antd';
import Branches from '../../../Common/Geography/Branches/Branches';
const CounterRegistrationForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        setDefaultValues,
        values,
        onValuesChange,
        getCountersByBranch,
        counters,
        getNewCounterNo,
    } = props;

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    // Get counters by branch
    React.useEffect(() => {
        if (values?.branchID) getCountersByBranch(values.branchID);
    }, [getCountersByBranch, values?.branchID]);

    // Get new counter no
    React.useEffect(() => {
        getNewCounterNo();
    }, [getNewCounterNo]);
    return (
        <div>
            <Form
                ref={ref}
                layout="vertical"
                className="margin-top"
                onValuesChange={val => onValuesChange(val, mode)}
                fields={[
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'counterName',
                        value: values?.counterName,
                    },
                    {
                        name: 'counterNO',
                        value: values?.counterNO,
                    },
                    {
                        name: 'description',
                        value: values?.description,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={6}>
                            <Branches
                                item="branchName"
                                required
                                disabled={mode !== 'view'}
                            />
                        </Col>
                        <Col span={4}>
                            <Branches
                                item="branchID"
                                required
                                disabled={mode !== 'view'}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <Form.Item
                                label="Counter Name"
                                name="counterName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {counters?.length &&
                                            counters.map((counter, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={counter.counterName}
                                                >
                                                    {counter.counterName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Counter No"
                                name="counterNO"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={mode !== 'view'}
                                >
                                    {counters?.length &&
                                        counters.map((counter, i) => (
                                            <Select.Option
                                                key={i}
                                                value={counter.counterNO}
                                            >
                                                {counter.counterNO}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4}></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
});

export default CounterRegistrationForm;
