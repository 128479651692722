import React from 'react';
// Import from dependencies
import { Layout, message, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
    enterpriseDetail as enterpriseDetailAtom,
    userCredentials as userCredentialsAtom,
    branches as branchesAtom,
} from './recoil/atoms';
// Import utils
import axios, { SEARCH_MEMBER, SEARCH_MEMBER_DETAIL } from './utils/axios';
// Import components
import SideNav from './components/Common/SideNav/SideNav';
import AppLayoutHeader from './components/AppLayout/AppLayoutHeader/AppLayoutHeader';
import AppLayoutBreadcrumb from './components/AppLayout/AppLayoutBreadcrumb/AppLayoutBreadcrumb';
import ProtectedRoutes from './routes/ProtectedRoutes';
import SearchMemberModal from './components/Common/SearchMemberModal/SearchMemberModal';

function App() {
    // Global states
    const setEnterpriseDetail = useSetRecoilState(enterpriseDetailAtom);
    const setUserCredentials = useSetRecoilState(userCredentialsAtom);
    const setBranches = useSetRecoilState(branchesAtom);
    // Local states
    const [members, setMembers] = React.useState([]);
    const [memberDetailModal, setMemberDetailModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [memberDetail, setMemberDetail] = React.useState(null);

    // Destructuring
    const { Content, Footer } = Layout;
    const { pathname } = useLocation();

    // Handle member search
    const handleMemberSearch = value => {
        if (!value) return;
        (async () => {
            const members = await SEARCH_MEMBER(value);
            setMembers(members);
        })();
    };

    // Handle member select
    const handleMemberSelect = value => {
        setMemberDetailModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
        const valArr = value.split('-');
        (async () => {
            const memberDetail = await SEARCH_MEMBER_DETAIL(
                valArr[1] || valArr[2],
                valArr[1] ? true : false
            );
            console.log('memberDetail: ', memberDetail);
            setMemberDetail(memberDetail);
        })();
    };

    // Handle member detail modal cancel
    const handleMemberDetailModalCancel = () => {
        setMemberDetail(null);
        setMemberDetailModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
    };

    // Scroll to top on pathname change
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // On component mount
    React.useEffect(() => {
        // Enterprise detail
        const localEnterpriseDetail = JSON.parse(
            localStorage.getItem('ENTERPRISE_DETAIL')
        );
        if (localEnterpriseDetail) {
            setEnterpriseDetail(localEnterpriseDetail);
        } else {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'USER_ACCESS'
                    )}`,
                },
            };
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/EnterpriseDetail`,
                    config
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    const data = res.data.data;
                    // Set in global state
                    setEnterpriseDetail(data);
                    // Plant in local storage
                    localStorage.setItem(
                        'ENTERPRISE_DETAIL',
                        JSON.stringify(data)
                    );
                })
                .catch(err => {
                    console.error(err, 'Failed to load Enterprise detail');
                    message.error(err, 'Failed to load Enterprise detail');
                });
        }
        // User credentials
        const userCredentials = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        );
        if (userCredentials) setUserCredentials(userCredentials);

        // Branches
        const branches = JSON.parse(localStorage.getItem('BRANCHES'));
        if (branches) setBranches(branches);
    }, [setEnterpriseDetail, setUserCredentials, setBranches]);

    return (
        <div className="app">
            <div className="app__wrapper">
                <Layout style={{ minHeight: '100vh' }}>
                    <SideNav />
                    <Layout className="site-layout">
                        <AppLayoutHeader />
                        <AppLayoutBreadcrumb
                            handleMemberSearch={handleMemberSearch}
                            handleMemberSelect={handleMemberSelect}
                            members={members}
                        />
                        <Content style={{ margin: '0 16px' }}>
                            <div className="site-layout-background">
                                <ProtectedRoutes />
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                            OXPAN ©2022. All rights reserved.
                        </Footer>
                    </Layout>
                </Layout>
            </div>
            <SearchMemberModal
                memberDetailModal={memberDetailModal}
                memberDetail={memberDetail}
                onCancel={handleMemberDetailModalCancel}
            />
        </div>
    );
}

export default App;
