import React from 'react';
import { Form, Select } from 'antd';

export default function Staffs(props) {
    // Destructuring
    const { label, item, name, disabled = false, required = false } = props;
    return (
        <Form.Item
            label={
                label ? label : item === 'staffName' ? 'Staff Name' : 'Staff No'
            }
            name={name || item}
            rules={[
                {
                    required,
                    message: 'Required',
                },
            ]}
        >
            <Select
                placeholder="Select..."
                showSearch
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabled}
            >
                {props.staffs.map((staff, i) => (
                    <Select.Option key={i} value={staff[item]}>
                        {staff[item]}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
