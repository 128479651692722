import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from 'antd';
const { TextArea } = Input;

const GroupTypeForm = React.forwardRef((props, ref) => {
    // Destructuring
    const { groupTypes, values, onValuesChange, onCheckboxChange, mode } =
        props;
    return (
        <div className="group-type-form">
            <div className="block-general-titled--body">
                <Form
                    ref={ref}
                    className="margin-top"
                    layout="vertical"
                    onValuesChange={onValuesChange}
                    fields={[
                        {
                            name: ['gtid'],
                            value: values?.gtid,
                        },
                        {
                            name: ['groupType'],
                            value: values?.groupType,
                        },
                        {
                            name: ['desc'],
                            value: values?.desc,
                        },
                    ]}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Group Type No." name="gtid">
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        disabled={!groupTypes?.length}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {groupTypes?.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.gtid}
                                            >
                                                {group.gtid}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input disabled />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item
                                label="Group Type Name"
                                name="groupType"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        disabled={!groupTypes?.length}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {groupTypes?.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.gtid}
                                            >
                                                {group.groupType}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3 className="colored-title">Rights and Authorities</h3>
                    <Row gutter={20}>
                        <Col xs={24} md={12} lg={6}>
                            <Form.Item label="Share Transaction">
                                <Checkbox
                                    name="hasShare"
                                    checked={values?.hasShare}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Has Shares
                                </Checkbox>
                                <Checkbox
                                    name="hasGuardian"
                                    checked={values?.hasGuardian}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Has Guardian
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Saving Rights">
                                <Checkbox
                                    name="compSaving"
                                    checked={values?.compSaving}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Compulsory Saving
                                </Checkbox>
                                <Checkbox
                                    name="otherSaving"
                                    checked={values?.otherSaving}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Other Saving
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} md={12} lg={6}>
                            <Form.Item label="Loan Rights">
                                <Checkbox
                                    name="emmLoan"
                                    checked={values?.emmLoan}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Emergency Loan
                                </Checkbox>
                                <Checkbox
                                    name="otherLoan"
                                    checked={values?.otherLoan}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Other Loan
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Other Activities">
                                <Checkbox
                                    name="agmVoting"
                                    checked={values?.agmVoting}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    AGM Voting
                                </Checkbox>
                                <Checkbox
                                    name="boardCandidate"
                                    checked={values?.boardCandidate}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Board Candidate
                                </Checkbox>
                                <Checkbox
                                    name="staff"
                                    checked={values?.staff}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Staff
                                </Checkbox>
                                <Checkbox
                                    name="guarantee"
                                    checked={values?.guarantee}
                                    onChange={onCheckboxChange}
                                    disabled={mode === 'view'}
                                >
                                    Guarantee
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={14}>
                            <Form.Item label="Description" name="desc">
                                <TextArea rows={4} disabled={mode === 'view'} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default GroupTypeForm;
