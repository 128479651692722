import React from 'react';
import {
    Row,
    Col,
    Form,
    Checkbox,
    Select,
    Input,
    Tooltip,
    message,
} from 'antd';
import { useRecoilState } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherAccounts as otherAccountsAtom,
    otherItems as otherItemsAtom,
} from '../../../recoil/atoms';
import axios, {
    GET_CASH_COUNTERS,
    GET_BANKS,
    GET_OTHER_ACCOUNTS,
    GET_OTHER_ITEMS,
} from '../../../utils/axios';

export default function PaymentSource(props) {
    // Destructuring
    const { mode, title, values } = props;

    // Global states
    const [cashCounters, setCashCounters] = useRecoilState(cashCountersAtom);
    const [banks, setBanks] = useRecoilState(banksAtom);
    const [otherAccounts, setOtherAccounts] = useRecoilState(otherAccountsAtom);
    const [otherItems, setOtherItems] = useRecoilState(otherItemsAtom);
    // Local states
    const [cashBalance, setCashBalance] = React.useState(null);
    const [bankBalance, setBankBalance] = React.useState(null);
    const [otherBalance, setOtherBalance] = React.useState(null);

    // Get cash counters, banks and others
    React.useEffect(() => {
        const branchID =
            values?.branchID ||
            JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID;
        (async () => {
            // Get cash counters
            const counters = await GET_CASH_COUNTERS(branchID);
            setCashCounters(counters);
            // Get banks
            const banks = await GET_BANKS(branchID);
            setBanks(banks);
            // Other accounts
            const otherAccounts = await GET_OTHER_ACCOUNTS(branchID);
            setOtherAccounts(otherAccounts);
        })();
    }, [values?.branchID, setBanks, setCashCounters, setOtherAccounts]);

    // Get other items
    React.useEffect(() => {
        if (values?.otherAcno) {
            const branchID =
                values?.branchID ||
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID;
            (async () => {
                const otherItems = await GET_OTHER_ITEMS(
                    branchID,
                    undefined,
                    values?.otherAcno
                );
                setOtherItems(otherItems);
            })();
        }
    }, [values?.branchID, values?.otherAcno, setOtherItems]);

    // Get cash balance
    React.useEffect(() => {
        if (values?.cashNo) {
            const branchID =
                values?.branchID ||
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID;
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${branchID}/080/080.01/${values?.cashNo}`
                )
                .then(res => setCashBalance(res.data.data))
                .catch(err => {
                    console.error(err, 'Failed to get cash balance');
                    message.error('Failed to get cash balance');
                });
        } else {
            setCashBalance(null);
        }
    }, [values?.branchID, values?.cashNo]);

    // Get bank balance
    React.useEffect(() => {
        if (values?.bankNo) {
            const branchID =
                values?.branchID ||
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID;
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${branchID}/090/090.01/${values?.bankNo}`
                )
                .then(res => setBankBalance(res.data.data))
                .catch(err => {
                    console.error(err, 'Failed to get bank balance');
                    message.error('Failed to get bank balance');
                });
        } else {
            setBankBalance(null);
        }
    }, [values?.branchID, values?.bankNo]);

    // Get other balance
    React.useEffect(() => {
        if (values?.otherAcno && values?.otherNo) {
            const branchID =
                values?.branchID ||
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID;
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/CheckBalance/ItemBalance/${branchID}/${values?.otherAcno.substring(
                        0,
                        3
                    )}/${values?.otherAcno}/${values?.otherNo}`
                )
                .then(res => setOtherBalance(res.data.data))
                .catch(err => {
                    console.error(err, 'Failed to get other balance');
                    message.error('Failed to get other balance');
                });
        } else {
            setOtherBalance(null);
        }
    }, [values?.branchID, values?.otherAcno, values?.otherNo]);
    return (
        <>
            <div className="payment-source">
                <div className="payment-source__body">
                    <p className="colored-title" style={{ marginTop: '0' }}>
                        {title}
                    </p>
                    <Row gutter={20}>
                        <Col span={1}>
                            <Form.Item name="isCash" valuePropName="checked">
                                <Checkbox></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Cash"
                                name="cashName"
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 19 }}
                                rules={[
                                    {
                                        required: values?.isCash,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isCash}
                                >
                                    {cashCounters?.length &&
                                        cashCounters.map((counter, i) => (
                                            <Select.Option
                                                key={i}
                                                value={counter.itemName}
                                            >
                                                {counter.itemName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="cashNo"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isCash,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isCash}
                                >
                                    {cashCounters?.length &&
                                        cashCounters.map((counter, i) => (
                                            <Select.Option
                                                key={i}
                                                value={counter.itemCode}
                                            >
                                                {counter.itemCode}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="cashBvrcno"
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    placeholder="Bvrcno"
                                    disabled={!values?.isCash}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={4}>
                            <Tooltip
                                title={cashBalance}
                                visible={cashBalance || cashBalance === 0}
                                placement="right"
                            >
                                <Form.Item
                                    colon={false}
                                    wrapperCol={{ span: 24 }}
                                    // label="Amount"
                                    name="cashAmount"
                                    rules={[
                                        {
                                            required: values?.isCash,
                                            message: 'Required',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value || +value > 0) {
                                                    if (mode === 'payment') {
                                                        if (
                                                            (cashBalance ||
                                                                cashBalance ===
                                                                    0) &&
                                                            +value >
                                                                +cashBalance
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Amount overflow'
                                                                )
                                                            );
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error('Invalid amount')
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input disabled={!values?.isCash} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={1}>
                            <Form.Item name="isBank" valuePropName="checked">
                                <Checkbox></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Bank"
                                name="bankName"
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 19 }}
                                rules={[
                                    {
                                        required: values?.isBank,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isBank}
                                >
                                    {banks?.length &&
                                        banks.map((bank, i) => (
                                            <Select.Option
                                                key={i}
                                                value={bank.itemName}
                                            >
                                                {bank.itemName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="bankNo"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isBank,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isBank}
                                >
                                    {banks?.length &&
                                        banks.map((bank, i) => (
                                            <Select.Option
                                                key={i}
                                                value={bank.itemCode}
                                            >
                                                {bank.itemCode}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="bankBvrcno"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isClearance,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Bvrcno"
                                    disabled={!values?.isBank}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="isClearance"
                                valuePropName="checked"
                            >
                                <Checkbox disabled={!values?.isBank}>
                                    Cheque clearance
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Tooltip
                                title={bankBalance}
                                visible={bankBalance || bankBalance === 0}
                                placement="right"
                            >
                                <Form.Item
                                    name="bankAmount"
                                    colon={false}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: values?.isBank,
                                            message: 'Required',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value || +value > 0) {
                                                    if (mode === 'payment') {
                                                        if (
                                                            (bankBalance ||
                                                                bankBalance ===
                                                                    0) &&
                                                            +value >
                                                                +bankBalance
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Amount overflow'
                                                                )
                                                            );
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error('Invalid amount')
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input disabled={!values?.isBank} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={1}>
                            <Form.Item name="isOther" valuePropName="checked">
                                <Checkbox></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Other"
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 19 }}
                                name="otherAcName"
                                rules={[
                                    {
                                        required: values?.isOther,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isOther}
                                >
                                    {otherAccounts?.length &&
                                        otherAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="otherAcno"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isOther,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isOther}
                                >
                                    {otherAccounts?.length &&
                                        otherAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="otherNo"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isOther,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isOther}
                                >
                                    {otherItems?.length &&
                                        otherItems.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.itemCode}
                                            >
                                                {item.itemCode}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="otherName"
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: values?.isOther,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!values?.isOther}
                                >
                                    {otherItems?.length &&
                                        otherItems.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.itemName}
                                            >
                                                {item.itemName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={4}>
                            <Tooltip
                                title={otherBalance}
                                visible={otherBalance || otherBalance === 0}
                                placement="right"
                            >
                                <Form.Item
                                    name="otherAmount"
                                    colon={false}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: values?.isOther,
                                            message: 'Required',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value || +value > 0) {
                                                    if (
                                                        (mode === 'receipt' &&
                                                            (+values?.otherMano <=
                                                                parseInt(
                                                                    '070'
                                                                ) ||
                                                                +values?.otherMano ===
                                                                    parseInt(
                                                                        '160'
                                                                    ))) ||
                                                        (mode === 'payment' &&
                                                            +values?.otherMano >
                                                                parseInt(
                                                                    '070'
                                                                ) &&
                                                            +values?.otherMano !==
                                                                parseInt('150'))
                                                    ) {
                                                        if (
                                                            (otherBalance ||
                                                                otherBalance ===
                                                                    0) &&
                                                            +value >
                                                                +otherBalance
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Amount overflow'
                                                                )
                                                            );
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error('Invalid amount')
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input disabled={!values?.isOther} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="In Words"
                                name="totalAmountInWords"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={5}>
                            <Form.Item
                                label="Total"
                                name="totalAmount"
                                // rules={[
                                //     { required: true, message: 'Required' },
                                //     ({ getFieldValue }) => ({
                                //         validator(_, value) {
                                //             if (
                                //                 !value ||
                                //                 getFieldValue('newShare') ===
                                //                     value
                                //             ) {
                                //                 return Promise.resolve();
                                //             }
                                //             return Promise.reject(
                                //                 new Error('Unbalanced amount')
                                //             );
                                //         },
                                //     }),
                                // ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
