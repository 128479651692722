import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Button,
    Space,
    Table,
    Popconfirm,
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

const GeneralIncomeForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        incomeAccounts,
        incomeItems,
        values,
        onValuesChange,
        isProcessing,
        handleNew,
        onFormSubmit,
        tableData,
        handleDataAdd,
        handleDataRemove,
    } = props;

    // Local states

    const columns = [
        {
            title: 'Item No',
            dataIndex: 'itemCode',
            key: 'itemCode',
        },
        {
            title: 'Income Item',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Received From',
            dataIndex: 'receivedFrom',
            key: 'receivedFrom',
        },
        {
            title: 'Old Balance',
            dataIndex: 'receivable',
            key: 'receivable',
        },
        {
            title: 'Received',
            dataIndex: 'received',
            key: 'received',
        },
        {
            title: 'Voucher No',
            dataIndex: 'bvrcno',
            key: 'bvrcno',
        },
        {
            title: '',
            render: (text, data) => (
                <Popconfirm
                    title="Remove?"
                    onConfirm={() => handleDataRemove(data.key)}
                    okText="Remove"
                    cancelText="No"
                >
                    <Button size="small">
                        <DeleteFilled />
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    return (
        <div>
            <Form
                ref={ref}
                layout="horizontal"
                className="margin-top"
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'itemCode',
                        value: values?.itemCode,
                    },
                    {
                        name: 'itemName',
                        value: values?.itemName,
                    },
                    {
                        name: 'receivedFrom',
                        value: values?.receivedFrom,
                    },
                    {
                        name: 'receivable',
                        value: values?.receivable,
                    },
                    {
                        name: 'received',
                        value: values?.received,
                    },
                    {
                        name: 'accountNo',
                        value: values?.accountNo,
                    },
                    {
                        name: 'accountNoAlt',
                        value: values?.accountNoAlt,
                    },
                    {
                        name: 'fullName',
                        value: values?.fullName,
                    },
                    {
                        name: 'memberNo',
                        value: values?.memberNo,
                    },
                    {
                        name: 'address',
                        value: values?.address,
                    },
                    {
                        name: 'expireDate',
                        value:
                            values?.expireDate &&
                            ENG_ISO_TO_NEP(values.expireDate),
                    },
                    {
                        name: 'receivedPaidBy',
                        value: values?.receivedPaidBy,
                    },
                    {
                        name: 'transDate',
                        value:
                            values?.transDate &&
                            ENG_ISO_TO_NEP(values.transDate),
                    },
                    {
                        name: 'preTotal',
                        value: values?.preTotal,
                    },
                    {
                        name: 'cashNo',
                        value: values?.cashNo,
                    },
                    {
                        name: 'cashName',
                        value: values?.cashName,
                    },
                    {
                        name: 'cashAmount',
                        value: values?.cashAmount,
                    },
                    {
                        name: 'bankNo',
                        value: values?.bankNo,
                    },
                    {
                        name: 'bankName',
                        value: values?.bankName,
                    },
                    {
                        name: 'bankAmount',
                        value: values?.bankAmount,
                    },
                    {
                        name: 'otherNo',
                        value: values?.otherNo,
                    },
                    {
                        name: 'otherName',
                        value: values?.otherName,
                    },
                    {
                        name: 'otherAmount',
                        value: values?.otherAmount,
                    },
                    {
                        name: 'otherAcno',
                        value: values?.otherAcno,
                    },
                    {
                        name: 'otherAcName',
                        value: values?.otherAcName,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'totalAmount',
                        value: values?.totalAmount,
                    },
                    {
                        name: 'totalAmountInWords',
                        value: values?.totalAmountInWords,
                    },

                    {
                        name: 'actualBal',
                        value: values?.actualBal,
                    },
                    {
                        name: 'isCash',
                        value: values?.isCash,
                    },
                    {
                        name: 'description',
                        value: values?.description,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Income Accounts"
                                name="acName"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {incomeAccounts?.length &&
                                        incomeAccounts.map((item, i) => (
                                            <Select.Option value={item.acName}>
                                                {item.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Account No"
                                name="acno"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {incomeAccounts?.length &&
                                        incomeAccounts.map((item, i) => (
                                            <Select.Option value={item.acno}>
                                                {item.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Voucher No"
                                name="bvrcno"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Date"
                                name="transDate"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Income Item"
                                name="itemName"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {incomeItems?.length &&
                                        incomeItems.map((item, i) => (
                                            <Select.Option
                                                value={item.itemName}
                                            >
                                                {item.itemName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Item No"
                                name="itemCode"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {incomeItems?.length &&
                                        incomeItems.map((item, i) => (
                                            <Select.Option
                                                value={item.itemCode}
                                            >
                                                {item.itemCode}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline"></Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Journal No"
                                name="journalNO"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Received From"
                                name="receivedFrom"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Old Balance"
                                name="receivable"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Received"
                                name="received"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value === '0')
                                                return Promise.reject(
                                                    new Error('Amount required')
                                                );
                                            if (
                                                !value ||
                                                getFieldValue('receivable') >=
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('Amount overflow')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input onPressEnter={handleDataAdd} />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Trans No"
                                name="transNo"
                                labelCol={{ span: 7 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col
                            span={6}
                            className="form-item-inline text-right"
                        ></Col>
                        <Col span={6} className="form-item-inline"></Col>
                        <Col
                            span={6}
                            className="form-item-inline text-right"
                        ></Col>
                        <Col span={6} className="form-item-inline"></Col>
                    </Row>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        className="ant-table-wrapper--collapsed"
                        style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
                        pagination={false}
                    />
                    <Row gutter={20}>
                        <Col span={6}></Col>
                        <Col span={6}></Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Description"
                                name="description"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="form-item-inline">
                            <Form.Item
                                label="Total Paid"
                                name="preTotal"
                                wrapperCol={{ span: 17 }}
                                labelCol={{ span: 7 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <PaymentSource
                        title="Receipt Source"
                        mode="receipt"
                        values={values}
                    />
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        onClick={onFormSubmit}
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
});

export default GeneralIncomeForm;
