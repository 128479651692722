import React from 'react';
// Import from dependencies
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherAccounts as otherAccountsAtom,
    otherItems as otherItemsAtom,
} from '../../../recoil/atoms';
import { useHistory } from 'react-router-dom';
// Import utils
import axios, { GET_MEMBERS_BY_BRANCH } from '../../../utils/axios';
import moment from 'moment';
import {
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
// Import components
import ReceiptEntryForm from '../../../components/Accounts/ReceiptEntry/ReceiptEntryForm/ReceiptEntryForm';

export default function ReceiptEntry(props) {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [isProcessing, setIsProcessing] = React.useState(false);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [members, setMembers] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [isPageLoading, setIsPageLoading] = React.useState(false);

    // Destructuring
    const { location } = props;

    // Refs
    const formRef = React.useRef();

    // Variables and Constants
    const history = useHistory();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        console.log(key, value);
        let values;
        if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            handleNew();
            updateValues(branch);
        } else if (key === 'memberNO' || key === 'memberName') {
            history.push('/receipt-entry');
            const member = members.find(member => member[key] === value);
            console.log('member: ', member);
            updateValues(member);
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);
            console.log('acc: ', acc);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherName', 'otherNo']),
                otherAcno: acc.acno,
                otherAcName: acc.acName,
                otherMano: acc.mano,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'transDate') {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        } else if (key.includes('remark')) {
            const i = +key.match(/(\d+)/)[0];
            const tableDataUpdated = [...tableData];
            tableDataUpdated[i] = {
                ...tableDataUpdated[i],
                [key]: value,
            };
            setTableData(tableDataUpdated);
        } else if (key.includes('received')) {
            const i = +key.match(/(\d+)/)[0];
            const tableDataUpdated = [...tableData];
            tableDataUpdated[i] = {
                ...tableDataUpdated[i],
                [key]: value,
            };
            setTableData(tableDataUpdated);
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            isClearance: false,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = val => {
        // console.log(viewValues, 'viewValues');
        // console.log(val, 'val');
        setIsProcessing(true);
        const payload = {
            transDate: viewValues?.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            memberNO: viewValues?.memberNO,
            memberName: viewValues?.memberName,
            memAddress: viewValues?.address,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType:
                viewValues?.branchID ===
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
                    ? 'Receipt Entry'
                    : 'ABBS Receipt Entry',
            partyDocument: viewValues?.incomeSource,
            description: viewValues?.description,
            creditTrans: tableData
                .filter((item, i) => item[`received${i}`])
                .map((item, i) => {
                    let mano, acno, itemCode, remarks1;
                    if (item.transaction === 'Loan') {
                        mano = '070';
                        acno = '070.10';
                        itemCode = item.acno.slice(-2) + item.itemCode;
                        remarks1 = item.acno;
                    } else {
                        mano = item.acno.slice(0, 3);
                        acno = item.acno;
                        itemCode = item.itemCode;
                        remarks1 = item[`remark${i}`] || '0';
                    }
                    return {
                        mano,
                        acno,
                        bvrcno: '',
                        itemCode,
                        itemName: viewValues?.memberName,
                        itemLocation: viewValues?.address,
                        receivedPaidBy: viewValues?.memberName,
                        remarks1,
                        remarks4: item[`remark${+item.key}`] || '0',
                        rate: item[`received${+item.key}`],
                        interBranchID: viewValues?.branchID,
                        amount: item[`received${+item.key}`],
                        quantity: '1',
                        particulars: `Receipt Entry - (${viewValues?.memberName} - ${viewValues?.memberNO})`,
                    };
                }),
            debitTrans: [
                ...(viewValues?.isCash
                    ? [
                          {
                              mano: '080',
                              acno: '080.01',
                              bvrcno: viewValues?.cashBvrcno,
                              itemCode: viewValues?.cashNo,
                              itemName: viewValues?.cashName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.memberName,
                              remarks1: '0',
                              rate: viewValues?.cashAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.cashAmount,
                              quantity: '1',
                              particulars: `Receipt Entry - ${viewValues?.memberName}`,
                          },
                      ]
                    : []),
                ...(viewValues?.isBank
                    ? [
                          {
                              mano: bankAccounts[0].mano,
                              acno: bankAccounts[0].acno,
                              bvrcno: viewValues?.bankBvrcno,
                              itemCode: viewValues?.bankNo,
                              itemName: viewValues?.bankName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.memberName,
                              remarks1: '0',
                              rate: viewValues?.bankAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.bankAmount,
                              quantity: '1',
                              particulars: `By - ${viewValues?.bankName}`,
                          },
                      ]
                    : []),
                ...(viewValues?.isOther
                    ? [
                          {
                              mano: viewValues.otherMano,
                              acno: viewValues.otherAcno,
                              bvrcno: '',
                              itemCode: viewValues?.otherNo,
                              itemName: viewValues?.otherName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.memberName,
                              remarks1: '0',
                              rate: viewValues?.otherAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.otherAmount,
                              quantity: '1',
                              particulars: `Received From - ${viewValues?.otherName}`,
                          },
                      ]
                    : []),
            ],
        };
        console.log('payload: ', payload);
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/ReceiptEntry`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to process share addition');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues('view');
        setIsProcessing(false);
    };

    const getDetailInstant = async e => {
        const val = e.target.value;
        e.preventDefault();
        setViewValues({
            accountNo: val,
        });
        setDefaultValues('view');
        const accountNo = val;
        getMemberDetail(accountNo, viewValues.transDate);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        members,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        tableData,
        getDetailInstant,
    };

    // Get member detail
    const getMemberDetail = React.useCallback(
        async (memberNO, transDate) => {
            const date = moment(transDate).format('YYYY-MM-DD');
            try {
                setTableData([]);
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/Receipt/Receipt/${memberNO}/${date}`
                );
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log('detail: ', res.data.data);
                setIsPageLoading(false);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                    memberName: res.data.data.name,
                    memberNO: res.data.data.memberNo,
                    branchName: branches.find(
                        branch => branch.branchID === res.data.data.branchID
                    ).branchName,
                }));
                const tableData = res.data.data.dataList.map((item, i) => {
                    return {
                        key: i + '',
                        ...item,
                        [`received${i}`]: item.received,
                        [`remark${i}`]: item.remark,
                    };
                });
                console.log('tableData: ', tableData);
                setTableData(tableData);
            } catch (err) {
                console.error(err, 'Failed to get member detail');
                message.error('Something went wrong. Please try again!');
            }
        },
        [branches]
    );

    // Get member detail
    React.useEffect(() => {
        if (viewValues?.memberNO && viewValues?.transDate) {
            setIsPageLoading(true);
            getMemberDetail(viewValues.memberNO, viewValues.transDate);
        }
    }, [viewValues?.memberNO, viewValues?.transDate, getMemberDetail]);

    // Get members by branch
    React.useEffect(() => {
        if (viewValues?.branchID) {
            // Get members by branch
            (async () => {
                const members = await GET_MEMBERS_BY_BRANCH(
                    viewValues?.branchID
                );
                setMembers(members);
            })();
        }
    }, [viewValues?.branchID]);

    // Get Current share
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.memberNO) {
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${viewValues.branchID}/010/010.01/${viewValues.memberNO}`
                )
                .then(res => {
                    setViewValues(prevValues => ({
                        ...prevValues,
                        currentShare: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to get Current share amount');
                    message.error('Failed to get Current share amount');
                });
        }
    }, [viewValues?.branchID, viewValues?.memberNO]);

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Set pre-total
    React.useEffect(() => {
        const preTotal = tableData.reduce(
            (prevValue, curValue, i) => +prevValue + +curValue[`received${i}`],
            0
        );
        setViewValues(prevValues => ({
            ...prevValues,
            preTotal,
        }));
    }, [tableData]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // on component mount
    React.useEffect(() => {
        if (location.search) {
            setIsPageLoading(true);
            const memberNO = location.search.substring(
                location.search.indexOf('=') + 1
            );
            setViewValues(prevValues => ({
                ...prevValues,
                memberNO,
            }));
        }
    }, [location.search]);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            Share Addition
                        </h4>
                        <Spin indicator={antIcon} spinning={isPageLoading}>
                            <ReceiptEntryForm
                                {...formProps}
                                mode="view"
                                values={viewValues}
                            />
                        </Spin>
                    </div>
                </div>
            </div>
        </>
    );
}
