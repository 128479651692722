import React from 'react';
import {
    Form,
    Row,
    Col,
    Radio,
    Space,
    Select,
    Checkbox,
    Table,
    Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';

export default function TrialBalanceForm(props) {
    // Destructuring
    const {
        branches,
        viewValues: values,
        onValuesChange,
        trialBalance,
    } = props;

    // DATA SOURCE - START
    const dataSourceLeft = [];
    const dataSourceRight = [];
    const dataLeft = trialBalance
        ? trialBalance?.trialBalance.filter(
              (item, i) => i === 0 || (i === 2 && item)
          )
        : [];
    const dataRight = trialBalance
        ? trialBalance?.trialBalance.filter(
              (item, i) => i === 1 || (i === 3 && item)
          )
        : [];

    const fillDataSource = (item, dataSource) => {
        dataSource.push({
            key: dataSource.length + 1,
            one: '',
            two: item?.saName,
            three: '',
        });
        item.dataList.forEach(item => {
            dataSource.push({
                key: dataSource.length + 1,
                one: item.mano,
                two: item.maName,
                three: '',
            });
            item.acList.forEach(item => {
                dataSource.push({
                    key: dataSource.length + 1,
                    one: item.acno,
                    two: item.acName,
                    three: item.balance,
                });
            });
        });
        dataSource.push({
            key: dataSource.length + 1,
            one: '',
            two: `TOTAL ${item.saName}`,
            three: item.totalBalance,
        });
    };

    dataLeft.forEach(item => fillDataSource(item, dataSourceLeft));
    dataRight.forEach(item => fillDataSource(item, dataSourceRight));

    const columns = [
        {
            title: 'A/C No',
            dataIndex: 'one',
            key: 'one',
            render: (text, data) => {
                if (!data.one || !data.three) {
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                } else if (data.one && data.two && data.three) {
                    return <Link to="/">{text}</Link>;
                } else {
                    return <div className="ant-table-cell-link">{text}</div>;
                }
            },
        },
        {
            title: 'Particulars',
            dataIndex: 'two',
            key: 'two',
            render: (text, data) => {
                if (!data.one && !data.three) {
                    return (
                        <div className="ant-table-cell-link">
                            <Tag color="#F26722" style={{ margin: '.25rem 0' }}>
                                {text}
                            </Tag>
                        </div>
                    );
                } else if (!data.one || !data.three) {
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                } else if (data.one && data.two && data.three) {
                    return <Link to="/">{text}</Link>;
                } else {
                    return text;
                }
            },
        },
        {
            title: 'Amount Rs',
            dataIndex: 'three',
            key: 'three',
            render: (text, data) => {
                if (data.one && data.two && data.three) {
                    return <Link to="/">{text}</Link>;
                } else if (!data.one) {
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                } else {
                    return <div className="ant-table-cell-link">{text}</div>;
                }
            },
        },
    ];
    // DATA SOURCE - END

    return (
        <div>
            <Form
                layout="vertical"
                className="margin-top"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'suspenseAccount',
                        value: values?.suspenseAccount,
                    },
                    {
                        name: 'odLoanSide',
                        value: values?.odLoanSide,
                    },
                    {
                        name: 'acHeadTotal',
                        value: values?.acHeadTotal,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={4}>
                            <Form.Item label="From" name="fromDate">
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Till" name="tillDate">
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Level" name="level">
                                <Radio.Group
                                // onChange={this.onChange}
                                // value={value}
                                >
                                    <Space direction="vertical">
                                        <Radio value={1}>Account</Radio>
                                        <Radio value={2}>Sub account</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Branch ID" name="branchID">
                                <Select placeholder="Select...">
                                    <Select.Option value="all">
                                        All
                                    </Select.Option>
                                    {branches?.length &&
                                        branches.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.branchID}
                                            >
                                                {item.branchID}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Branch Name" name="branchName">
                                <Select placeholder="Select...">
                                    <Select.Option value="all">
                                        All
                                    </Select.Option>
                                    {branches?.length &&
                                        branches.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.branchName}
                                            >
                                                {item.branchName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="Show options"
                                name="suspenseAccount"
                                valuePropName="checked"
                                className="form-item-collapsed"
                            >
                                <Checkbox>Show susp.</Checkbox>
                            </Form.Item>
                            <Form.Item
                                // label="Show options"
                                name="odLoanSide"
                                valuePropName="checked"
                                className="form-item-collapsed"
                            >
                                <Checkbox>OD on loan side</Checkbox>
                            </Form.Item>
                            <Form.Item
                                // label="Show options"
                                name="acHeadTotal"
                                valuePropName="checked"
                                className="form-item-collapsed"
                            >
                                <Checkbox>A/C head total</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Row gutter={20}>
                <Col span={12}>
                    <Table
                        dataSource={dataSourceLeft}
                        columns={columns}
                        className="ant-table-wrapper--collapsed ant-table-wrapper--link"
                        pagination={false}
                    />
                </Col>
                <Col span={12}>
                    <Table
                        dataSource={dataSourceRight}
                        columns={columns}
                        className="ant-table-wrapper--collapsed ant-table-wrapper--link"
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    );
}
