import React from 'react';
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import { useLocation, useHistory } from 'react-router-dom';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherItems as otherItemsAtom,
    otherAccounts as otherAccountsAtom,
} from '../../../recoil/atoms';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import {
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    NEP_TO_ENG_ISO,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
import axios, {
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
    GET_CLOSING_ACCOUNT_INFO,
    GET_SAVING_ACCOUNT_CHEQUE_NOS,
} from '../../../utils/axios';
import ClosingAccountForm from '../../../components/Savings/ClosingAccount/ClosingAccountForm/ClosingAccountForm';

export default function ClosingAccount() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [depTypeAccounts, setDepTypeAccounts] = React.useState([]);
    const [savingTypeAccounts, setSavingTypeAccounts] = React.useState([]);
    const [chequeNos, setChequeNos] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [lightbox, setLightbox] = React.useState({
        index: 0,
        visible: false,
    });

    // Variables and Constants
    const location = useLocation();
    const history = useHistory();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            transDate: moment().format(),
            receivedPaidBy: 'Self',
            untimeCharge: 0,
            isClearance: false,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        } else if (key === 'acno' || key === 'acName') {
            const acc = depTypeAccounts.find(acc => acc[key] === value);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, ['accountNo']),
                ...acc,
            });
        } else if (key === 'accountNo' || key === 'fullName') {
            const acc = savingTypeAccounts.find(acc => acc[key] === value);
            return updateValues(acc);
        } else if (key === 'transDate') {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherNo', 'otherName']),
                otherMano: acc.mano,
                otherAcno: acc.acno,
                otherAcName: acc.acName,
            });
        } else if (key === 'interest') {
            if (viewValues?.selfCalculation) {
                const tax = Math.round((+value * +viewValues?.taxRate) / 100);
                updateValues({ tax, odInterest: 0 });
            }
        }
        // Update values
        updateValues(val);
    };

    // On form submit
    const onFormSubmit = () => {
        setIsProcessing(true);
        const payload = {
            transDate: viewValues?.transDate,
            depACNO: viewValues?.acno,
            depAccountNo: viewValues?.accountNo,
            depACName: viewValues?.acName,
            memberNo: viewValues?.memberNo,
            depName: viewValues?.fullName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            interest: viewValues?.interest,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            pay: +viewValues?.payRec >= 0 ? viewValues?.preTotal : 0,
            received: +viewValues?.payRec < 0 ? viewValues?.preTotal : 0,
            unpaidInterest: viewValues?.unpaidInterest,
            intProvision: viewValues?.intProvision,
            provisionTax: viewValues?.provisionTax,
            odInterest: viewValues?.odInterest,
            tax: viewValues?.tax,
            calculatedODInterest: viewValues?.calculatedODInterest,
            withdrawBalance: viewValues?.balance,
            untimelyFine: viewValues?.untimelyFine,
            closingCharge: viewValues?.closingCharge,
            bvrcno: viewValues?.chequeNo,
            remarks4: viewValues?.selfCalculation ? 'Self Calculated' : '',
            remove: viewValues?.remove,
            disabled: viewValues?.disabled,
            cashItem: viewValues?.cashName,
            cashItemNo: viewValues?.cashNo,
            cashBal: viewValues?.cashAmount || 0,
            cBVRCNO: viewValues?.cashBvrcno,
            bankItem: viewValues?.bankName,
            bankItemNo: viewValues?.bankNo,
            bankBal: viewValues?.bankAmount || 0,
            bBVRCNO: viewValues?.bankBvrcno,
            otherACNO: viewValues?.otherAcno,
            otherItem: viewValues?.otherAcName,
            otherItemNo: viewValues?.otherItemNo,
            otherBal: viewValues?.otherAmount || 0,
        };
        console.log(payload, 'payload');
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/CloseSavingAccount`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to process close saving account');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Get dep short info
    const getDepShortInfo = () => {
        if (!viewValues?.branchID || !viewValues?.accountNoAlt) return;
        // setViewValues(DLT_KEYS_FROM_OBJ_EXCEPT(viewValues, ['accountNoAlt']));

        const branchID = viewValues?.branchID;
        const accountNo = viewValues?.accountNoAlt;
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountInfoShort/${branchID}/${accountNo}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get saving account info short');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues();
        setIsProcessing(false);
    };

    // Handle show signature
    const handleShowSignature = () => {
        if (!viewValues?.signature) {
            return message.error('No signature found');
        }
        setLightbox({
            visible: true,
        });
    };

    // Handle get dep short info quick
    const getDepShortInfoQuick = e => {
        e.preventDefault();
        getDepShortInfo();
    };

    // Form props
    const formProps = {
        depTypeAccounts,
        savingTypeAccounts,
        onValuesChange,
        getDepShortInfo,
        isProcessing,
        handleNew,
        onFormSubmit,
        getDepShortInfoQuick,
        chequeNos,
        handleShowSignature,
    };

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const depTypeAccounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setDepTypeAccounts(depTypeAccounts);
            setDefaultValues();
        })();
    }, []);

    // Get saving type accounts
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.acno) {
            console.log(viewValues.branchID, viewValues.acno);
            (async () => {
                const savingTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                    viewValues.branchID,
                    viewValues.acno
                );
                setSavingTypeAccounts(savingTypeAccounts);
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.acno]);

    // Get saving type info short
    React.useEffect(() => {
        if (
            viewValues?.acno &&
            viewValues?.accountNo &&
            viewValues?.transDate
        ) {
            const branchID = JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID;
            const acno = viewValues.acno;
            const accountNo = viewValues.accountNo;
            const transDate = viewValues?.transDate;
            (async () => {
                const payload = {
                    acno,
                    accountNo,
                    tillDate: transDate,
                    intDate: transDate,
                    branchID,
                };
                const closingAccountInfo = await GET_CLOSING_ACCOUNT_INFO(
                    payload
                );
                console.log('closingAccountInfo: ', closingAccountInfo);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...closingAccountInfo,
                }));
                setDefaultValues();
            })();
        }
    }, [viewValues?.accountNo, viewValues?.acno, viewValues?.transDate]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Get cheque nos
    React.useEffect(() => {
        if (viewValues?.acno && viewValues?.accountNo) {
            (async () => {
                const acno = viewValues.acno;
                const accountNo = viewValues.accountNo;
                const chequeNos = await GET_SAVING_ACCOUNT_CHEQUE_NOS(
                    acno,
                    accountNo
                );
                console.log('chequeNos: ', chequeNos);
                setChequeNos(chequeNos);
            })();
        }
    }, [viewValues?.acno, viewValues?.accountNo]);

    // Set Untime fine
    React.useEffect(() => {
        if (
            viewValues?.ucRateType &&
            viewValues?.balance &&
            viewValues?.untimeclosingRate
        ) {
            const ucRateType = viewValues.ucRateType;
            const balance = viewValues.balance;
            const untimeclosingRate = viewValues.untimeclosingRate;
            let untimelyFine;
            if (ucRateType === 'Percent')
                untimelyFine = (balance * untimeclosingRate) / 100;
            else untimelyFine = untimeclosingRate;
            untimelyFine = Math.round(untimelyFine);
            setViewValues(prevValues => ({
                ...prevValues,
                untimelyFine,
            }));
        }
    }, [
        viewValues?.ucRateType,
        viewValues?.balance,
        viewValues?.untimeclosingRate,
    ]);

    // Set payable receivable
    React.useEffect(() => {
        const balance = +viewValues?.balance || 0;
        // Plus
        const unpaidInterest = +viewValues?.unpaidInterest || 0;
        const intProvision = +viewValues?.intProvision || 0;
        const interest = +viewValues?.interest || 0;
        // minus
        const provisionTax = +viewValues?.provisionTax || 0;
        const tax = +viewValues?.tax || 0;
        const odInterest = +viewValues?.odInterest || 0;
        const calculatedODInterest = +viewValues?.calculatedODInterest || 0;
        const untimelyFine = +viewValues?.untimelyFine || 0;
        const closingCharge = +viewValues?.closingCharge || 0;

        let payRec =
            balance +
            unpaidInterest +
            intProvision +
            interest -
            provisionTax -
            tax -
            odInterest -
            calculatedODInterest -
            untimelyFine -
            closingCharge;
        payRec = payRec.toFixed(2);
        setViewValues(prevValues => ({
            ...prevValues,
            payRec,
            preTotal: Math.abs(payRec),
        }));
    }, [
        viewValues?.balance,
        viewValues?.unpaidInterest,
        viewValues?.intProvision,
        viewValues?.interest,
        viewValues?.provisionTax,
        viewValues?.tax,
        viewValues?.odInterest,
        viewValues?.calculatedODInterest,
        viewValues?.untimelyFine,
        viewValues?.closingCharge,
    ]);

    // Extract query params and get info
    React.useEffect(() => {
        if (location.search) {
            const search = location.search;
            const arr = search.split('&');
            const acno = arr[0].split('=')[1];
            const acName = arr[1].split('=')[1].replace(/%20/g, ' ');
            const accountNo = arr[2].split('=')[1];

            setViewValues(prevValues => ({
                ...prevValues,
                acno,
                acName,
                accountNo,
            }));
            history.push('/closing-account');
        }
    }, [location.search, history]);

    return (
        <div className="owner-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">Closing Account</h3>
                <div className="container-fluid">
                    <ClosingAccountForm {...formProps} values={viewValues} />
                </div>
            </div>
            {/* Signature lightbox */}
            {lightbox.visible && (
                <Lightbox
                    mainSrc={`${process.env.REACT_APP_STATIC_FILES}/${viewValues?.signature}`}
                    imageLoadErrorMessage="Failed to load signature"
                    onCloseRequest={() =>
                        setLightbox(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }))
                    }
                />
            )}
        </div>
    );
}
