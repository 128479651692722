import React from 'react';
// Import from dependencies
import { Button, Modal, message } from 'antd';
// Import utils
import axios from '../../../utils/axios';
import moment from 'moment';
import {
    UPDATE_AXIOS_AUTH,
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ,
    DLT_KEYS_FROM_OBJ_EXCEPT,
    NEP_TO_ENG_ISO,
} from '../../../utils/functions';
// Import components
import GeneralAccountForm from '../../../components/GeneralAccount/GeneralAccountForm/GeneralAccountForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import { Link } from 'react-router-dom';

export default function GeneralAccount() {
    // Local states

    const [superAccounts, setSuperAccounts] = React.useState([]);
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [generalAccounts, setGeneralAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get super accounts
    const getSuperAccounts = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/SuperAccount`)
            .then(res => setSuperAccounts(res.data.data))
            .catch(err => {
                console.error(err, 'Failed to load Super accounts');
                message.error('Something went wrong!');
            });
    };

    // Get new account number to create account
    const getNewAccNum = mano => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Account/NewACNO/${mano}`)
            .then(res => {
                console.log('New account num: ', res);
                setAddValues(prevValues => ({
                    ...prevValues,
                    acno: res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get new account number');
                message.error('Something went wrong!');
            });
    };

    // Request to get main accounts by super account
    const getMainAccountsBySuperAccount = React.useCallback(saname => {
        // Request
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/MainAccount/GetGeneralAccount/${saname}`
            )
            .then(res => {
                setMainAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get main accounts');
                message.error('Something went wrong!');
            });
    }, []);

    // Request to get General accounts by MANO
    const getGeneralAccountsByMano = React.useCallback(mano => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Account/GetGeneralAccount/${mano}`
            )
            .then(res => {
                console.log(res.data.data, 'General accounts');
                setGeneralAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get General accounts');
                message.error('Something went wrong!');
            });
    }, []);

    // Get general account detail
    const getGeneralAccountDetail = acno => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Account/GeneralAccountDetail/${acno}`
            )
            .then(res => {
                const data = res.data.data;
                console.log('data: ', data);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to load General account details');
                message.error('Something went wrong!');
            });
    };

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('saname')) {
            if (mode === 'view') {
                viewValues &&
                    updateValues(DLT_KEYS_FROM_OBJ_EXCEPT(values, ['saname']));
            } else if (mode === 'add') {
                updateValues(
                    addValues &&
                        DLT_KEYS_FROM_OBJ(values, ['mano', 'maName', 'acno'])
                );
            }
        } else if (val.hasOwnProperty('maName') || val.hasOwnProperty('mano')) {
            const acc = mainAccounts.find(acc => acc[key] === val[key]);
            if (mode === 'view') {
                viewValues &&
                    updateValues(
                        DLT_KEYS_FROM_OBJ_EXCEPT(values, [
                            'saname',
                            'mano',
                            'maName',
                        ])
                    );
            }
            return updateValues(acc);
        } else if (val.hasOwnProperty('acno') || val.hasOwnProperty('acName')) {
            const acc = generalAccounts.find(acc => acc[key] === val[key]);
            if (mode === 'view') getGeneralAccountDetail(acc.acno);
            else {
                if (val.hasOwnProperty('acName')) {
                    return updateValues({ [key]: value.toUpperCase() });
                }
            }
        } else if (val.hasOwnProperty('formedDate')) {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        }
        // Update values
        updateValues(val);
    };

    // On edit account button click
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On Edit account modal OK
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Account/UpdateAccount/${editValues?.acno}`,
                        editValues
                    )
                    .then(res => {
                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Account updated');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to edit account');
                        message.error('Failed to update account');
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setEditValues(null);
    };

    // ADD ACCOUNT MODAL
    // On Add account modal OK
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Account/AddAccount/${addValues.acno}`,
                        addValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setViewValues(data);
                        setGeneralAccounts([
                            ...generalAccounts,
                            { acno: data.acno, acName: data.acName },
                        ]);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Account created');
                        setAddValues(null);
                    })
                    .catch(err => {
                        console.error(err, 'Failed to create account');
                        message.error('Failed to create account');
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On Add Modal Cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On Add Modal Open
    const onAddModalOpen = () => {
        setAddValues({
            saname: viewValues?.saname,
            maName: viewValues?.maName,
            mano: viewValues?.mano,
        });
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On Add Modal After Close
    const onAddModalAfterClose = () => {
        viewValues?.saname && getMainAccountsBySuperAccount(viewValues.saname);
        viewValues?.mano && getGeneralAccountsByMano(viewValues.mano);
    };

    // DELETE ACCOUNT MODAL
    // On delete account button click
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On delete account modal Ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Account/DeleteAccount/${viewValues?.acno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Account deleted');
            })
            .catch(err => console.error(err, 'Failed to delete account'))
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On delete account modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            formedDate: moment().format(),
            isMemRel: false,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        superAccounts,
        mainAccounts,
        generalAccounts,
        setDefaultValues,
        getMainAccountsBySuperAccount,
        getGeneralAccountsByMano,
    };

    // Get new account number
    React.useEffect(() => {
        if (addValues?.mano) {
            getNewAccNum(addValues.mano);
        }
    }, [addValues?.mano]);

    // On component mount
    React.useState(() => {
        // Update axios headers
        UPDATE_AXIOS_AUTH();
        // Get super accounts
        getSuperAccounts();
    }, []);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            General Account
                        </h4>
                        <GeneralAccountForm
                            {...formProps}
                            mode="view"
                            values={viewValues}
                        />
                        <BottomActionBarFixed>
                            <Link to="/general-items" className="ant-btn">
                                New Item
                            </Link>
                            <Button
                                type="primary"
                                onClick={onEditModalOpen}
                                disabled={!viewValues?.acno}
                            >
                                Edit
                            </Button>
                            <Button type="primary" onClick={onAddModalOpen}>
                                Add
                            </Button>
                            <Button
                                type="primary"
                                onClick={onDeleteModalOpen}
                                disabled={!viewValues?.acno}
                            >
                                Delete
                            </Button>
                        </BottomActionBarFixed>
                    </div>
                </div>
            </div>
            {/* Edit account modal */}
            <Modal
                title="Edit Account"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                okText="Update"
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <GeneralAccountForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add Account"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Add account"
                destroyOnClose
                maskClosable={false}
                width={1000}
                afterClose={onAddModalAfterClose}
            >
                <GeneralAccountForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete modal */}
            <Modal
                title="Delete account?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                width={480}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
        </>
    );
}
