import React from 'react';
// Import from dependencies
import { Avatar } from 'antd';

export default function UserDataOneLine({ stat }) {
	return (
		<div className="user-data-one-line">
			<div className="user-data-one-line__avatar">
				<Avatar src={stat.avatar} />
			</div>
			<div className="user-data-one-line__info-primary">{stat.date}</div>
			<div className="user-data-one-line__info-secondary">
				{stat.name}
			</div>
		</div>
	);
}
