import React from 'react';
// Import from dependencies
import { Form, Select, DatePicker } from 'antd';

export default function DailyCollectionForm() {
    return (
        <div className="daily-collection-form">
            <Form layout="vertical">
                <div className="daily-collection-form__row">
                    <div className="daily-collection-form__col">
                        <Form.Item label="Saving Account Type">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option></Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="daily-collection-form__col">
                        <Form.Item label="Label">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option></Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="daily-collection-form__col">
                        <Form.Item label="Label">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option></Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="daily-collection-form__col">
                        <Form.Item label="Label">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option></Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="daily-collection-form__col">
                        <Form.Item label="Entry Date">
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
}
