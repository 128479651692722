import React from 'react';
import { Button, message, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import { userCredentials as userCredentialsAtom } from '../../../recoil/atoms';
import moment from 'moment';
// Import utils
import axios from '../../../utils/axios';
import { SET_DEFAULT_VALUES, NEP_TO_ENG_ISO } from '../../../utils/functions';
// Import components
import GeneralItemsForm from '../../../components/ItemSetup/GeneralItemsForm/GeneralItemsForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function GeneralItems() {
    // Global states
    const userCredentials = useRecoilValue(userCredentialsAtom);
    // Local states
    const [superAccounts, setSuperAccounts] = React.useState([]);
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [generalAccounts, setGeneralAccounts] = React.useState([]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [generalItems, setGeneralItems] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [asyncUpdateModal, setAsyncUpdateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // COMMON
    // Get super accounts
    const getSuperAccounts = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/SuperAccount`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setSuperAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get super accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get main accounts
    const getMainAccounts = saname => {
        setMainAccounts([]);
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/MainAccount/GetGeneralAccount/${saname}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'main accounts');
                setMainAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get main accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get general accounts
    const getGeneralAccounts = mano => {
        setGeneralAccounts([]);
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Account/GetGeneralAccount/${mano}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'general accounts');
                setGeneralAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get general accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get general items
    const getGeneralItems = branchID => {
        setGeneralItems([]);
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/ItemMaster/${viewValues.acno}/${branchID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setGeneralItems(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get general items');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get general item detail
    const getGeneralItemDetail = itemCode => {
        setGeneralItems([]);
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/ItemMaster/Detail/${viewValues?.acno}/${itemCode}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'item detail');
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get general item detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    // On Super account change
    const onSAChange = obj => {
        // prettier-ignore
        ['mano', 'maName', 'acno', 'acName', 'nepName', 'branchID', 'branchName', 'itemCode', 'itemName', 'formedDate','itemLocation', 'isGovCode', 'govCode', 'description'].forEach(key => delete obj[key]);
        return obj;
    };

    // On main account change
    const onMAChange = obj => {
        // prettier-ignore
        ['acno', 'acName', 'nepName', 'branchID', 'branchName', 'itemCode', 'itemName', 'formedDate','itemLocation', 'isGovCode', 'govCode', 'description'].forEach(key => delete obj[key]);
        return obj;
    };

    // On general account change
    const onAccChange = obj => {
        // prettier-ignore
        [ 'branchID', 'branchName', 'itemCode', 'itemName', 'formedDate','itemLocation', 'isGovCode', 'govCode', 'description'].forEach(key => delete obj[key]);
        return obj;
    };

    // VIEW FORM
    const onValuesChange = (val, mode) => {
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('saname')) {
            // Remove other form values
            if (mode === 'view') {
                const valuesTemp = onSAChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onSAChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            // Get main accounts
            getMainAccounts(val.saname);
        } else if (val.hasOwnProperty('mano')) {
            // Remove other form values
            if (mode === 'view') {
                const valuesTemp = onMAChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onMAChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            // Sync main account name
            const mainAcc = mainAccounts.find(acc => acc.mano === val.mano);
            updateValues({ maName: mainAcc.maName });
            // Get general accounts
            getGeneralAccounts(mainAcc.mano);
        } else if (val.hasOwnProperty('maName')) {
            // Remove other form values
            if (mode === 'view') {
                const valuesTemp = onMAChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onMAChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            // Sync main account no
            const mainAcc = mainAccounts.find(acc => acc.maName === val.maName);
            updateValues({ mano: mainAcc.mano });
            // Get general accounts
            getGeneralAccounts(mainAcc.mano);
        } else if (val.hasOwnProperty('acno')) {
            const generalAcc = generalAccounts.find(
                acc => acc.acno === val.acno
            );
            if (mode === 'view') {
                const valuesTemp = onAccChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onAccChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            updateValues({ acName: generalAcc.acName });
        } else if (val.hasOwnProperty('acName')) {
            const generalAcc = generalAccounts.find(
                acc => acc.acName === val.acName
            );
            if (mode === 'view') {
                const valuesTemp = onAccChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onAccChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            updateValues({ acno: generalAcc.acno });
        } else if (val.hasOwnProperty('branchID')) {
            const branch = branches.find(
                branch => branch.branchID === val.branchID
            );
            updateValues({ branchName: branch.branchName });
            if (mode === 'view') getGeneralItems(branch.branchID);
        } else if (val.hasOwnProperty('branchName')) {
            const branch = branches.find(
                branch => branch.branchName === val.branchName
            );
            updateValues({ branchID: branch.branchID });
            if (mode === 'view') getGeneralItems(branch.branchID);
        } else if (val.hasOwnProperty('itemCode')) {
            if (mode === 'view') {
                const item = generalItems.find(
                    item => item.itemCode === val.itemCode
                );
                getGeneralItemDetail(item.itemCode);
                updateValues({ itemName: item.itemName });
            }
            return updateValues({ itemCode: val.itemCode.toUpperCase() });
        } else if (val.hasOwnProperty('itemName')) {
            if (mode === 'view') {
                const item = generalItems.find(
                    item => item.itemName === val.itemName
                );
                getGeneralItemDetail(item.itemCode);
                updateValues({
                    itemCode: item.itemCode,
                });
            }
            return updateValues({ itemName: val.itemName.toUpperCase() });
        } else if (val.hasOwnProperty('formedDate')) {
            return updateValues({ formedDate: NEP_TO_ENG_ISO(val.formedDate) });
        }
        updateValues(val);
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            const payload = {
                ...editValues,
                user: userCredentials.userName,
            };
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/ItemMaster/Update/${editValues?.acno}/${editValues?.itemCode}`,
                    payload
                )
                .then(res => {
                    console.log(res);
                    if (res.data.statusCode !== 0)
                        if (res.data.statusCode === 2)
                            return setAsyncUpdateModal(prevValues => ({
                                ...prevValues,
                                visible: true,
                            }));
                        else return message.error(res.data.statusMessage);

                    setViewValues(res.data.data);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(
                        err,
                        'Failed to update item setup general account'
                    );
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ASYNC UPDATE MODAL
    // On async update modal ok
    const onAsyncUpdateModalOk = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        const payload = {
            ...editValues,
            user: userCredentials.userName,
        };
        console.log('payload: ', payload);
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/ItemMaster/UpdateAll/${editValues?.acno}/${editValues?.itemCode}`,
                payload
            )
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setViewValues(res.data.data);
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Updated!');
            })
            .catch(err => {
                console.error(
                    err,
                    'Failed to update item setup general account asynchronously'
                );
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // ADD ACCOUNT MODAL
    // Get new item code
    const getNewItemCode = acno => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/ItemMaster/GetNewID/${acno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setAddValues(prevValues => ({
                    ...prevValues,
                    itemCode: res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get item code');
                message.error('Failed to get item code. Please try again!');
            });
    };
    // On add modal open
    const onAddModalOpen = () => {
        setAddValues({
            saname: viewValues?.saname,
            maName: viewValues?.maName,
            mano: viewValues?.mano,
            acName: viewValues?.acName,
            acno: viewValues?.acno,
        });
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/ItemMaster/Add/${addValues?.itemCode}`,
                    addValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewValues(addValues);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setAddValues(null);
                    message.success('New item created!');
                })
                .catch(err => {
                    console.error(err, 'Failed to create general item');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On async update modal cancel
    const onAsyncUpdateModalCancel = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/ItemMaster/Delete/${viewValues?.acno}/${viewValues?.itemCode}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Item deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete general item');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        const key = e.target.name;
        const checked = e.target.checked;

        if (mode === 'edit') {
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        } else if (mode === 'add') {
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        }
    };
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            formedDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Get new item code
    React.useEffect(() => {
        if (addValues?.acno) {
            getNewItemCode(addValues.acno);
        }
    }, [addValues?.acno]);

    // On component mount
    React.useEffect(() => {
        // Get super accounts
        getSuperAccounts();
    }, []);
    return (
        <div className="general-items">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">General Items</h3>
                <GeneralItemsForm
                    ref={formRef}
                    mode="view"
                    superAccounts={superAccounts}
                    mainAccounts={mainAccounts}
                    generalAccounts={generalAccounts}
                    branches={branches}
                    generalItems={generalItems}
                    values={viewValues}
                    onValuesChange={val => onValuesChange(val, 'view')}
                    setDefaultValues={setDefaultValues}
                />
                {/* Edit account modal */}
                <Modal
                    title="Edit general item"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <GeneralItemsForm
                        ref={formRef}
                        mode="edit"
                        superAccounts={superAccounts}
                        mainAccounts={mainAccounts}
                        generalAccounts={generalAccounts}
                        branches={branches}
                        generalItems={generalItems}
                        values={editValues}
                        onValuesChange={val => onValuesChange(val, 'edit')}
                        onCheckboxChange={onCheckboxChange}
                        setDefaultValues={setDefaultValues}
                    />
                </Modal>
                {/* Add account modal */}
                <Modal
                    title="Add general item"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    onOk={onAddModalOk}
                    onCancel={onAddModalCancel}
                    okText="Create"
                    cancelButtonProps={{
                        disabled: addModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <GeneralItemsForm
                        ref={formRef}
                        mode="add"
                        superAccounts={superAccounts}
                        mainAccounts={mainAccounts}
                        generalAccounts={generalAccounts}
                        branches={branches}
                        generalItems={generalItems}
                        values={addValues}
                        onValuesChange={val => onValuesChange(val, 'add')}
                        onCheckboxChange={onCheckboxChange}
                        setDefaultValues={setDefaultValues}
                    />
                </Modal>
                {/* Async edit modal */}
                <Modal
                    title="Update account?"
                    visible={asyncUpdateModal.visible}
                    confirmLoading={asyncUpdateModal.confirmLoading}
                    onOk={onAsyncUpdateModalOk}
                    onCancel={onAsyncUpdateModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: asyncUpdateModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                >
                    <p>
                        The update may take a while. Please be patient during
                        the operation.
                    </p>
                </Modal>
                {/* Delete account modal */}
                <Modal
                    title="Delete account?"
                    visible={deleteModal.visible}
                    confirmLoading={deleteModal.confirmLoading}
                    onOk={onDeleteModalOk}
                    onCancel={onDeleteModalCancel}
                    okText="Delete"
                    cancelButtonProps={{
                        disabled: deleteModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                >
                    <p>The item and its data will be deleted forever</p>
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.itemCode}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                    <Button
                        type="primary"
                        onClick={onDeleteModalOpen}
                        disabled={!viewValues?.itemCode}
                    >
                        Delete
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
