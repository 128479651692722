import React from 'react';
import { Form, Input, Button } from 'antd';

export default function ChangePasswordForm(props) {
    // Destructuring
    const { handlePasswordChange, changePwdModal, isChangingPassword } = props;
    return (
        <Form layout="vertical" onFinish={handlePasswordChange}>
            <Form.Item label="Username">
                <div className="ant-input ant-input--fake">
                    {changePwdModal?.values?.userName}
                </div>
            </Form.Item>
            <Form.Item
                label="New password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                    {
                        min: 6,
                        message: 'At least 6 characters',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="Confirm new password"
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                    {
                        min: 6,
                        message: 'At least 6 characters',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error('Passwords do not match!')
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    style={{ marginTop: '8px' }}
                    loading={isChangingPassword}
                >
                    Change password
                </Button>
            </Form.Item>
        </Form>
    );
}
