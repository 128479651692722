import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Button,
    Table,
    Dropdown,
    Menu,
    Popconfirm,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Branches from '../../../Common/Geography/Branches/Branches';

export default function CounterAssignmentForm(props) {
    // Local states
    const [assignableCounters, setAssignableCounters] = React.useState([]);
    // Destructuring
    const {
        users,
        values,
        onValuesChange,
        mode,
        assignedCounters,
        countersPayload,
        removeCounter,
        addCounter,
        branchCounters,
        setDefaultValues,
    } = props;

    const counters = mode === 'view' ? assignedCounters : countersPayload;

    const dataSource = counters?.map((counter, i) => {
        return {
            key: i,
            ...counter,
            ...(mode !== 'view' && { removeCounter: 'Remove' }),
        };
    });

    const columns = [
        {
            title: 'Counter No.',
            dataIndex: 'counterNO',
            key: 'counterNO',
        },
        {
            title: 'Counter Name',
            dataIndex: 'counterName',
            key: 'counterName',
        },
        {
            title: 'Branch ID',
            dataIndex: 'branchID',
            key: 'branchID',
        },
        {
            title: 'Branch Name',
            dataIndex: 'branchName',
            key: 'branchName',
        },
        {
            title: '',
            dataIndex: 'removeCounter',
            key: 'removeCounter',
            render: (text, data) => (
                <Popconfirm
                    title="Remove counter for this user?"
                    onConfirm={() => removeCounter(data.counterNO)}
                    okText="Remove"
                    cancelText="No"
                >
                    <p style={{ color: 'red', cursor: 'pointer' }}>{text}</p>
                </Popconfirm>
            ),
        },
    ];

    // Assignable counter options
    const assignableCounterOptions = (
        <Menu onClick={e => addCounter(e.key)}>
            {assignableCounters.map(counter => (
                <Menu.Item
                    key={counter.counterNO}
                >{`${counter.counterNO} - ${counter.counterName}`}</Menu.Item>
            ))}
        </Menu>
    );

    // Set assignable counters
    React.useEffect(() => {
        console.log(countersPayload);
        const assignedCounterNos =
            countersPayload?.length &&
            countersPayload.map(counter => counter.counterNO);

        const counters = assignedCounterNos?.length
            ? branchCounters.filter(
                  counter => !assignedCounterNos.includes(counter.counterNO)
              )
            : branchCounters;

        setAssignableCounters(counters);
    }, [countersPayload, branchCounters]);

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [mode, setDefaultValues]);

    return (
        <div>
            <Form
                layout="vertical"
                className="margin-top"
                onValuesChange={val => onValuesChange(val, mode)}
                fields={[
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'userName',
                        value: values?.userName,
                    },
                    {
                        name: 'staffID',
                        value: values?.staffID,
                    },
                    {
                        name: 'staffName',
                        value: values?.staffName,
                    },
                    {
                        name: 'counterBranchID',
                        value: values?.counterBranchID,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={6}>
                            <Branches
                                item="branchName"
                                disabled={mode !== 'view'}
                            />
                        </Col>
                        <Col span={4}>
                            <Branches
                                item="branchID"
                                disabled={mode !== 'view'}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="User Name" name="userName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={mode !== 'view'}
                                    value={null}
                                >
                                    {users?.length &&
                                        users.map((user, i) => (
                                            <Select.Option
                                                value={user.userName}
                                            >
                                                {user.userName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Staff Name" name="staffName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Staff ID" name="staffID">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    {mode !== 'view' && (
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item label="Counters">
                                    <Dropdown
                                        overlay={assignableCounterOptions}
                                        trigger={['click']}
                                        disabled={!assignableCounters.length}
                                    >
                                        <Button>
                                            {assignableCounters.length ? (
                                                <>
                                                    Assign counter{' '}
                                                    <DownOutlined />
                                                </>
                                            ) : (
                                                'All counters assigned'
                                            )}
                                        </Button>
                                    </Dropdown>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Branches
                                    name="counterBranchID"
                                    item="branchID"
                                />
                            </Col>
                        </Row>
                    )}
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        size="small"
                    />
                </div>
            </Form>
        </div>
    );
}
