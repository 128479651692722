import React from 'react';
// Import from dependencies
import { Switch, Route, Redirect } from 'react-router-dom';
// Import components
import Dashboard from '../containers/Dashboard/Dashboard';
import ReceiptEntry from '../containers/Accounts/ReceiptEntry/ReceiptEntry';
import DailyCollection from '../containers/DailyCollection/DailyCollection';
import GeneralAccount from '../containers/Account/GeneralAccount/GeneralAccount';
import AssetsTypeAccount from '../containers/Account/AssetsTypeAccount/AssetsTypeAccount';
import DepositTypes from '../containers/Account/DepositTypes/DepositTypes';
import LoanTypes from '../containers/Account/LoanTypes/LoanTypes';
import GroupType from '../containers/GroupType/GroupType';
import Area from '../containers/Area/Area';
import MemberGroup from '../containers/MemberGroup/MemberGroup';
import CollectionGroup from '../containers/CollectionGroup/CollectionGroup';
import GeneralItems from '../containers/ItemSetup/GeneralItems/GeneralItems';
import BankRegistration from '../containers/ItemSetup/BankRegistration/BankRegistration';
import AssetsRegistration from '../containers/ItemSetup/AssetsRegistration/AssetsRegistration';
import StaffTypes from '../containers/StaffTypes/StaffTypes';
import StaffRegistration from '../containers/AdministrativePanel/StaffRegistration/StaffRegistration';
import StaffPosts from '../containers/StaffPosts/StaffPosts';
import MemberRegistration from '../containers/Membership/MemberRegistration/MemberRegistration';
import OfficeDetails from '../containers/AdministrativePanel/OfficalDetail/OfficeDetails';
import OwnerDetails from '../containers/OwnerDetails/OwnerDetails';
import UserRegister from '../containers/UserRegister/UserRegister';
import DepositRegistration from '../containers/DepositRegistration/DepositRegistration';
import LoanRegistration from '../containers/LoanRegistration/LoanRegistration';
import ChangePassword from '../containers/AdministrativePanel/ChangePassword/ChangePassword';
import CounterWithdraw from '../containers/Counter/CounterWithdraw/CounterWithdraw';
import CounterRegistration from '../containers/Counter/CounterRegistration/CounterRegistration';
import ShareAddition from '../containers/ShareAddition/ShareAddition';
import CounterAssignment from '../containers/Counter/CounterAssignment/CounterAssignment';
import CounterDeposit from '../containers/Counter/CounterDeposit/CounterDeposit';
import FixedDeposit from '../containers/Counter/FixedDeposit/FixedDeposit';
import CashVault from '../containers/Closing/DayOpen/CashVault/CashVault';
import CounterCashDistribution from '../containers/Closing/DayClose/CounterCashDistribution/CounterCashDistribution';
import RemitPayment from '../containers/Remittance/RemitPayment/RemitPayment';
import RemitSending from '../containers/Remittance/RemitSending/RemitSending';
import RemitDuesReceived from '../containers/Remittance/RemitDuesReceived/RemitDuesReceived';
import RemitCompanyDeposit from '../containers/Remittance/RemitCompanyDeposit/RemitCompanyDeposit';
import CreateRemit from '../containers/Remittance/CreateRemit/CreateRemit';
import DepositToBank from '../containers/Accounts/Banking/DepositToBank/DepositToBank';
import WithdrawFromBank from '../containers/Accounts/Banking/WithdrawFromBank/WithdrawFromBank';
import OverdraftWithdraw from '../containers/Savings/Withdraw/OverdraftWithdraw/OverdraftWithdraw';
import LoanGranting from '../containers/Credits/LoanGranting/LoanGranting';
import CollectionEntry from '../containers/Savings/CollectionEntry/CollectionEntry';
import PayForPayable from '../containers/Accounts/PayForPayable/PayForPayable';
import HouseRent from '../containers/Accounts/HouseRent/HouseRent';
import PayInAdvance from '../containers/Accounts/GeneralAdvance/PayInAdvance/PayInAdvance';
import AdvanceReturn from '../containers/Accounts/GeneralAdvance/AdvanceReturn/AdvanceReturn';
import GeneralIncome from '../containers/Accounts/GeneralIncome/GeneralIncome';
import GeneralExpenditure from '../containers/Accounts/GeneralExpenditure/GeneralExpenditure';
import Investment from '../containers/Accounts/Investment/Investment';
import CalculateInterest from '../containers/Savings/CalculateInterest/CalculateInterest';
import ClosingAccount from '../containers/Savings/ClosingAccount/ClosingAccount';
import BranchAssignment from '../containers/AdministrativePanel/BranchAssignment/BranchAssignment';
import TrialBalance from '../containers/Reports/TrialBalance/TrialBalance';

export default function ProtectedRoutes() {
    return (
        <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/receipt-entry" component={ReceiptEntry} />
            <Route exact path="/daily-collection" component={DailyCollection} />
            <Route exact path="/general-account" component={GeneralAccount} />
            <Route
                exact
                path="/assets-type-account"
                component={AssetsTypeAccount}
            />
            <Route
                exact
                path="/deposit-types-account"
                component={DepositTypes}
            />
            <Route exact path="/loan-types-account" component={LoanTypes} />
            <Route exact path="/group-type" component={GroupType} />
            <Route exact path="/area" component={Area} />
            <Route exact path="/member-group" component={MemberGroup} />
            <Route exact path="/collection-group" component={CollectionGroup} />
            <Route exact path="/general-items" component={GeneralItems} />
            <Route
                exact
                path="/bank-registration"
                component={BankRegistration}
            />
            <Route
                exact
                path="/assets-registration"
                component={AssetsRegistration}
            />
            <Route
                exact
                path="/assets-registration"
                component={AssetsRegistration}
            />
            <Route exact path="/staff/types" component={StaffTypes} />
            <Route exact path="/staff/posts" component={StaffPosts} />
            <Route
                exact
                path="/staff-registration"
                component={StaffRegistration}
            />
            <Route
                exact
                path="/member-registration"
                component={MemberRegistration}
            />
            <Route exact path="/office-details" component={OfficeDetails} />
            <Route exact path="/owner-details" component={OwnerDetails} />
            <Route exact path="/user-register" component={UserRegister} />
            <Route
                exact
                path="/deposit-registration"
                component={DepositRegistration}
            />
            <Route
                exact
                path="/loan-registration"
                component={LoanRegistration}
            />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/counter-withdraw" component={CounterWithdraw} />
            <Route
                exact
                path="/counter-registration"
                component={CounterRegistration}
            />
            <Route
                exact
                path="/counter-assignment"
                component={CounterAssignment}
            />
            <Route exact path="/share-addition" component={ShareAddition} />
            <Route exact path="/counter-deposit" component={CounterDeposit} />
            <Route exact path="/fixed-deposit" component={FixedDeposit} />
            <Route exact path="/cash-vault" component={CashVault} />
            <Route
                exact
                path="/counter-cash-distribution"
                component={CounterCashDistribution}
            />
            <Route exact path="/remit-payment" component={RemitPayment} />
            <Route exact path="/remit-sending" component={RemitSending} />
            <Route
                exact
                path="/remit-dues-received"
                component={RemitDuesReceived}
            />
            <Route
                exact
                path="/remit-company-deposit"
                component={RemitCompanyDeposit}
            />
            <Route exact path="/create-remit" component={CreateRemit} />
            <Route exact path="/deposit-to-bank" component={DepositToBank} />
            <Route
                exact
                path="/withdraw-from-bank"
                component={WithdrawFromBank}
            />
            <Route
                exact
                path="/overdraft-withdraw"
                component={OverdraftWithdraw}
            />
            <Route exact path="/loan-granting" component={LoanGranting} />
            <Route exact path="/collection-entry" component={CollectionEntry} />
            <Route exact path="/pay-for-payable" component={PayForPayable} />
            <Route exact path="/house-rent" component={HouseRent} />
            <Route exact path="/pay-in-advance" component={PayInAdvance} />
            <Route exact path="/advance-return" component={AdvanceReturn} />
            <Route exact path="/general-income" component={GeneralIncome} />
            <Route
                exact
                path="/general-expenditure"
                component={GeneralExpenditure}
            />
            <Route exact path="/investment" component={Investment} />
            <Route
                exact
                path="/calculate-interest"
                component={CalculateInterest}
            />
            <Route exact path="/closing-account" component={ClosingAccount} />
            <Route
                exact
                path="/branch-assignment"
                component={BranchAssignment}
            />
            <Route exact path="/trial-balance" component={TrialBalance} />
            <Redirect to="/dashboard" />
        </Switch>
    );
}
