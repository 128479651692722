import React from 'react';
import { Row, Col, Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PlaceholderImage from '../../../assets/images/placeholder-image.png';
import PlaceholderSignature from '../../../assets/images/signature.jpg';

export default function DocumentsForm() {
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Form.Item label="Citizenship Image">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img src={PlaceholderImage} alt="Citizenship" />
                                {/* Replace placeholder with real picture
                                <img
                                    src={UploadedProfilePic}
                                    alt="profile picture"
                                /> */}
                            </div>
                            <div className="avatar--upload-button">
                                <Upload>
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Form.Item label="Signature">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img
                                    src={PlaceholderSignature}
                                    alt="Signature"
                                />
                                {/* Replace placeholder with real picture
                                <img
                                    src={UploadedProfilePic}
                                    alt="profile picture"
                                /> */}
                            </div>
                            <div className="avatar--upload-button">
                                <Upload>
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Form.Item label="Image">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img src={PlaceholderImage} alt="Citizenship" />
                                {/* Replace placeholder with real picture
                                <img
                                    src={UploadedProfilePic}
                                    alt="profile picture"
                                /> */}
                            </div>
                            <div className="avatar--upload-button">
                                <Upload>
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Form.Item label="Image">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img src={PlaceholderImage} alt="Citizenship" />
                                {/* Replace placeholder with real picture
                                <img
                                    src={UploadedProfilePic}
                                    alt="profile picture"
                                /> */}
                            </div>
                            <div className="avatar--upload-button">
                                <Upload>
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
