import React from 'react';
// Import from dependencies
import { Form, Select, Input, Row, Col } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
    ENG_ISO_TO_NEP,
    CONTAINS_DIGITS_OR_DECIMAL_ONLY,
} from '../../../utils/functions';
import moment from 'moment';

const AssetsTypeAccountForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mainAccounts,
        onValuesChange,
        mainAccountType,
        accounts,
        formValues,
        setAddFormValues,
        formMode,
    } = props;

    // Variables and constants

    let maNameInput,
        acnoInput,
        acNameInput,
        nepNameInput,
        depreciationRateInput,
        descriptionInput,
        formedDateInput,
        govCodeInput;
    switch (formMode) {
        case 'view':
            maNameInput = (
                <Select
                    placeholder="Select..."
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {mainAccounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.maName}>
                            {acc.maName}
                        </Select.Option>
                    ))}
                </Select>
            );
            acnoInput = (
                <Select
                    placeholder="Select..."
                    disabled={!accounts?.length}
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {accounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.acno}>
                            {acc.acno}
                        </Select.Option>
                    ))}
                </Select>
            );
            acNameInput = (
                <Select
                    placeholder="Select..."
                    disabled={!accounts?.length}
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {accounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.acName}>
                            {acc.acName}
                        </Select.Option>
                    ))}
                </Select>
            );
            nepNameInput = <Input />;
            depreciationRateInput = <Input addonAfter="%" />;
            descriptionInput = <Input />;
            formedDateInput = (
                <Form.Item
                    label="Formed date"
                    name="formedDate"
                    rules={
                        formMode !== 'view' && [
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]
                    }
                >
                    <NepaliDatePicker inputClassName="ant-input" />
                </Form.Item>
            );
            govCodeInput = <Input />;
            break;
        case 'edit':
            maNameInput = <Input />;
            acnoInput = <Input disabled />;
            acNameInput = (
                <Input value={formValues?.acName} placeholder="Required" />
            );
            nepNameInput = <Input value={formValues?.nepName} />;
            depreciationRateInput = (
                <Input value={formValues?.depreciationRate} addonAfter="%" />
            );
            descriptionInput = <Input value={formValues?.description} />;
            formedDateInput = (
                <>
                    <Form.Item
                        label="Formed date"
                        name="formedDate"
                        rules={
                            formMode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <NepaliDatePicker inputClassName="ant-input" />
                    </Form.Item>
                </>
            );
            govCodeInput = <Input value={formValues?.govCode} />;
            break;
        case 'add':
            maNameInput = (
                <Select
                    placeholder="Required"
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {mainAccounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.maName}>
                            {acc.maName}
                        </Select.Option>
                    ))}
                </Select>
            );
            acnoInput = <Input disabled />;
            acNameInput = (
                <Input value={formValues?.acName} placeholder="Required" />
            );
            nepNameInput = <Input value={formValues?.nepName} />;
            depreciationRateInput = (
                <Input value={formValues?.depreciationRate} addonAfter="%" />
            );
            descriptionInput = <Input value={formValues?.description} />;
            formedDateInput = (
                <>
                    <Form.Item
                        label="Formed date"
                        name="formedDate"
                        rules={
                            formMode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <NepaliDatePicker inputClassName="ant-input" />
                    </Form.Item>
                </>
            );
            govCodeInput = <Input value={formValues?.govCode} />;
            break;
        default:
            maNameInput =
                acnoInput =
                acNameInput =
                nepNameInput =
                depreciationRateInput =
                descriptionInput =
                formedDateInput =
                govCodeInput =
                    null;
    }

    // On component mount
    React.useEffect(() => {
        if (formMode === 'add')
            setAddFormValues(prevValues => ({
                ...prevValues,
                formedDate: moment().format(),
            }));
    }, [setAddFormValues, formMode]);

    return (
        <div className="assets-type-account-form-view">
            <div className="block-general-titled--body">
                <Form
                    ref={ref}
                    className="margin-top"
                    name="assets-type-account-form"
                    layout="vertical"
                    fields={[
                        {
                            name: ['maName'],
                            value: formValues?.maName,
                        },
                        {
                            name: ['acno'],
                            value: formValues?.acno,
                        },
                        {
                            name: ['acName'],
                            value: formValues?.acName,
                        },
                        {
                            name: ['nepName'],
                            value: formValues?.nepName,
                        },
                        {
                            name: ['formedDate'],
                            value:
                                formValues?.formedDate &&
                                ENG_ISO_TO_NEP(formValues?.formedDate),
                        },
                        {
                            name: ['depreciationRate'],
                            value: formValues?.depreciationRate,
                        },
                        {
                            name: ['description'],
                            value: formValues?.description,
                        },
                        {
                            name: ['govCode'],
                            value: formValues?.govCode,
                        },
                    ]}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Main account name"
                                name="maName"
                                rules={
                                    formMode === 'add' && [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ]
                                }
                            >
                                {maNameInput}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            {formedDateInput}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Account no"
                                name="acno"
                                required={formMode === 'add'}
                            >
                                {acnoInput}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Government code" name="govCode">
                                {govCodeInput}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Account name"
                                name="acName"
                                rules={
                                    formMode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ]
                                }
                            >
                                {acNameInput}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Nepali name" name="nepName">
                                {nepNameInput}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Description" name="description">
                                {descriptionInput}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            {mainAccountType === 'FIXED ASSETS' && (
                                <Form.Item
                                    label="Depreciation rate"
                                    name="depreciationRate"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    CONTAINS_DIGITS_OR_DECIMAL_ONLY(
                                                        value
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error('Digits only')
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    {depreciationRateInput}
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default AssetsTypeAccountForm;
