import React from 'react';
import { Form, Select, Input, Row, Col, DatePicker } from 'antd';
import {
    GET_CASTE_GROUPS,
    GET_RELIGIONS,
    GET_MARITAL_STATUSES,
    GET_MEMBERS,
    GET_RELATION_TYPES,
} from '../../../utils/axios';

export default function FamilyDetailForm(props) {
    // Destructing
    const {
        mode,
        members,
        setMembers,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        relations,
        setRelations,
    } = props;

    // On component mount
    React.useEffect(() => {
        if (mode !== 'view')
            (async () => {
                const casteGroups = await GET_CASTE_GROUPS();
                setCasteGroups(casteGroups);
                const religions = await GET_RELIGIONS();
                setReligions(religions);
                const maritalStatuses = await GET_MARITAL_STATUSES();
                setMaritalStatuses(maritalStatuses);
                const members = await GET_MEMBERS();
                setMembers(members);
                const relations = await GET_RELATION_TYPES();
                setRelations(relations);
            })();
    }, [
        setCasteGroups,
        setReligions,
        setMaritalStatuses,
        setMembers,
        setRelations,
        mode,
    ]);
    return (
        <div className="family-detail-form">
            <h3 className="colored-title" style={{ marginTop: '0' }}>
                Guardian Detail
            </h3>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Member No"
                        name="memberNO"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members.map((member, i) => (
                                <Select.Option key={i} value={member.memberNO}>
                                    {member.memberNO}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Member Name"
                        name="memberName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input placeholder="Enter..." disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Relation" name="nomRel">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {relations.map((relation, i) => (
                                <Select.Option key={i} value={relation}>
                                    {relation}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Address" name="memAddress">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Mobile" name="memContact">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <h3 className="colored-title">Family Detail</h3>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Father's Name"
                        name="headName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Mother's Name"
                        name="motherName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Grand Father's Name"
                        name="grandHeadName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Husband/Wife Name" name="spouse">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="बुवाको नाम" name="unFather">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="आमाको नाम" name="unMotherName">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="हजुरबुवाको नाम" name="unGrandFather">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="पति/पत्नीको नाम" name="unSpouse">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Caste Group"
                        name="casteGroup"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {casteGroups.map((group, i) => (
                                <Select.Option key={i} value={group.casteGroup}>
                                    {group.casteGroup}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Caste"
                        name="caste"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {castes.map((caste, i) => (
                                <Select.Option key={i} value={caste.casteName}>
                                    {caste.casteName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Religion" name="religion">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {religions.map((religion, i) => (
                                <Select.Option key={i} value={religion}>
                                    {religion}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Marital Status"
                        name="maritalStatus"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {maritalStatuses.map((status, i) => (
                                <Select.Option
                                    key={i}
                                    value={status.maritalStatus}
                                >
                                    {status.maritalStatus}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
