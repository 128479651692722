import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Space,
    Button,
    Table,
    Radio,
    AutoComplete,
    message,
} from 'antd';
import validator from 'validator';
import moment from 'moment';
import axios from '../../../../utils/axios';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

const CollectionEntryForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        collectors,
        onFormSubmit,
        isProcessing,
        handleNew,
        tableData,
        setTableData,
        accounts,
        results,
        setResults,
    } = props;
    // Global states
    // Local states
    const [isLoadingTableData, setIsLoadingTableData] = React.useState(false);

    // Refs

    // Handle search
    const handleSearch = async val => {
        setResults([]);
        if (!val) return;

        try {
            const res = await axios.get(
                `${
                    process.env.REACT_APP_HOST
                }/api/CollectionEntry/AccountSearch/${val}?ACNO=${
                    values?.acno || ''
                }&BranchID=${
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                }&staffID=${values?.staffID || ''}`
            );
            // console.log('res: ', res);
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            // const results = res.data.data.map(
            //     item =>
            //         `${item.type}-${item.acno}-${item.accountNo}-${item.accountHolder}`
            // );
            const results = res.data.data;
            console.log('results: ', results);
            setResults(results);
        } catch (err) {
            console.error(err, 'Failed to get results');
        }
    };

    const handleSelect = async data => {
        setIsLoadingTableData(true);

        const arr = data.split('-');
        const type = arr[0];
        const accountNo = arr[2];
        const date = moment(values?.transDate).format('YYYY-MM-DD');
        const branchID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).branchID;
        const acno = arr[1];

        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/CollectionAccount/${type}/${accountNo}/${date}?&BranchID=${branchID}&acno=${acno}`
        );

        const resData = res.data.data[0];

        setTableData(prevValues => [
            ...prevValues,
            {
                key: tableData.length + '',
                ...resData,
                [`collectedAmt${tableData.length}`]: 0,
                [`remark${tableData.length}`]: resData.remark,
            },
        ]);
        setIsLoadingTableData(false);
        document.getElementById(`collectedAmt${tableData.length}`).focus();
    };

    // Fill All
    const handleFillAll = async () => {
        const branchID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).branchID;
        const date = moment(values?.transDate).format('YYYY-MM-DD');
        const acno = values?.acno || '';
        const staffID = values?.staffID || '';

        setIsLoadingTableData(true);
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/FillAll/${branchID}/Savings/${date}?ACNO=${acno}&StaffID=${staffID}`
        );
        setIsLoadingTableData(false);
        let data = res.data.data.map((item, i) => {
            return {
                key: i + '',
                ...item,
                [`collectedAmt${i}`]: 0,
                [`remark${i}`]: item.remark,
            };
        });
        data.length = 10;
        // setTableData(data);
    };

    const columns = [
        {
            title: 'Account No',
            dataIndex: 'accountNo',
            key: 'accountNo',
            width: '120px',
        },
        {
            title: 'Account Holder',
            dataIndex: 'accountHolder',
            key: 'accountHolder',
            // width: '120px',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '150px',
        },
        {
            title: 'Collected Amt',
            dataIndex: 'collectedAmt',
            key: 'collectedAmt',
            width: '120px',
            render: (text, data) => (
                <Form.Item
                    name={`collectedAmt${+data.key}`}
                    wrapperCol={24}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || validator.isInt(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Digits Only'));
                            },
                        }),
                    ]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            ),
        },
        {
            title: 'Previous Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: '100px',
        },
        {
            title: 'Final Balance',
            dataIndex: 'finalBalance',
            key: 'finalBalance',
            width: '100px',
        },
        {
            title: 'Remarks',
            dataIndex: 'remark',
            key: 'remark',
            width: '100px',
            render: (text, data) => (
                <Form.Item name={`remark${+data.key}`} wrapperCol={24}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            ),
        },
        {
            title: 'Previous Remarks',
            dataIndex: 'previousRemark',
            key: 'previousRemark',
            width: '150px',
        },
    ];

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <div>
            <Form
                ref={ref}
                layout="horizontal"
                className="margin-top"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onValuesChange={val => onValuesChange(val, mode)}
                fields={[
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'transDate',
                        value:
                            values?.transDate &&
                            ENG_ISO_TO_NEP(values?.transDate),
                    },
                    {
                        name: 'collectionDate',
                        value:
                            values?.collectionDate &&
                            ENG_ISO_TO_NEP(values?.collectionDate),
                    },
                    {
                        name: 'memberNo',
                        value: values?.memberNo,
                    },
                    {
                        name: 'memberName',
                        value: values?.memberName,
                    },
                    {
                        name: 'address',
                        value: values?.address,
                    },
                    {
                        name: 'mobileNO',
                        value: values?.mobileNO,
                    },
                    {
                        name: 'paidBy',
                        value: values?.paidBy,
                    },
                    {
                        name: 'cashNo',
                        value: values?.cashNo,
                    },
                    {
                        name: 'cashName',
                        value: values?.cashName,
                    },
                    {
                        name: 'bankNo',
                        value: values?.bankNo,
                    },
                    {
                        name: 'bankName',
                        value: values?.bankName,
                    },
                    {
                        name: 'otherAcno',
                        value: values?.otherAcno,
                    },
                    {
                        name: 'otherAcName',
                        value: values?.otherAcName,
                    },
                    {
                        name: 'otherNo',
                        value: values?.otherNo,
                    },
                    {
                        name: 'otherName',
                        value: values?.otherName,
                    },

                    {
                        name: 'preTotal',
                        value: values?.preTotal,
                    },
                    {
                        name: 'isCash',
                        value: values?.isCash,
                    },
                    {
                        name: 'cashAmount',
                        value: values?.cashAmount,
                    },
                    {
                        name: 'isBank',
                        value: values?.isBank,
                    },
                    {
                        name: 'bankAmount',
                        value: values?.bankAmount,
                    },
                    {
                        name: 'isOther',
                        value: values?.isOther,
                    },
                    {
                        name: 'otherAmount',
                        value: values?.otherAmount,
                    },
                    {
                        name: 'totalAmount',
                        value: values?.totalAmount,
                    },
                    {
                        name: 'totalAmountInWords',
                        value: values?.totalAmountInWords,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'receiptNo',
                        value: values?.receiptNo,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'isClearance',
                        value: values?.isClearance,
                    },
                    {
                        name: 'accountNo',
                        value: values?.accountNo,
                    },
                    {
                        name: 'staffID',
                        value: values?.staffID,
                    },
                    {
                        name: 'staffName',
                        value: values?.staffName,
                    },
                    {
                        name: 'collectedBy',
                        value: values?.collectedBy,
                    },
                    {
                        name: 'collectedByID',
                        value: values?.collectedByID,
                    },
                    {
                        name: 'collectionOf',
                        value: values?.collectionOf,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    ...tableData.map((item, i) => {
                        return {
                            name: `collectedAmt${i}`,
                            value: item[`collectedAmt${i}`],
                        };
                    }),
                    ...tableData.map((item, i) => {
                        return {
                            name: `remark${i}`,
                            value: item[`remark${i}`],
                        };
                    }),
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Collection Of"
                                name="collectionOf"
                                labelCol={8}
                                wrapperCol={24}
                            >
                                <Radio.Group>
                                    <Radio value="deposit">Deposit</Radio>
                                    <Radio value="loan">
                                        Loan Payment Fund
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Date"
                                name="transDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Collected On"
                                name="collectionDate"
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Account Type" name="acName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {accounts?.length &&
                                        accounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Type Code" name="acno">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {accounts?.length &&
                                        accounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="Journal No." name="journalNO">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Field Of" name="staffName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {collectors?.length &&
                                        collectors.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.staffName}
                                            >
                                                {member.staffName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ID" name="staffID">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {collectors?.length &&
                                        collectors.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.staffID}
                                            >
                                                {member.staffID}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="Receipt No." name="receiptNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Collected By" name="collectedBy">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {collectors?.length &&
                                        collectors.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.staffName}
                                            >
                                                {member.staffName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ID" name="collectedByID">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {collectors?.length &&
                                        collectors.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.staffID}
                                            >
                                                {member.staffID}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="Trans No." name="transNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space style={{ margin: '.5rem 0' }}>
                        <Button onClick={handleFillAll}>Fill All Items</Button>
                        <Button onClick={handleNew}>Clear Items</Button>
                        <Button>Load Sheet</Button>
                        <Button>Search Account</Button>
                        <Button>Print Sheet</Button>
                        <Button>Export to Excel</Button>
                    </Space>
                    <Table
                        // size="small"
                        loading={isLoadingTableData}
                        className="ant-table-wrapper--collapsed"
                        dataSource={tableData}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 240 }}
                    />
                    <AutoComplete
                        style={{
                            width: '400px',
                        }}
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                        placeholder="Search ..."
                        defaultActiveFirstOption={true}
                    >
                        {results.map(item => (
                            <AutoComplete.Option
                                key={item.accountNo}
                                value={`${item.type}-${item.acno}-${item.accountNo}-${item.accountHolder}`}
                                style={
                                    tableData.find(
                                        test =>
                                            test.accountNo === item.accountNo
                                    )
                                        ? { background: '#F8F64B' }
                                        : {}
                                }
                            >
                                {item.type}-{item.acno}-{item.accountNo}-
                                {item.accountHolder}
                            </AutoComplete.Option>
                        ))}
                    </AutoComplete>
                    <Row gutter={20}>
                        <Col span={16}></Col>
                        <Col span={6} style={{ marginTop: '.5rem' }}>
                            <Form.Item label="Total Collection" name="preTotal">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <PaymentSource
                        title="Receipt Source"
                        mode="receipt"
                        values={values}
                    />
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        onClick={onFormSubmit}
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
});

export default CollectionEntryForm;
