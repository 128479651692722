import React from 'react';
// Import from dependencies
import { Form, Select, message } from 'antd';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { branches as branchesAtom } from '../../../recoil/atoms';

export default function LoginBranches() {
    // Global states
    const [branches, setBranches] = useRecoilState(branchesAtom);

    // On component mount
    React.useEffect(() => {
        // Fetch branches and store in local state
        axios
            .get(`${process.env.REACT_APP_HOST}/api/branch`)
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                const data = res.data.data;
                setBranches(data);
                localStorage.setItem('BRANCHES', JSON.stringify(data));
            })
            .catch(err => {
                console.error(err, 'Failed to load branches');
                message.error('Something went wrong. Please try again!');
            });
    }, [setBranches]);
    return (
        <div className="login-branches">
            <Form.Item
                label="Branch"
                name="branchID"
                rules={[
                    {
                        required: true,
                        message: 'Please select a branch',
                    },
                ]}
            >
                <Select
                    placeholder={
                        branches.length
                            ? 'Select branch'
                            : 'Loading branches...'
                    }
                    showSearch
                    loading={!branches.length}
                    disabled={!branches.length}
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {branches.map((branch, i) => (
                        <Select.Option key={i} value={branch.branchID}>
                            {branch.branchName}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
}
