import React from 'react';
import { Modal, Button, message } from 'antd';
// Import utils
import {
    axios,
    GET_LOAN_TYPE_ACCOUNTS,
    GET_LOAN_TYPE_ACCOUNT_DETAIL,
    GET_NEW_LOAN_TYPE_ACC_NO,
} from '../../../utils/axios';
import {
    ON_CHECKBOX_CHANGE,
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
} from '../../../utils/functions';
// Import components
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import LoanTypesForm from '../../../components/LoanTypes/LoanTypesForm';
import moment from 'moment';

export default function LoanTypes() {
    // Local states
    const [accounts, setAccounts] = React.useState([]);
    const [kistaPeriods, setKistaPeriods] = React.useState();
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // EDIT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...editValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };
                console.log('payload: ', payload);
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/LoanType/UpdateLoanType/${editValues.acno}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update loan type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {});
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD MODAL
    // On add modal open
    const onAddModalOpen = () => {
        (async () => {
            const acno = await GET_NEW_LOAN_TYPE_ACC_NO();
            setAddValues(prevValues => ({
                ...prevValues,
                acno,
            }));
        })();
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    modifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/LoanType/AddLoanType/${addValues?.acno}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setAccounts([
                            ...accounts,
                            {
                                acno: data.acno,
                                acName: data.acName,
                            },
                        ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New account added!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to add account');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/LoanType/DeleteLoanType/${viewValues.acno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAccounts(
                    [...accounts].filter(acc => acc.acno !== viewValues?.acno)
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Account deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On delete modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            ips: 'DimBal',
            lps: 'Installment',
            kistaPeriod: 'Monthly',
            fineOrInt: 'Fine',
            calcFineOn: 'DP',
            rebateRate: '0',
            formedDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // On values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // let values;
        // if (mode === 'view') values = addValues;
        // else if (mode === 'edit') values = editValues;
        // else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('acno') || val.hasOwnProperty('acName')) {
            const acc = accounts.find(acc => acc[key] === value);
            updateValues(acc);
            if (mode === 'view') {
                (async () => {
                    const accDetail = await GET_LOAN_TYPE_ACCOUNT_DETAIL(
                        acc.acno
                    );
                    console.log('accDetail: ', accDetail);
                    setViewValues(accDetail);
                })();
            } else {
                return updateValues({ acName: value.toUpperCase() });
            }
        } else if (val.hasOwnProperty('formedDate')) {
            const dateISO = NEP_TO_ENG_ISO(value);
            return updateValues({ [key]: dateISO });
        }
        updateValues(val);
    };

    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        console.log(e);
        ON_CHECKBOX_CHANGE(e, mode, setEditValues, setAddValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        kistaPeriods,
        setKistaPeriods,
        accounts,
        onValuesChange,
        onCheckboxChange,
        setDefaultValues,
        viewValues,
    };

    // On component mount
    React.useEffect(() => {
        (async () => {
            const accounts = await GET_LOAN_TYPE_ACCOUNTS();
            setAccounts(accounts);
        })();
    }, []);
    return (
        <div className="deposit-types">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Loan Type Account
                </h3>
                <LoanTypesForm mode="view" values={viewValues} {...formProps} />
                {/* Edit modal */}
                <Modal
                    title="Edit Loan Type"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <LoanTypesForm
                        mode="edit"
                        values={editValues}
                        {...formProps}
                    />
                </Modal>
                {/* Add modal */}
                <Modal
                    title="Add Loan Type"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    onOk={onAddModalOk}
                    onCancel={onAddModalCancel}
                    okText="Create"
                    cancelButtonProps={{
                        disabled: addModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1100}
                >
                    <LoanTypesForm
                        mode="add"
                        values={addValues}
                        {...formProps}
                    />
                </Modal>
                {/* Delete modal */}
                <Modal
                    title="Delete account?"
                    visible={deleteModal.visible}
                    confirmLoading={deleteModal.confirmLoading}
                    onOk={onDeleteModalOk}
                    onCancel={onDeleteModalCancel}
                    okText="Delete"
                    cancelButtonProps={{
                        disabled: deleteModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                >
                    <p>The account and its data will be deleted forever</p>
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.acno}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                    <Button
                        type="primary"
                        onClick={onDeleteModalOpen}
                        disabled={!viewValues?.acno}
                    >
                        Delete
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
