import React from 'react';
// Import from dependencies
import { Switch, Route, Redirect } from 'react-router-dom';
// Import components
import Login from '../Login/Login';
import MemberRegistration from '../MemberRegistration/MemberRegistration';

export default function Auth() {
	return (
		<div className="auth">
			<div className="auth__wrapper">
				<Switch>
					<Route exact path="/auth/login" component={Login} />
					<Route
						exact
						path="/auth/register"
						component={MemberRegistration}
					/>
					<Redirect to="/auth/login" />
				</Switch>
			</div>
		</div>
	);
}
