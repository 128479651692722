import React from 'react';
import { Table, Input } from 'antd';
// Import utils

export default function FlexibleInterestRate(props) {
    // Local states

    // Destructuring
    const { mode, values, onValuesChange } = props;

    // Table headings
    const columns = [
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: 'Balance Greater Than',
            dataIndex: 'balanceGreaterThan',
            key: 'balanceGreaterThan',
            render: (input, item) => {
                return (
                    <>
                        {mode === 'view' ? (
                            <p>{values && values[`level${item.level}`]}</p>
                        ) : (
                            <Input
                                name={`level${item.level}`}
                                value={values && values[`level${item.level}`]}
                                addonBefore="Rs"
                                onChange={onValuesChange}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: 'Interest Rate',
            dataIndex: 'interestRate',
            key: 'interestRate',
            render: (input, item) => (
                <>
                    {mode === 'view' ? (
                        <p>{values && values[`rate${item.level}`]}</p>
                    ) : (
                        <Input
                            name={`rate${item.level}`}
                            value={values && values[`rate${item.level}`]}
                            addonAfter="%"
                            onChange={onValuesChange}
                        />
                    )}
                </>
            ),
        },
    ];

    // Table data
    const data = Array.from(new Array(10)).map((_, i) => {
        return {
            key: i + 1,
            level: i + 1,
            // balanceGreaterThan:
            //     flexRateValues && flexRateValues[`level${i + 1}`],
            // interestRate: flexRateValues && flexRateValues[`rate${i + 1}`],
        };
    });

    return (
        <div>
            <div className="flexible-interest-table">
                <Table columns={columns} dataSource={data} pagination={false} />
            </div>
        </div>
    );
}
