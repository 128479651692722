import React from 'react';

export default function LoginLeft() {
	return (
		<div className="login-left">
			{/* <div className="login-left__header">
				<h2 className="login-left__title">Oxpan</h2>
			</div> */}
			{/* <div className="login-left__body">
				<img src={LoginPlaceholder} alt="logo" />
			</div> */}
			<div className="login-left__footer">
				<p className="login-left__footer-primary">
					OXPAN accounting software
				</p>
				<p className="login-left__footer-secondary">
					An easy way to manage your accounts
				</p>
			</div>
		</div>
	);
}
