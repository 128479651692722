import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
    GET_CASTE_GROUPS,
    GET_RELIGIONS,
    GET_MARITAL_STATUSES,
} from '../../../../utils/axios';
import PhotoUpload from '../../../../components/Common/PhotoUpload/PhotoUpload';

export default function FamilyDetailForm(props) {
    // Destructing
    const {
        mode,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        onFileChange,
        values,
    } = props;

    // On component mount
    React.useEffect(() => {
        (async () => {
            const casteGroups = await GET_CASTE_GROUPS();
            setCasteGroups(casteGroups);
            const religions = await GET_RELIGIONS();
            setReligions(religions);
            const maritalStatuses = await GET_MARITAL_STATUSES();
            setMaritalStatuses(maritalStatuses);
        })();
    }, [setCasteGroups, setReligions, setMaritalStatuses]);
    return (
        <div className="family-detail-form">
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Father's Name"
                        name="headName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Mother's Name"
                        name="motherName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Grand Father's Name"
                        name="grandHeadName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Husband/Wife Name" name="spouse">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="बुवाको नाम" name="unFather">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="आमाको नाम" name="unMotherName">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="हजुरबुवाको नाम" name="unGrandFather">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="पति/पत्नीको नाम" name="unSpouse">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Caste Group"
                        name="casteGroup"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {casteGroups.map((group, i) => (
                                <Select.Option key={i} value={group.casteGroup}>
                                    {group.casteGroup}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Caste"
                        name="caste"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {castes.map((caste, i) => (
                                <Select.Option key={i} value={caste.casteName}>
                                    {caste.casteName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Religion" name="religion">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {religions.map((religion, i) => (
                                <Select.Option key={i} value={religion}>
                                    {religion}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Marital Status"
                        name="maritalStatus"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {maritalStatuses.map((status, i) => (
                                <Select.Option
                                    key={i}
                                    value={status.maritalStatus}
                                >
                                    {status.maritalStatus}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <h3 className="colored-title">Nominee Detail</h3>
            <Row gutter={20}>
                <Col span={18}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Nominee Name" name="nominee">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="हकवालाको नाम" name="unNominee">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Relation" name="nomRel">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Nominee DOB" name="nomDOB">
                                <NepaliDatePicker
                                    className="nepali-date-picker--top"
                                    inputClassName="ant-input"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Address" name="nomAddress">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Contact No" name="nomContact">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Citizen No" name="nomCitizen">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <PhotoUpload
                        mode={mode}
                        onFileChange={onFileChange}
                        inputName="nomDocImg"
                        photoBinary={values?.nomDocImg}
                        photoUrl={values?.nomDOC}
                    />
                </Col>
            </Row>
        </div>
    );
}
