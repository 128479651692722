import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
// Import utils
import { ENG_ISO_TO_NEP } from '../../utils/functions';

const AreaForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        areas,
        staffs,
        values,
        onValuesChange,
        onCheckboxChange,
        mode,
        setDefaultValues,
    } = props;

    // Variables and constants
    const intervalPeriodSelection = (
        <Form.Item name="intervalPeriod">
            <Select
                className="select-after"
                disabled={mode === 'view'}
                style={{ width: '80px' }}
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                <Select.Option value="Days">Days</Select.Option>
                <Select.Option value="Months">Months</Select.Option>
                <Select.Option value="Years">Years</Select.Option>
            </Select>
        </Form.Item>
    );

    React.useEffect(() => {
        setDefaultValues(mode);
    }, [mode, setDefaultValues]);

    return (
        <div className="area-form">
            <div className="block-general-titled--body">
                <Form
                    ref={ref}
                    className="margin-top"
                    layout="vertical"
                    onValuesChange={onValuesChange}
                    fields={[
                        {
                            name: ['areaID'],
                            value: values?.areaID,
                        },
                        {
                            name: ['areaName'],
                            value: values?.areaName,
                        },
                        {
                            name: ['areaAddress'],
                            value: values?.areaAddress,
                        },
                        {
                            name: ['headStaff'],
                            value: values?.headStaff,
                        },
                        {
                            name: ['coordinator'],
                            value: values?.coordinator,
                        },
                        {
                            name: ['collectionInterval'],
                            value: values?.collectionInterval,
                        },
                        {
                            name: ['intervalPeriod'],
                            value: values?.intervalPeriod,
                        },
                        {
                            name: ['staffID'],
                            value: values?.staffID,
                        },
                        {
                            name: ['staffName'],
                            value: values?.staffName,
                        },
                        {
                            name: ['collectionPlace'],
                            value: values?.collectionPlace,
                        },
                        {
                            name: ['branchID'],
                            value:
                                values?.branchID ||
                                JSON.parse(
                                    localStorage.getItem('USER_CREDENTIALS')
                                ).branchID,
                        },
                        {
                            name: ['formedDate'],
                            value:
                                values?.formedDate &&
                                ENG_ISO_TO_NEP(values.formedDate),
                        },
                        {
                            name: ['baseDate'],
                            value:
                                values?.baseDate &&
                                ENG_ISO_TO_NEP(values.baseDate),
                        },
                    ]}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Area No." name="areaID">
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {areas.map((area, i) => (
                                            <Select.Option
                                                key={i}
                                                value={area.areaID}
                                            >
                                                {area.areaID}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input disabled />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Area Name"
                                name="areaName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {areas.map((area, i) => (
                                            <Select.Option
                                                key={i}
                                                value={area.areaID}
                                            >
                                                {area.areaName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Formed Date"
                                name="formedDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item label="Address" name="areaAddress">
                                <Input disabled={mode === 'view'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item
                                label="Co-ordinator Name"
                                name="coordinator"
                            >
                                <Input disabled={mode === 'view'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item
                                label="Collection Place"
                                name="collectionPlace"
                            >
                                <Input disabled={mode === 'view'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Collection Interval"
                                name="collectionInterval"
                            >
                                <Input
                                    addonAfter={intervalPeriodSelection}
                                    disabled={mode === 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                            <Form.Item
                                label="Base Date"
                                name="baseDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item label="Collector Name" name="staffName">
                                <Select
                                    placeholder="Select..."
                                    disabled={mode === 'view'}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffs.map((staff, i) => (
                                        <Select.Option
                                            key={i}
                                            value={staff.staffName}
                                        >
                                            {staff.staffName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Collector ID" name="staffID">
                                <Select
                                    placeholder="Select..."
                                    disabled={mode === 'view'}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffs.map((staff, i) => (
                                        <Select.Option
                                            key={i}
                                            value={staff.staffID}
                                        >
                                            {staff.staffID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={7}>
                            <Form.Item
                                label="Branch"
                                name="branchID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {JSON.parse(
                                        localStorage.getItem('BRANCHES')
                                    ).map(branch => (
                                        <Select.Option value={branch.branchID}>
                                            {branch.branchID} -{' '}
                                            {branch.branchName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Default Area">
                                <Checkbox
                                    checked={values?.isDefault}
                                    name="isDefault"
                                    onChange={e => onCheckboxChange(e, mode)}
                                    disabled={mode === 'view'}
                                >
                                    Default Area
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default AreaForm;
