import React from 'react';
// Import from dependencies
import { Link } from 'react-router-dom';
// Import components
import Logo from '../../Common/Logo/Logo';

export default function MemberRegistrationHeader() {
	return (
		<div className="member-registration-header">
			<div className="member-registration-header__logo">
				<Logo />
			</div>
			<div className="member-registration-header__title-wrapper">
				<h1 className="member-registration-header__title">
					Member registration
				</h1>
				<Link
					to="/auth/login"
					className="member-registration-header__login"
				>
					Or login instead
				</Link>
			</div>
		</div>
	);
}
