import axios from './axios';
import adbs from 'ad-bs-converter';
import PhotoPlaceholder from '../assets/images/placeholder-image.png';
import moment from 'moment';
import { ToWords } from 'to-words';

// Update axios headers
export const UPDATE_AXIOS_AUTH = (
    token = localStorage.getItem('USER_ACCESS')
) =>
    (axios.defaults.headers = {
        Authorization: `Bearer ${token}`,
    });

// JSON to Form data
function buildFormData(formData, data, parentKey) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach(key => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}
export const JSON_TO_FORM_DATA = data => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
};

export const DLT_KEYS_FROM_OBJ = (obj, keys) => {
    keys.forEach(key => delete obj[key]);
    return obj;
};

export const DLT_KEYS_FROM_OBJ_EXCEPT = (obj, keys) => {
    const objKeys = Object.keys(obj);
    objKeys.forEach(key => {
        if (!keys.includes(key)) delete obj[key];
    });
    return obj;
};

// On file change
export const ON_FILE_CHANGE = (e, mode, cbEdit, cbAdd) => {
    const file = e.target.files[0];
    const key = e.target.name;

    if (mode === 'edit') {
        cbEdit(prevValues => ({
            ...prevValues,
            [key]: file,
        }));
    } else if (mode === 'add') {
        cbAdd(prevValues => ({
            ...prevValues,
            [key]: file,
        }));
    }
};

// On checkbox change
export const ON_CHECKBOX_CHANGE = (e, mode, cbEdit, cbAdd) => {
    const key = e.target.name;
    const checked = e.target.checked;
    if (mode === 'edit') {
        cbEdit(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    } else if (mode === 'add') {
        cbAdd(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    }
};

// Set profile pic
export const SET_PROFILE_PIC = (photo, photoLoc, cb) => {
    if (photo) {
        let file = photo;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            cb(e.target.result);
        };
        return;
    }

    // Display available photo
    if (photoLoc) {
        return cb(`${process.env.REACT_APP_STATIC_FILES}/${photoLoc}`);
    }

    // Display placeholder photo
    if (!photoLoc) {
        return cb(PhotoPlaceholder);
    }
};

export const COMPARE_ARRAYS = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item));
};

// Check if a key of an object contains in both arrays
export const COMPARE_OBJECT_IN_ARRAYS = (arr1, arr2, key) => {
    const arr1Found = arr1.find(item => item[key]);
    const arr2Found = arr2.find(item => item[key]);
    if (!arr1Found || !arr2Found) return false;
    return arr1Found[key] === arr2Found[key];
};

export const SET_DEFAULT_VALUES = (
    defaultValues,
    mode,
    cbView,
    cbEdit,
    cbAdd
) => {
    if (mode === 'view')
        cbView(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    else if (mode === 'edit')
        cbEdit(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    else if (mode === 'add')
        cbAdd(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
};

export const NEP_TO_ENG_ISO = dateString => {
    const dateNep = dateString.replace(/-/g, '/');
    const dateEng = adbs.bs2ad(dateNep);

    const yearEng = dateEng.year;
    const monthEng = dateEng.month;
    const dayEng = dateEng.day;

    const dateISO = moment(`${yearEng}-${monthEng}-${dayEng}`).format();
    return dateISO;
};

export const ENG_ISO_TO_NEP = dateISO => {
    const year = +moment(dateISO).format('YYYY');
    const month = +moment(dateISO).format('M');
    const day = +moment(dateISO).format('D');

    const dateNep = adbs.ad2bs(`${year}/${month}/${day}`);
    const yearNep = dateNep.ne.year;
    let monthNep = dateNep.ne.month;
    monthNep = ('0' + monthNep).slice(-2);
    let dayNep = dateNep.ne.day;
    dayNep = ('0' + dayNep).slice(-2);

    const dateNepaliFull = `${yearNep}-${monthNep}-${dayNep}`;
    return dateNepaliFull;
};

export const CONTAINS_DIGITS_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (string.match(/^[0-9]+$/) != null) return true;
    else return false;
};

export const CONTAINS_DIGITS_OR_DECIMAL_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (/^\d+\.\d+$|^\d+$/.test(string)) return true;
    else return false;
};

export const CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (/^[0-9,.]+$/.test(string)) return true;
    else return false;
};

// PAYMENT SOURCE
// Set cash amount
export const SET_CASH_AMOUNT = (
    values,
    isCash,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const bankAmount = +values?.bankAmount || 0;
    const otherAmount = +values?.otherAmount || 0;
    if (isCash) {
        cb({ cashAmount: preTotal - bankAmount - otherAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, ['cashNo', 'cashName']),
            cashAmount: null,
        });
    }
};
// Set bank amount
export const SET_BANK_AMOUNT = (
    values,
    isBank,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const cashAmount = +values?.cashAmount || 0;
    const otherAmount = +values?.otherAmount || 0;
    if (isBank) {
        cb({ bankAmount: preTotal - cashAmount - otherAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, ['bankNo', 'bankName']),
            bankAmount: null,
        });
    }
};
// Set other amount
export const SET_OTHER_AMOUNT = (
    values,
    isOther,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const cashAmount = +values?.cashAmount || 0;
    const bankAmount = +values?.bankAmount || 0;
    if (isOther) {
        cb({ otherAmount: preTotal - cashAmount - bankAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, [
                'otherNo',
                'otherName',
                'acno',
                'acName',
            ]),
            otherAmount: null,
        });
    }
};
// Set total amount
export const SET_TOTAL_AMOUNT = (cashAmount, bankAmount, otherAmount, cb) => {
    const totalAmount = cashAmount + bankAmount + otherAmount;
    const toWords = new ToWords({
        converterOptions: {
            currency: true,
        },
    });
    let words = toWords.convert(totalAmount);
    cb(prevValues => ({
        ...prevValues,
        totalAmount,
        totalAmountInWords: words,
    }));
};

// Is valid date
export const IS_VALID_DATE = (dateISO, msg = 'date') => {
    const date = moment(dateISO);
    const isValidDate = date.isValid();
    // console.log('isValidDate: ', isValidDate);
    // if (!isValidDate) message.error(`Invalid ${msg}`);
    return isValidDate;
};
