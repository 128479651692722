import React from 'react';
// Import from dependencies
import { Row, Col } from 'antd';
// Import components
import LoginLeft from '../../components/Login/LoginLeft/LoginLeft';
import LoginRight from '../../components/Login/LoginRight/LoginRight';

export default function Login() {
    return (
        <div className="login">
            <div className="login__wrapper">
                <Row>
                    <Col span={12}>
                        <div className="login__each login__each--left">
                            <LoginLeft />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="login__each login__each--right">
                            <LoginRight />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
