import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import validator from 'validator';
// Import utils
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Branches from '../../../Common/Geography/Branches/Branches';
import Remits from '../../../Common/Remittance/Remits/Remits';

const CreateRemitForm = React.forwardRef((props, ref) => {
    // Destructuring
    const { values, onValuesChange, mode, setDefaultValues } = props;

    React.useEffect(() => {
        setDefaultValues(mode);
    }, [mode, setDefaultValues]);

    return (
        <div className="area-form">
            <div className="block-general-titled--body">
                <Form
                    ref={ref}
                    className="margin-top"
                    layout="vertical"
                    onValuesChange={e => onValuesChange(e, mode)}
                    // labelCol={{ span: 8 }}
                    fields={[
                        {
                            name: ['entryDate'],
                            value:
                                values?.entryDate &&
                                ENG_ISO_TO_NEP(values.entryDate),
                        },
                        {
                            name: 'branchID',
                            value: values?.branchID,
                        },
                        {
                            name: 'branchName',
                            value: values?.branchName,
                        },
                        {
                            name: 'remitID',
                            value: values?.remitID,
                        },
                        {
                            name: 'remitName',
                            value: values?.remitName,
                        },
                        {
                            name: 'address',
                            value: values?.address,
                        },
                        {
                            name: 'commissionRate',
                            value: values?.commissionRate,
                        },
                        {
                            name: 'contactPerson',
                            value: values?.contactPerson,
                        },
                        {
                            name: 'contactno',
                            value: values?.contactno,
                        },
                        {
                            name: 'description',
                            value: values?.description,
                        },
                    ]}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Date"
                                name="entryDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Branches item="branchID" required />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Branches item="branchName" required />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            {mode === 'view' ? (
                                <Remits item="remitID" required />
                            ) : (
                                <Form.Item
                                    label="Remit ID"
                                    name="remitID"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            )}
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            {mode === 'view' ? (
                                <Remits item="remitName" />
                            ) : (
                                <Form.Item
                                    label="Remit Name"
                                    name="remitName"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={10}>
                            <Form.Item label="Address" name="address">
                                <Input disabled={mode === 'view'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Commission Rate"
                                name="commissionRate"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                validator.isDecimal(value + '')
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Digits only')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Contact Person"
                                name="contactPerson"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Contact No"
                                name="contactno"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                validator.isInt(value)
                                            ) {
                                                if (
                                                    value &&
                                                    value.length !== 10
                                                ) {
                                                    return Promise.reject(
                                                        new Error('10 Digits')
                                                    );
                                                }
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Digits only')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={10}>
                            <Form.Item label="Description" name="description">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default CreateRemitForm;
