import React from 'react';
import { Form, Tabs } from 'antd';
import { ENG_ISO_TO_NEP, IS_VALID_DATE } from '../../../utils/functions';

const MemberRegistrationForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        tabPanes,
        activePanelKey,
        onPanelChange,
        members,
        provinces,
        setProvinces,
        districtsTmp,
        districtsPer,
        municipalitiesTmp,
        municipalitiesPer,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        education,
        setEducation,
        occupation,
        setOccupation,
        groups,
        setGroups,
        areas,
        setAreas,
        staffs,
        setStaffs,
        values,
        onValuesChange,
        handleAddressSameAsPermanent,
        onFileChange,
        memberNOValidationStatus,
        regSavIdValidationStatus,
        setDefaultValues,
        onDateChange,
    } = props;

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <div className="block-general-titled--body">
            <Form
                ref={ref}
                layout="vertical"
                className="margin-top"
                onValuesChange={val => onValuesChange(val, mode)}
                fields={[
                    {
                        name: 'memberName',
                        value: values?.memberName,
                    },
                    {
                        name: 'area',
                        value: values?.area,
                    },
                    {
                        name: 'blockNO',
                        value: values?.blockNO,
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'casteGroup',
                        value: values?.casteGroup,
                    },
                    {
                        name: 'caste',
                        value: values?.caste,
                    },
                    {
                        name: 'citDate',
                        value: values?.citDate,
                    },
                    {
                        name: 'citDistrict',
                        value: values?.citDistrict,
                    },
                    {
                        name: 'citOffice',
                        value: values?.citOffice,
                    },
                    {
                        name: 'citizenLoc',
                        value: values?.citizenLoc,
                    },
                    {
                        name: 'citizenshipno',
                        value: values?.citizenshipno,
                    },
                    {
                        name: 'district',
                        value: values?.district,
                    },
                    {
                        name: 'education',
                        value: values?.education,
                    },
                    {
                        name: 'emailID',
                        value: values?.emailID,
                    },
                    {
                        name: 'gender',
                        value: values?.gender,
                    },
                    {
                        name: 'grandHeadName',
                        value: values?.grandHeadName,
                    },
                    {
                        name: 'group',
                        value: values?.group,
                    },
                    {
                        name: 'groupType',
                        value: values?.groupType,
                    },
                    {
                        name: 'headName',
                        value: values?.headName,
                    },
                    {
                        name: 'lastModifiedBy',
                        value: values?.lastModifiedBy,
                    },
                    {
                        name: 'maritalStatus',
                        value: values?.maritalStatus,
                    },
                    {
                        name: 'memberNO',
                        value: values?.memberNO,
                    },
                    {
                        name: 'memberName',
                        value: values?.memberName,
                    },
                    {
                        name: 'mobileNO',
                        value: values?.mobileNO,
                    },
                    {
                        name: 'monthlyIncome',
                        value: values?.monthlyIncome,
                    },
                    {
                        name: 'motherName',
                        value: values?.motherName,
                    },
                    {
                        name: 'msid',
                        value: values?.msid,
                    },
                    {
                        name: 'nomDOB',
                        value: values?.nomDOB,
                    },
                    {
                        name: 'nomRel',
                        value: values?.nomRel,
                    },
                    {
                        name: 'nominee',
                        value: values?.nominee,
                    },
                    {
                        name: 'occupation',
                        value: values?.occupation,
                    },
                    {
                        name: 'panno',
                        value: values?.panno,
                    },
                    {
                        name: 'passportLoc',
                        value: values?.passportLoc,
                    },
                    {
                        name: 'passportNO',
                        value: values?.passportNO,
                    },
                    {
                        name: 'photoLoc',
                        value: values?.photoLoc,
                    },
                    {
                        name: 'province',
                        value: values?.province,
                    },
                    {
                        name: 'regSaveId',
                        value: values?.regSaveId,
                    },
                    {
                        name: 'religion',
                        value: values?.religion,
                    },
                    {
                        name: 'shareHolderNO',
                        value: values?.shareHolderNO,
                    },
                    {
                        name: 'signLoc',
                        value: values?.signLoc,
                    },
                    {
                        name: 'signLoc',
                        value: values?.signLoc,
                    },
                    {
                        name: 'staffID',
                        value: values?.staffID,
                    },
                    {
                        name: 'staffName',
                        value: values?.staffName,
                    },
                    {
                        name: 'telephone',
                        value: values?.telephone,
                    },
                    {
                        name: 'tmpBlockNO',
                        value: values?.tmpBlockNO,
                    },
                    {
                        name: 'tmpDistrict',
                        value: values?.tmpDistrict,
                    },
                    {
                        name: 'tmpProvince',
                        value: values?.tmpProvince,
                    },
                    {
                        name: 'tmpTole',
                        value: values?.tmpTole,
                    },
                    {
                        name: 'tmpVDCMun',
                        value: values?.tmpVDCMun,
                    },
                    {
                        name: 'tmpWardNO',
                        value: values?.tmpWardNO,
                    },
                    {
                        name: 'type',
                        value: values?.type,
                    },
                    {
                        name: 'unDistrict',
                        value: values?.unDistrict,
                    },
                    {
                        name: 'unFather',
                        value: values?.unFather,
                    },
                    {
                        name: 'unGrandFather',
                        value: values?.unGrandFather,
                    },
                    {
                        name: 'unMotherName',
                        value: values?.unMotherName,
                    },
                    {
                        name: 'unName',
                        value: values?.unName,
                    },
                    {
                        name: 'unNominee',
                        value: values?.unNominee,
                    },
                    {
                        name: 'unProvince',
                        value: values?.unProvince,
                    },
                    {
                        name: 'spouse',
                        value: values?.spouse,
                    },
                    {
                        name: 'unSpouse',
                        value: values?.unSpouse,
                    },
                    {
                        name: 'unTole',
                        value: values?.unTole,
                    },
                    {
                        name: 'unWardNO',
                        value: values?.unWardNO,
                    },
                    {
                        name: 'untDistrict',
                        value: values?.untDistrict,
                    },
                    {
                        name: 'untProvince',
                        value: values?.untProvince,
                    },
                    {
                        name: 'untTole',
                        value: values?.untTole,
                    },
                    {
                        name: 'untWardNO',
                        value: values?.untWardNO,
                    },
                    {
                        name: 'untvdc',
                        value: values?.untvdc,
                    },
                    {
                        name: 'unvdc',
                        value: values?.unvdc,
                    },
                    {
                        name: 'vdcMun',
                        value: values?.vdcMun,
                    },
                    {
                        name: 'wardNO',
                        value: values?.wardNO,
                    },
                    {
                        name: 'tole',
                        value: values?.tole,
                    },
                    {
                        name: 'entryDate',
                        value:
                            values?.entryDate &&
                            ENG_ISO_TO_NEP(values.entryDate),
                    },
                    {
                        name: 'dateOfBirth',
                        value:
                            values?.dateOfBirth &&
                            IS_VALID_DATE(values?.dateOfBirth) &&
                            ENG_ISO_TO_NEP(values.dateOfBirth),
                    },
                    {
                        name: 'nomDOB',
                        value: values?.nomDOB && ENG_ISO_TO_NEP(values.nomDOB),
                    },
                    {
                        name: 'citDate',
                        value:
                            values?.citDate && ENG_ISO_TO_NEP(values.citDate),
                    },
                ]}
            >
                <Tabs activeKey={activePanelKey} onChange={onPanelChange}>
                    {tabPanes.map((pane, i) => {
                        return (
                            <Tabs.TabPane tab={pane.title} key={i}>
                                {React.cloneElement(pane.component, {
                                    mode,
                                    members,
                                    provinces,
                                    setProvinces,
                                    districtsTmp,
                                    districtsPer,
                                    municipalitiesTmp,
                                    municipalitiesPer,
                                    casteGroups,
                                    setCasteGroups,
                                    castes,
                                    religions,
                                    setReligions,
                                    values,
                                    maritalStatuses,
                                    setMaritalStatuses,
                                    education,
                                    setEducation,
                                    occupation,
                                    setOccupation,
                                    groups,
                                    setGroups,
                                    areas,
                                    setAreas,
                                    staffs,
                                    setStaffs,
                                    handleAddressSameAsPermanent,
                                    onFileChange,
                                    memberNOValidationStatus,
                                    regSavIdValidationStatus,
                                    onDateChange,
                                })}
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            </Form>
        </div>
    );
});

export default MemberRegistrationForm;
