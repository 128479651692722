import React from 'react';
import { Form, Select } from 'antd';

export default function ActiveDepositTypeAccounts(props) {
    const { accounts, item, required } = props;
    let label;
    if (item === 'acno') {
        label = 'Deposit Type ACNO';
    } else if (item === 'acName') {
        label = 'Deposit Type';
    } else {
        label = 'Nepali name';
    }
    return (
        <Form.Item
            label={label}
            name={item}
            rules={[{ required: required, message: 'Required' }]}
        >
            <Select
                placeholder="Select..."
                showSearch
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {accounts?.map((acc, i) => (
                    <Select.Option key={i} value={acc[item]}>
                        {acc[item]}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
