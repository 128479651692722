import React from 'react';
import { Row, Col, Form, Select, Input, Button, Space } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import validator from 'validator';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Branches from '../../../Common/Geography/Branches/Branches';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

export default function CounterWithdrawForm(props) {
    // Destructuring
    const {
        loanTypeAccounts,
        loanMembers,
        values,
        onValuesChange,
        getLoanMemberInfoShortQuick,
        isProcessing,
        handleNew,
        onFormSubmit,
    } = props;

    // Local states
    return (
        <div>
            <Form
                layout="horizontal"
                className="margin-top"
                onValuesChange={onValuesChange}
                onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'loanNo',
                        value: values?.loanNo,
                    },
                    {
                        name: 'loanNoAlt',
                        value: values?.loanNoAlt,
                    },
                    {
                        name: 'name',
                        value: values?.name,
                    },
                    {
                        name: 'memberNo',
                        value: values?.memberNo,
                    },
                    {
                        name: 'address',
                        value: values?.address,
                    },
                    {
                        name: 'expireDate',
                        value:
                            values?.expireDate &&
                            ENG_ISO_TO_NEP(values.expireDate),
                    },
                    {
                        name: 'paidTo',
                        value: values?.paidTo,
                    },
                    {
                        name: 'transDate',
                        value:
                            values?.transDate &&
                            ENG_ISO_TO_NEP(values.transDate),
                    },
                    {
                        name: 'agreementDate',
                        value:
                            values?.agreementDate &&
                            ENG_ISO_TO_NEP(values.agreementDate),
                    },
                    {
                        name: 'todaysIssue',
                        value: values?.todaysIssue,
                    },
                    {
                        name: 'cashNo',
                        value: values?.cashNo,
                    },
                    {
                        name: 'cashName',
                        value: values?.cashName,
                    },
                    {
                        name: 'cashAmount',
                        value: values?.cashAmount,
                    },
                    {
                        name: 'bankNo',
                        value: values?.bankNo,
                    },
                    {
                        name: 'bankName',
                        value: values?.bankName,
                    },
                    {
                        name: 'bankAmount',
                        value: values?.bankAmount,
                    },
                    {
                        name: 'otherNo',
                        value: values?.otherNo,
                    },
                    {
                        name: 'otherName',
                        value: values?.otherName,
                    },
                    {
                        name: 'otherAmount',
                        value: values?.otherAmount,
                    },
                    {
                        name: 'otherAcno',
                        value: values?.otherAcno,
                    },
                    {
                        name: 'otherAcName',
                        value: values?.otherAcName,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'receiptNo',
                        value: values?.receiptNo,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'totalAmount',
                        value: values?.totalAmount,
                    },
                    {
                        name: 'totalAmountInWords',
                        value: values?.totalAmountInWords,
                    },

                    {
                        name: 'oldBalance',
                        value: values?.oldBalance,
                    },
                    {
                        name: 'isCash',
                        value: values?.isCash,
                    },
                    {
                        name: 'agreedLoan',
                        value: values?.agreedLoan,
                    },
                    {
                        name: 'serviceChargeRs',
                        value: values?.serviceChargeRs,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={15}>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={9} lg={9}>
                                    <Branches item="branchName" />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={9}>
                                    <Branches item="branchID" />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item
                                        label="Loan No"
                                        className="borderinfo"
                                    ></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={13} lg={13}>
                                    <Form.Item name="loanNoAlt">
                                        <Input
                                            className="ant-input--highlight-secondary"
                                            onPressEnter={
                                                getLoanMemberInfoShortQuick
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={13} lg={4}></Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Loan Type"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item
                                        name="acName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {loanTypeAccounts?.length &&
                                                loanTypeAccounts.map(
                                                    (acc, i) => (
                                                        <Select.Option
                                                            key={i}
                                                            value={acc.acName}
                                                        >
                                                            {acc.acName}
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6}>
                                    <Form.Item
                                        name="acno"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {loanTypeAccounts?.length &&
                                                loanTypeAccounts.map(
                                                    (acc, i) => (
                                                        <Select.Option
                                                            key={i}
                                                            value={acc.acno}
                                                        >
                                                            {acc.acno}
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Loan No"></Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item
                                        name="loanNo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {loanMembers?.length &&
                                                loanMembers.map((acc, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={acc.loanNo}
                                                    >
                                                        {acc.loanNo}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Loan Holder"></Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item name="name">
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {loanMembers?.length &&
                                                loanMembers.map((acc, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={acc.name}
                                                    >
                                                        {acc.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6}>
                                    <Form.Item name="memberNo">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Address"></Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item name="address">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Paid To"></Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item name="paidTo">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={5}>
                                    <Form.Item label="Description"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={13}>
                                    <Form.Item name="description">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={6}>
                                    <Form.Item>
                                        <Button type="default" className="btns">
                                            Statement
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={6}>
                                    <Form.Item>
                                        <Button type="default" className="btns">
                                            Notes
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col span={9}>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Date"></Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={16}>
                                    <Form.Item name="transDate">
                                        <NepaliDatePicker inputClassName="ant-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={10} lg={8}>
                                    <Form.Item label="Journal No"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="journalNO">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Trans No "></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="transNo">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={24}>
                                    <br></br>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Approved On"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="agreementDate">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Approved Amount"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="agreedLoan">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Old Balance"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="oldBalance">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Today's Issue"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item
                                        name="todaysIssue"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value)
                                                        return Promise.resolve();
                                                    if (
                                                        !validator.isInt(value)
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Digits only'
                                                            )
                                                        );
                                                    }
                                                    if (+value === 0) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Cannot be zero'
                                                            )
                                                        );
                                                    }
                                                    if (
                                                        !value ||
                                                        +getFieldValue(
                                                            'agreedLoan'
                                                        ) >=
                                                            +value +
                                                                +getFieldValue(
                                                                    'oldBalance'
                                                                )
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Amount overflow'
                                                        )
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            // disabled
                                            className="ant-input--highlight-primary"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Service Charge"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item
                                        name="serviceChargeRs"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value)
                                                        return Promise.resolve();
                                                    if (
                                                        !validator.isInt(value)
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'Digits only'
                                                            )
                                                        );
                                                    }
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            'todaysIssue'
                                                        ) > +value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Amount overflow'
                                                        )
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            addonBefore="Rs"
                                            // disabled
                                            // className="ant-input--highlight-primary"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <PaymentSource
                        title="Payment Source"
                        mode="payment"
                        values={values}
                    />
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        htmlType="submit"
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
