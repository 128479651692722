import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../utils/functions';

// Import utils

const MemberGroupForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        groups,
        groupTypes,
        areas,
        values,
        onValuesChange,
        mode,
        setDefaultValues,
    } = props;

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <div className="member-group-form">
            <div className="block-general-titled--body">
                <Form
                    className="margin-top"
                    ref={ref}
                    layout="vertical"
                    fields={[
                        {
                            name: ['groupId'],
                            value: values?.groupId,
                        },
                        {
                            name: ['groupName'],
                            value: values?.groupName,
                        },
                        {
                            name: ['gtid'],
                            value: values?.gtid,
                        },
                        {
                            name: ['areaID'],
                            value: values?.areaID,
                        },
                        {
                            name: ['address'],
                            value: values?.address,
                        },
                        {
                            name: ['coordinator'],
                            value: values?.coordinator,
                        },
                        {
                            name: ['collectionDay'],
                            value: values?.collectionDay,
                        },
                        {
                            name: ['collectionDay2'],
                            value: values?.collectionDay2,
                        },
                        {
                            name: ['formedDate'],
                            value:
                                values?.formedDate &&
                                ENG_ISO_TO_NEP(values.formedDate),
                        },
                    ]}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Group No." name="groupId">
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {groups.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.groupId}
                                            >
                                                {group.groupId}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Group Name"
                                name="groupName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {groups.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.groupName}
                                            >
                                                {group.groupName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Formed Date"
                                name="formedDate"
                                rules={[
                                    { required: true, message: 'Required' },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Group Type" name="gtid">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {groupTypes.map(group => (
                                        <Select.Option value={group.gtid}>
                                            {group.groupType}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Area" name="areaID">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {areas.map(area => (
                                        <Select.Option value={area.areaID}>
                                            {area.areaName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Address" name="address">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Coordinator" name="coordinator">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Collection Day"
                                name="collectionDay"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Collection Day 2"
                                name="collectionDay2"
                                rules={[
                                    {
                                        pattern: '^[1-2]?[2-9]$|^[3][0-0]$',
                                        message: 'Should be between 2 and 30',
                                    },
                                ]}
                            >
                                <Input placeholder="Between 2 and 30" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default MemberGroupForm;
