import React from 'react';
import { Form, Select, Row, Col } from 'antd';
import { GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS } from '../../../utils/axios';
import PhotoUpload from '../../Common/PhotoUpload/PhotoUpload';

export default function DepositRegistrationForm3(props) {
    // Destructuring
    const {
        mode,
        transferableAccounts,
        setTransferableAccounts,
        savingTypeAccountsFull,
        onFileChange,
        values,
    } = props;

    // On component mount
    React.useEffect(() => {
        (async () => {
            if (mode !== 'view') {
                const transferableAccounts =
                    await GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS();

                setTransferableAccounts(transferableAccounts);
            }
        })();
    }, [mode, setTransferableAccounts]);
    return (
        <div>
            <h3 className="colored-title">Interest Collection Account</h3>
            <Row gutter={20}>
                <Col span={20}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={10}>
                            <Form.Item
                                label="Deposit Type"
                                name="intColDepTypeName"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {transferableAccounts?.map((acc, i) => (
                                        <Select.Option
                                            key={i}
                                            value={acc.acName}
                                        >
                                            {acc.acName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Deposit Type No"
                                name="intColDepType"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {transferableAccounts?.map((acc, i) => (
                                        <Select.Option key={i} value={acc.acno}>
                                            {acc.acno}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={10}>
                            <Form.Item
                                label="Account Name"
                                name="transferInterestToName"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccountsFull?.map((acc, i) => (
                                        <Select.Option
                                            key={i}
                                            value={acc.fullName}
                                        >
                                            {acc.fullName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Account No"
                                name="transferInterestTo"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccountsFull?.map((acc, i) => (
                                        <Select.Option
                                            key={i}
                                            value={acc.accountNo}
                                        >
                                            {acc.accountNo}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={10}>
                            <Form.Item
                                label="Transfer Interest On"
                                name="intTransPeriod"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="0">
                                        As defined on Deposit Type
                                    </Select.Option>
                                    <Select.Option value="1">
                                        Monthly
                                    </Select.Option>
                                    <Select.Option value="2">
                                        Quarterly
                                    </Select.Option>
                                    <Select.Option value="3">
                                        Half Yearly
                                    </Select.Option>
                                    <Select.Option value="4">
                                        Yearly
                                    </Select.Option>
                                    <Select.Option value="5">
                                        At the time of Maturity
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <PhotoUpload
                        mode={mode}
                        label="Signature"
                        onFileChange={onFileChange}
                        inputName="signImg"
                        photoBinary={values?.signImg}
                        photoUrl={values?.signature}
                    />
                </Col>
            </Row>
        </div>
    );
}
