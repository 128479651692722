import React from 'react';
// Import from dependencies
import { Switch, Route, Redirect } from 'react-router-dom';
// Import components
import Auth from '../containers/Auth/Auth';

export default function PublicRoutes() {
	return (
		<Switch>
			<Route path="/auth" component={Auth} />
			<Redirect to="/auth/login" />
		</Switch>
	);
}
