import React from 'react';
import { GET_KISTA_PERIODS } from '../../../utils/axios';
import { Form, Row, Col, Input, Radio, Checkbox, Select } from 'antd';
import {
    CONTAINS_DIGITS_OR_DECIMAL_ONLY,
    CONTAINS_DIGITS_ONLY,
} from '../../../utils/functions';

export default function InterestAndKista(props) {
    // Destructuring
    const { mode, kistaPeriods, setKistaPeriods, values, onCheckboxChange } =
        props;

    // On component mount
    React.useEffect(() => {
        if (mode === 'edit' || mode === 'add')
            (async () => {
                const kistaPeriods = await GET_KISTA_PERIODS();
                setKistaPeriods(kistaPeriods);
            })();
    }, [setKistaPeriods, mode]);

    return (
        <div>
            <div>
                <Row gutter={20}>
                    <Col xs={24} sm={12} md={4}>
                        <Form.Item
                            label="Service charge."
                            name="serviceCharge"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            CONTAINS_DIGITS_OR_DECIMAL_ONLY(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                        <Form.Item
                            label="Interest Rate."
                            name="interestRate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            CONTAINS_DIGITS_OR_DECIMAL_ONLY(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                        <Form.Item
                            label="Extra Rate Passing."
                            name="passingRate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            CONTAINS_DIGITS_OR_DECIMAL_ONLY(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input addonAfter="%" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={11}></Col>

                    <Col xs={24} sm={12} md={4}>
                        <Form.Item
                            label="Rebate system"
                            name="rebateSystem"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Checkbox>Active</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={4}>
                        <Form.Item
                            label="Rebate Rate"
                            name="rebateRate"
                            rules={[
                                {
                                    required: values?.rebateSystem,
                                    message: 'Required',
                                },
                                () =>
                                    values?.rebateSystem && {
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                CONTAINS_DIGITS_OR_DECIMAL_ONLY(
                                                    value
                                                )
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Digits only')
                                            );
                                        },
                                    },
                            ]}
                        >
                            <Input
                                addonAfter="%"
                                disabled={!values?.rebateSystem}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={6}>
                        <Form.Item
                            label="Adjust with Interest"
                            name="deductRebate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Radio.Group
                                name="deductRebate"
                                disabled={!values?.rebateSystem}
                            >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}></Col>

                    <Col xs={24} sm={12} md={6}>
                        <Form.Item
                            label="Interest Calculating System"
                            name="ips"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Radio.Group name="ips">
                                <Radio value="DimBal">Diminishing</Radio>
                                <Radio value="Flat">Flat</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={16}>
                        <Form.Item
                            label="Recovery System"
                            name="lps"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Radio.Group name="lps">
                                <Radio value="Installment">
                                    Normal installment
                                </Radio>

                                <Radio value="Fixed">Fixed Installment</Radio>
                                <Radio value="Interest">Interest Only</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={4}>
                        <Form.Item
                            label="No of Installment"
                            name="totalKista"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (CONTAINS_DIGITS_ONLY(value)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={5}>
                        <Form.Item
                            label="Installment period"
                            name="kistaPeriod"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select..."
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {kistaPeriods?.map((period, i) => (
                                    <Select.Option
                                        key={i}
                                        value={period.kistaPeriod}
                                    >
                                        {period.kistaPeriod}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        ,
                    </Col>

                    <Col xs={24} sm={12} md={24}>
                        <label
                            style={{
                                display: 'block',
                                marginBottom: '.5rem',
                            }}
                        >
                            Extra options
                        </label>

                        <Checkbox
                            name="loanOnSaving"
                            checked={values?.loanOnSaving}
                            onChange={e => onCheckboxChange(e, mode)}
                        >
                            Loan on Deposit
                        </Checkbox>
                        <Checkbox
                            name="csItem"
                            checked={values?.csItem}
                            onChange={e => onCheckboxChange(e, mode)}
                        >
                            Collection Sheet Item
                        </Checkbox>
                        <Checkbox
                            name="isLOC"
                            checked={values?.isLOC}
                            onChange={e => onCheckboxChange(e, mode)}
                        >
                            Is Microfinance Loan
                        </Checkbox>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
