import React from 'react';
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import { useLocation, useHistory } from 'react-router-dom';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherItems as otherItemsAtom,
    otherAccounts as otherAccountsAtom,
} from '../../../recoil/atoms';
import moment from 'moment';
import {
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    NEP_TO_ENG_ISO,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
import axios, {
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
    GET_SAVING_ACCOUNT_INFO_SHORT,
    GET_SAVING_ACCOUNT_CHEQUE_NOS,
} from '../../../utils/axios';
import CounterWithdrawForm from '../../../components/Counter/CounterWidthdraw/CounterWithdrawForm/CounterWithdrawForm';

export default function CounterWithdraw() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [depTypeAccounts, setDepTypeAccounts] = React.useState([]);
    const [savingTypeAccounts, setSavingTypeAccounts] = React.useState([]);
    const [chequeNos, setChequeNos] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);

    // Destructuring

    // Variables and Constants
    const location = useLocation();
    const history = useHistory();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            transDate: moment().format(),
            receivedPaidBy: 'Self',
            untimeCharge: 0,
            isClearance: false,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues, 'withdrawAmount');
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues, 'withdrawAmount');
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues, 'withdrawAmount');
        } else if (key === 'acno' || key === 'acName') {
            const acc = depTypeAccounts.find(acc => acc[key] === value);
            return updateValues(acc);
        } else if (key === 'accountNo' || key === 'fullName') {
            const acc = savingTypeAccounts.find(acc => acc[key] === value);
            return updateValues(acc);
        } else if (key === 'transDate') {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherNo', 'otherName']),
                otherMano: acc.mano,
                otherAcno: acc.acno,
                otherAcName: acc.acName,
            });
        }
        // Update values
        updateValues(val);
    };

    // On form submit
    const onFormSubmit = () => {
        console.log(viewValues, 'viewValues');
        setIsProcessing(true);
        const payload = {
            transDate: viewValues?.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            memberNO: viewValues?.memberNo,
            memberName: viewValues?.fullName,
            memAddress: viewValues?.address,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType:
                JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                    .branchID === viewValues?.branchID
                    ? 'Withdraw Deposit'
                    : 'ABBS Withdraw Deposit',
            description: viewValues?.description,
            debitTrans: [
                {
                    mano: '030',
                    acno: viewValues?.acno,
                    bvrcno: viewValues?.chequeNo || null,
                    itemCode: viewValues?.accountNo,
                    itemName: viewValues?.fullName,
                    itemLocation: viewValues?.address,
                    receivedPaidBy: viewValues?.receivedPaidBy,
                    remarks1: '',
                    rate: 1,
                    interBranchID: viewValues?.branchID,
                    amount: viewValues?.withdrawAmount,
                    quantity: 1,
                    particulars: `Deposit Withdraw - ${viewValues?.fullName} ${viewValues?.acno}`,
                    deposittFine: viewValues?.untimeCharge,
                },
            ],
            creditTrans: [
                ...(viewValues?.isCash
                    ? [
                          {
                              mano: '080',
                              acno: '080.01',
                              bvrcno: viewValues?.cashBvrcno,
                              itemCode: viewValues?.cashNo,
                              itemName: viewValues?.cashName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues.receivedPaidBy,
                              remarks1: '0',
                              rate: viewValues?.cashAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues.cashAmount,
                              quantity: '1',
                              particulars: `Cash - ${viewValues?.fullName} ${viewValues?.acno}`,
                          },
                      ]
                    : []),
                ...(viewValues.isBank
                    ? [
                          {
                              mano: bankAccounts[0].mano,
                              acno: bankAccounts[0].acno,
                              bvrcno: viewValues?.bankBvrcno,
                              itemCode: viewValues?.bankNo,
                              itemName: viewValues?.bankName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.paidBy,
                              remarks1: '0',
                              rate: viewValues?.bankAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.bankAmount,
                              quantity: '1',
                              particulars: `${viewValues?.bankName} - ${viewValues?.fullName} ${viewValues?.acno}`,
                          },
                      ]
                    : []),
                ...(viewValues?.isOther
                    ? [
                          {
                              mano: viewValues.otherMano,
                              acno: viewValues.otherAcno,
                              bvrcno: '',
                              itemCode: viewValues?.otherNo,
                              itemName: viewValues?.otherName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.paidBy,
                              remarks1: '0',
                              rate: viewValues?.otherAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.otherAmount,
                              quantity: '1',
                              particulars: `${viewValues?.otherAcName} - ${viewValues?.fullName} ${viewValues?.acno}`,
                          },
                      ]
                    : []),
            ],
        };
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/WithdrawDeposit`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to process share addition');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Get dep short info
    const getDepShortInfo = () => {
        if (!viewValues?.branchID || !viewValues?.accountNoAlt) return;

        const branchID = viewValues?.branchID;
        const accountNo = viewValues?.accountNoAlt;
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountInfoShort/${branchID}/${accountNo}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get saving account info short');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues();
        setIsProcessing(false);
    };

    // Handle get dep short info quick
    const getDepShortInfoQuick = e => {
        e.preventDefault();
        getDepShortInfo();
    };

    // Form props
    const formProps = {
        depTypeAccounts,
        savingTypeAccounts,
        chequeNos,
        onValuesChange,
        getDepShortInfo,
        isProcessing,
        handleNew,
        onFormSubmit,
        getDepShortInfoQuick,
    };

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const depTypeAccounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setDepTypeAccounts(depTypeAccounts);
            setDefaultValues();
        })();
    }, []);

    // Get saving type accounts
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.acno) {
            console.log(viewValues.branchID, viewValues.acno);
            (async () => {
                const savingTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                    viewValues.branchID,
                    viewValues.acno
                );
                setSavingTypeAccounts(savingTypeAccounts);
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.acno]);

    // Get saving type info short
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.accountNo && viewValues?.acno) {
            const branchID = viewValues.branchID;
            const accountNo = viewValues.accountNo;
            const acno = viewValues.acno;
            (async () => {
                const savingAccountInfoShort =
                    await GET_SAVING_ACCOUNT_INFO_SHORT(
                        branchID,
                        accountNo,
                        acno
                    );
                console.log('savingAccountInfoShort: ', savingAccountInfoShort);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...savingAccountInfoShort,
                }));
                // Get actual balance
                axios
                    .get(
                        `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${branchID}/030/${acno}/${accountNo}`
                    )
                    .then(res => {
                        setViewValues(prevValues => ({
                            ...prevValues,
                            actualBal: res.data.data,
                        }));
                    });
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.accountNo, viewValues?.acno]);

    // Get net balance
    React.useEffect(() => {
        if (
            (viewValues?.actualBal || viewValues?.actualBal === 0) &&
            (viewValues?.minBal || viewValues?.minBal === 0) &&
            (viewValues?.lockedAmount || viewValues?.lockedAmount === 0) &&
            (viewValues?.guarantedAmount || viewValues?.guarantedAmount === 0)
        ) {
            const actualBal = viewValues.actualBal;
            const minBal = viewValues.minBal;
            const lockedAmount = viewValues.lockedAmount;
            const guarantedAmount = viewValues.guarantedAmount;
            const netBal =
                actualBal - (minBal + lockedAmount + guarantedAmount);
            setViewValues(prevValues => ({
                ...prevValues,
                netBal,
            }));
        }
    }, [
        viewValues?.actualBal,
        viewValues?.guarantedAmount,
        viewValues?.lockedAmount,
        viewValues?.minBal,
    ]);

    // Get check no
    React.useEffect(() => {
        if (viewValues?.acno && viewValues?.accountNo) {
            (async () => {
                const acno = viewValues.acno;
                const accountNo = viewValues.accountNo;
                const chequeNos = await GET_SAVING_ACCOUNT_CHEQUE_NOS(
                    acno,
                    accountNo
                );
                console.log('chequeNos: ', chequeNos);
                setChequeNos(chequeNos);
            })();
        }
    }, [viewValues?.acno, viewValues?.accountNo]);

    // Set total debit
    React.useEffect(() => {
        if (viewValues?.withdrawAmount) {
            const withdrawAmount = viewValues.withdrawAmount;
            const untimeCharge = viewValues?.untimeCharge || 0;
            const preTotal = +withdrawAmount + +untimeCharge;
            setViewValues(prevValues => ({
                ...prevValues,
                preTotal,
            }));
        }
    }, [viewValues?.withdrawAmount, viewValues?.untimeCharge]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Extract query params and get info
    React.useEffect(() => {
        if (location.search) {
            const search = location.search;
            const arr = search.split('&');
            const acno = arr[0].split('=')[1];
            const acName = arr[1].split('=')[1].replace(/%20/g, ' ');
            const accountNo = arr[2].split('=')[1];

            setViewValues(prevValues => ({
                ...prevValues,
                acno,
                acName,
                accountNo,
            }));
            history.push('/counter-withdraw');
        }
    }, [location.search, history]);

    return (
        <div className="owner-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Counter Withdraw
                </h3>
                <div className="container" style={{ marginLeft: 'unset' }}>
                    <CounterWithdrawForm {...formProps} values={viewValues} />
                </div>
            </div>
        </div>
    );
}
