import React from 'react';
import { Form, Select } from 'antd';
import { useRecoilValue } from 'recoil';
import { branches as branchesAtom } from '../../../../recoil/atoms';

export default function Branches(props) {
    // Global states
    const branches = useRecoilValue(branchesAtom);
    // Destructuring
    const { item, name, required = false, disabled = false } = props;
    return (
        <Form.Item
            label={item === 'branchName' ? 'Branch Name' : 'Branch ID'}
            name={name || item}
            rules={[
                {
                    required,
                    message: 'Required',
                },
            ]}
        >
            <Select
                placeholder="Select..."
                disabled={disabled}
                showSearch
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {branches?.map((branch, i) => (
                    <Select.Option key={i} value={branch[item]}>
                        {branch[item]}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
