import React from 'react';
import { Form, Select, Input, Row, Col, DatePicker, Radio } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/constants';
import { GET_GROUPS, GET_AREAS, GET_OWNER_TYPES } from '../../../utils/axios';
import PhotoUpload from '../../Common/PhotoUpload/PhotoUpload';

export default function PersonalDetailForm(props) {
    // Destructuring
    const {
        mode,
        owners,
        ownerTypes,
        setOwnerTypes,
        values,
        groups,
        setGroups,
        areas,
        setAreas,
        onDateChange,
        ownerNOValidationStatus,
        onFileChange,
    } = props;
    // On component mount

    React.useEffect(() => {
        (async () => {
            const groups = await GET_GROUPS();
            setGroups(groups);
            const areas = await GET_AREAS();
            setAreas(areas);
            const ownerTypes = await GET_OWNER_TYPES();
            setOwnerTypes(ownerTypes);
        })();
    }, [setGroups, setAreas, setOwnerTypes]);

    return (
        <div className="personal-detail-form">
            <Row gutter={40}>
                <Col xs={24} lg={19}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Branch Name" name="branchName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Branch ID" name="branchID">
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="Entrance Date" name="entryDate">
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Owner No."
                                name="ownerNO"
                                rules={
                                    mode === 'add' &&
                                    JSON.parse(
                                        localStorage.getItem(
                                            'ENTERPRISE_DETAIL'
                                        )
                                    ).fetchIDbeforeInsert && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                                validateStatus={ownerNOValidationStatus}
                                hasFeedback={
                                    mode === 'add' && ownerNOValidationStatus
                                }
                                help={
                                    ownerNOValidationStatus === 'error'
                                        ? 'Already taken'
                                        : null
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {owners?.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.ownerNO}
                                            >
                                                {member.ownerNO}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Input
                                        placeholder="Enter..."
                                        disabled={mode === 'edit'}
                                    />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Owner Type" name="ownerType">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ownerTypes?.map((type, i) => (
                                        <Select.Option key={i} value={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={18}></Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Owner Name"
                                name="ownerName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="सदस्यको नाम" name="unName">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}></Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Date of Birth (English)"
                                name="dobEnglish"
                            >
                                <span style={{ display: 'none' }}></span>
                                <DatePicker
                                    value={
                                        values?.dobEnglish &&
                                        moment(
                                            moment(values?.dobEnglish).format(
                                                DATE_FORMAT
                                            )
                                        )
                                    }
                                    format={DATE_FORMAT}
                                    allowClear={false}
                                    onChange={date =>
                                        onDateChange(date, 'dobEnglish', mode)
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="जन्म मिति (नेपालि)"
                                name="dateOfBirth"
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Radio.Group>
                                    <Radio value="M">Male</Radio>
                                    <Radio value="F">Female</Radio>
                                    <Radio value="I">Institution</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Service Area"
                                name="area"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {areas?.map((area, i) => (
                                        <Select.Option
                                            key={i}
                                            value={area.areaName}
                                        >
                                            {area.areaName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Group"
                                name="group"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {groups?.map((group, i) => (
                                        <Select.Option
                                            key={i}
                                            value={group.groupName}
                                        >
                                            {group.groupName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={12}></Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telephone No." name="telephone">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Mobile No."
                                name="mobileNO"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                        {
                                            len: 10,
                                            message: 'Must be 10 digits',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Email ID"
                                name="emailID"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            type: 'email',
                                            message: 'Invalid email address',
                                        },
                                    ]
                                }
                            >
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={5}>
                    <PhotoUpload
                        mode={mode}
                        onFileChange={onFileChange}
                        inputName="photoImg"
                        photoBinary={values?.photoImg}
                        photoUrl={values?.photo}
                    />
                    {/* <Form.Item label="Add/Change Photo" name="photoLoc">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img
                                    src={photoUrl || PhotoPlaceholder}
                                    alt="Profile"
                                />
                            </div>
                            {mode !== 'view' && (
                                <div className="avatar--upload-button">
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept={PROFILE_PIC_SUPPORTED_EXT}
                                        onChange={e => onFileChange(e, mode)}
                                    />
                                </div>
                            )}
                        </div>
                    </Form.Item> */}
                </Col>
            </Row>
        </div>
    );
}
