import React from 'react';
import { Button, Modal, message } from 'antd';

import {
    GET_ENTERPRISE_DETAIL,
    GET_PROVINCES,
    GET_DISTRICTS,
    GET_MUNICIPALITIES,
    axios,
} from '../../../utils/axios';
import {
    DLT_KEYS_FROM_OBJ,
    JSON_TO_FORM_DATA,
    ON_FILE_CHANGE,
    UPDATE_AXIOS_AUTH,
    NEP_TO_ENG_ISO,
} from '../../../utils/functions';

import OfficeDetailsForm from '../../../components/OfficeDetails/OfficeDetailsForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function OfficeDetails() {
    const [provinces, setProvinces] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [municipalities, setMunicipalities] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get provinces
    const getProvinces = () => {
        (async () => {
            const provinces = await GET_PROVINCES();
            setProvinces(provinces);
        })();
    };

    // Get districts
    const getDistricts = province => {
        // alert('test');
        (async () => {
            const districts = await GET_DISTRICTS(province);
            setDistricts(districts);
        })();
    };

    // Get municipalities
    const getMunicipalities = district => {
        (async () => {
            const municipalities = await GET_MUNICIPALITIES(district);
            console.log('municipalities: ', municipalities);
            setMunicipalities(municipalities);
        })();
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            const payload = JSON_TO_FORM_DATA(editValues);
            axios
                .put(
                    `${process.env.REACT_APP_HOST}/api/enterpriseDetail/UpdateDetail`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    const data = res.data.data;

                    setViewValues(data);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    const detailUpdated = {
                        ...JSON.parse(
                            localStorage.getItem('ENTERPRISE_DETAIL')
                        ),
                        ...data,
                    };
                    localStorage.setItem(
                        'ENTERPRISE_DETAIL',
                        JSON.stringify(detailUpdated)
                    );
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update enterprise detail');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        const updateValues = valObj => {
            if (mode === 'view') {
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            } else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (
            val.hasOwnProperty('provinceName') ||
            val.hasOwnProperty('provinceNepali')
        ) {
            const province = provinces.find(
                province => province[key] === val[key]
            );

            getDistricts(province.provinceName);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'districtName',
                    'districtNepali',
                    'municipalName',
                    'municipalNepali',
                ]),
                ...province,
            });
        } else if (
            val.hasOwnProperty('districtName') ||
            val.hasOwnProperty('districtNepali')
        ) {
            const district = districts.find(
                district => district[key] === val[key]
            );
            getMunicipalities(district.districtName);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'municipalName',
                    'municipalNepali',
                ]),
                ...district,
            });
        } else if (
            val.hasOwnProperty('municipalName') ||
            val.hasOwnProperty('municipalNepali')
        ) {
            const mun = municipalities.find(mun => mun[key] === val[key]);

            return updateValues(mun);
        } else if (val.hasOwnProperty('estd')) {
            return updateValues({
                [key]: NEP_TO_ENG_ISO(val.estd),
            });
        }
        updateValues(val);
    };

    // On file change
    const onFileChange = (e, mode) => {
        ON_FILE_CHANGE(e, mode, setEditValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        provinces,
        getProvinces,
        districts,
        getDistricts,
        municipalities,
        getMunicipalities,
        onFileChange,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        (async () => {
            const enterpriseDetail = await GET_ENTERPRISE_DETAIL();
            setViewValues(enterpriseDetail);
        })();
    }, []);
    return (
        <div className="office-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">OFFICE DETAILS</h3>
                <OfficeDetailsForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                />
                {/* Edit account modal */}
                <Modal
                    title="Edit account"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    onOk={onEditModalOk}
                    onCancel={onEditModalCancel}
                    okText="Update"
                    cancelButtonProps={{
                        disabled: editModal.confirmLoading,
                    }}
                    destroyOnClose
                    maskClosable={false}
                    width={1000}
                >
                    <OfficeDetailsForm
                        {...formProps}
                        mode="edit"
                        values={editValues}
                    />
                </Modal>
                {/* Actions bar */}
                <BottomActionBarFixed>
                    <Button type="primary" onClick={onEditModalOpen}>
                        Edit
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
