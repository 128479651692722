import { atom } from 'recoil';

// C.O.M.M.O.N
// User credentials
export const userCredentials = atom({
    key: 'userCredentials',
    default: null,
});

export const branches = atom({
    key: 'branches',
    default: [],
});

// Verification status
export const verificationStatus = atom({
    key: 'verificationStatus',
    default: '',
});

// Enterprise detail
export const enterpriseDetail = atom({
    key: 'enterpriseDetail',
    default: null,
});

// DEPOSIT TYPES ACCOUNT
// Accounts
export const depositTypesAccounts = atom({
    key: 'depositTypesAccounts',
    default: [],
});

// PAYMENT SOURCE
// Cash counters
export const cashCounters = atom({
    key: 'cashCounters',
    default: [],
});
// Banks
export const banks = atom({
    key: 'banks',
    default: [],
});
// Other accounts
export const otherAccounts = atom({
    key: 'otherAccounts',
    default: [],
});
// Other items
export const otherItems = atom({
    key: 'otherItems',
    default: [],
});

// REMITTANCE
export const remits = atom({
    key: 'remits',
    default: [],
});
