import React from 'react';
// Import from dependencies
import { Form, Input, Row, Col, Select, Radio } from 'antd';

export default function MemberRegistrationStep03(props) {
    return (
        <>
            <h2 className="member-registration-step__header">{props.header}</h2>
            <div className="member-registration-step__form">
                <Form layout="vertical">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="VDC/Municipality">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Kathmandu</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Ward No.">
                                <Input placeholder="Ward No." />
                            </Form.Item>
                            <Form.Item label="Tole">
                                <Input placeholder="Tole" />
                            </Form.Item>
                            <Form.Item label="District">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Kathmandu</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Telephone No.">
                                <Input placeholder="Telephone No." />
                            </Form.Item>
                            <Form.Item>
                                {/* <Radio.Group onChange={onChange} value={value}> */}
                                <Radio.Group style={{ marginBottom: '8px' }}>
                                    <Radio value={1}>Husband</Radio>
                                    <Radio value={2}>Wife</Radio>
                                </Radio.Group>
                                <Input placeholder="Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Mobile No.">
                                <Input placeholder="Mobile No." />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="Temporary address">
                                <Input placeholder="Temporary address" />
                            </Form.Item>
                            <Form.Item>
                                {/* <Radio.Group onChange={onChange} value={value}> */}
                                <Radio.Group style={{ marginBottom: '8px' }}>
                                    <Radio value={1}>Father</Radio>
                                    <Radio value={2}>Husband</Radio>
                                    <Radio value={3}>Head</Radio>
                                </Radio.Group>
                                <Input placeholder="Full Name" />
                            </Form.Item>
                            <Form.Item>
                                {/* <Radio.Group onChange={onChange} value={value}> */}
                                <Radio.Group style={{ marginBottom: '8px' }}>
                                    <Radio value={1}>Grandfather</Radio>
                                    <Radio value={2}>Father-in-law</Radio>
                                </Radio.Group>
                                <Input placeholder="Full Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}
