import React from 'react';
import { Row, Col } from 'antd';
import PhotoUpload from '../../../Common/PhotoUpload/PhotoUpload';

export default function DocumentsForm(props) {
    // Destructuring
    const { mode, onFileChange, values } = props;
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <PhotoUpload
                        mode={mode}
                        label="Signature"
                        onFileChange={onFileChange}
                        inputName="sign"
                        photoBinary={values?.sign}
                        photoUrl={values?.signLoc}
                    />
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <PhotoUpload
                        mode={mode}
                        label="Citizenship"
                        onFileChange={onFileChange}
                        inputName="citizen"
                        photoBinary={values?.citizen}
                        photoUrl={values?.citizenLoc}
                    />
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <PhotoUpload
                        mode={mode}
                        label="Passport"
                        onFileChange={onFileChange}
                        inputName="passport"
                        photoBinary={values?.passport}
                        photoUrl={values?.passportLoc}
                    />
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <PhotoUpload
                        mode={mode}
                        label="License"
                        onFileChange={onFileChange}
                        inputName="license"
                        photoBinary={values?.license}
                        photoUrl={values?.licenseLoc}
                    />
                </Col>
            </Row>
        </div>
    );
}
