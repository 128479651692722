import React from 'react';

import { Form, Select, Input, Row, Col, Radio, Avatar } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { UserOutlined } from '@ant-design/icons';
import {
    GET_MEMBERS_BY_BRANCH,
    GET_STAFFS_BY_BRANCH,
} from '../../../utils/axios';
import { CONTAINS_DIGITS_ONLY } from '../../../utils/functions';
import Branches from '../../Common/Geography/Branches/Branches';
import ActiveDepositTypeAccounts from '../../Common/Account/ActiveDepositTypeAccounts/ActiveDepositTypeAccounts';

export default function DepositRegistrationForm1(props) {
    // Destructuring
    const {
        mode,
        members,
        setMembers,
        values,
        activeDepositTypeAccounts,
        setStaffs,
        accNoValidationStatus,
        savingTypeAccounts,
    } = props;

    // On component amount
    React.useEffect(() => {
        if (mode !== 'view')
            (async () => {
                const staffs = await GET_STAFFS_BY_BRANCH(
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                );
                setStaffs(staffs);
                const members = await GET_MEMBERS_BY_BRANCH(
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                );
                setMembers(members);
            })();
    }, [setMembers, mode, setStaffs]);
    return (
        <div>
            <Row gutter={20}>
                <Col span={20}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8}>
                            <ActiveDepositTypeAccounts
                                accounts={activeDepositTypeAccounts}
                                item="acName"
                                required
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <ActiveDepositTypeAccounts
                                accounts={activeDepositTypeAccounts}
                                item="acno"
                                required
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Branches item="branchName" />
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Branches item="branchID" />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label=" Account Number"
                                name="accountNo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                                validateStatus={accNoValidationStatus}
                                hasFeedback={
                                    mode === 'add' && values?.accountNo
                                }
                                help={
                                    accNoValidationStatus === 'error'
                                        ? 'Already taken'
                                        : null
                                }
                            >
                                {mode === 'add' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {savingTypeAccounts.length &&
                                            savingTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.accountNo}
                                                >
                                                    {acc.accountNo}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={14}></Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="Entrance Date"
                                name="entranceDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Avatar
                        shape="square"
                        size={90}
                        src={`${process.env.REACT_APP_STATIC_FILES}/${values?.photoLoc}`}
                        icon={<UserOutlined />}
                    />
                </Col>
            </Row>

            <Row gutter={20}>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Member No"
                        name="memberNo"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberNO}
                                    >
                                        {member.memberNO}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Member Name"
                        name="operator1"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberName}
                                    >
                                        {member.memberName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Address"
                        name="opAddress1"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Mobile No "
                        name="mobileNO1"
                        rules={[
                            {
                                len: 10,
                                message: 'Should be 10 digits',
                            },
                            () => ({
                                validator(_, value) {
                                    if (CONTAINS_DIGITS_ONLY(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item label="Is sign compulsory" name="op1Sign">
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
