import React from 'react';

export default function DashboardStat({ stat }) {
	return (
		<div className="dashboard-stat">
			<div className="dashboard-stat__icon-wrapper">
				<div className="dashboard-stat__icon">{stat.icon}</div>
			</div>
			<div className="dashboard-stat__data">
				<div className="dashboard-stat__unit">{stat.unit}</div>
				<div className="dashboard-stat__title">{stat.title}</div>
			</div>
		</div>
	);
}
