import React from 'react';
// Import from dependencies
import { Form, Input, Button, message, Modal } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
    branches as branchesAtom,
    verificationStatus as verificationStatusAtom,
} from '../../../recoil/atoms';
// Import from utils
import { USER_ACCESS, USER_CREDENTIALS } from '../../../utils/localStorage';
import { UPDATE_AXIOS_AUTH } from '../../../utils/functions';
// Import components
import LoginBranches from '../LoginBranches/LoginBranches';
import Logo from '../../Common/Logo/Logo';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';

export default function LoginRight() {
    // Global states
    const setVerificationStatus = useSetRecoilState(verificationStatusAtom);
    const branches = useRecoilValue(branchesAtom);
    // Local states
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const [isChangingPassword, setIsChangingPassword] = React.useState(false);
    const [changePwdModal, setChangePwdModal] = React.useState({
        values: null,
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const loginFormRef = React.useRef();

    // Handle login
    const handleLogin = values => {
        setIsLoggingIn(true);
        axios
            .post(`${process.env.REACT_APP_HOST}/api/login/token`, values)
            .then(res => {
                const data = res.data.data;

                if (res.data.statusCode !== 0) {
                    setIsLoggingIn(false);
                    message.error('Something went wrong. Please try again!');
                    return;
                }

                if (data.isNewUser) {
                    loginFormRef.current.resetFields();
                    setIsLoggingIn(false);
                    setChangePwdModal({
                        values: {
                            ...values,
                            ...data,
                        },
                        visible: true,
                    });
                } else {
                    // Plant user credentials in local storage
                    const branch = branches.find(
                        branch => branch.branchID === values.branchID
                    );
                    const userCredentials = {
                        ...values,
                        userID: res.data.data.userID,
                        ...branch,
                    };
                    delete userCredentials.password;
                    localStorage.setItem(
                        USER_CREDENTIALS,
                        JSON.stringify(userCredentials)
                    );
                    // Plan JWT Token in Local storage
                    localStorage.setItem(USER_ACCESS, res.data.data.token);
                    UPDATE_AXIOS_AUTH(res.data.data.token);
                    // Set verification status
                    setVerificationStatus('authorized');
                }
            })
            .catch(err => {
                console.error(err, 'Failed to login');
                message.error('Something went wrong');
                setIsLoggingIn(false);
            });
    };

    // Handle password change
    const handlePasswordChange = val => {
        console.log(val);
        console.log(changePwdModal.values);
        setIsChangingPassword(true);
        const values = {
            userName: changePwdModal.values.userName,
            confirmpassword: changePwdModal.values.password,
            password: val.password,
            user: changePwdModal.values.userName,
            branchID: changePwdModal.values.branchID,
        };
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/Users/ChangePasssword`,
                values,
                {
                    headers: {
                        Authorization: `Bearer ${changePwdModal.values.token}`,
                    },
                }
            )
            .then(res => {
                console.log('res: ', res);

                if (res.data.statusCode !== 0) {
                    message.error('Something went wrong. Please try again!');
                }
                setChangePwdModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success(
                    'Your password has been changed successfully. Please login using your new password.'
                );
            })
            .catch(err => {
                console.error(err, 'Failed to change password');
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsChangingPassword(false);
                setChangePwdModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    return (
        <div className="login-right">
            <div className="login-right__logo">
                <Logo />
            </div>
            <h1 className="login-right__heading">Login</h1>
            <Form
                ref={loginFormRef}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Username"
                    name="userName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <LoginBranches />
                <Form.Item style={{ marginBottom: '0' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="ant-btn--fluid"
                        loading={isLoggingIn}
                        disabled={isLoggingIn}
                    >
                        Login
                    </Button>
                </Form.Item>
            </Form>
            <div className="login-right__register">
                <Link to="/auth/register">Register</Link>
            </div>
            {/* Change password modal */}
            <Modal
                title="Change password"
                visible={changePwdModal.visible}
                okText="Change password"
                footer={false}
                destroyOnClose
                maskClosable={false}
            >
                <p className="color-error" style={{ marginBottom: '12px' }}>
                    Please change your password before you continue to use the
                    application
                </p>
                <ChangePasswordForm
                    handlePasswordChange={handlePasswordChange}
                    changePwdModal={changePwdModal}
                    isChangingPassword={isChangingPassword}
                />
            </Modal>
        </div>
    );
}
