import React from 'react';
// Import components
import DailyCollectionForm from '../../components/DailyCollection/DailyCollectionForm/DailyCollectionForm';
import DailyCollectionTable from '../../components/DailyCollection/DailyCollectionTable/DailyCollectionTable';

export default function DailyCollection() {
	return (
		<div className="daily-collection">
			<DailyCollectionForm />
			<DailyCollectionTable />
		</div>
	);
}
