import React from 'react';
// Import from dependencies
import { Table } from 'antd';

export default function ReceiptEntryTable() {
	return (
		<div className="receipt-entry-table">
			<Table columns={columns} dataSource={data} />
		</div>
	);
}

const columns = [
	{
		title: 'A/C No.',
		dataIndex: 'acc_no',
		key: 'acc_no',
		render: text => text,
	},
	{
		title: 'Account holder',
		dataIndex: 'acc_holder',
		key: 'acc_holder',
		render: text => text,
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
		render: text => text,
	},
	{
		title: 'Collected Rs.',
		dataIndex: 'collected_rs',
		key: 'collected_rs',
		render: text => text,
	},
	{
		title: 'Prev. Balance',
		dataIndex: 'prev_balance',
		key: 'prev_balance',
		render: text => text,
	},
	{
		title: 'Final. Balance',
		dataIndex: 'final_balance',
		key: 'final_balance',
		render: text => text,
	},
	{
		title: 'Remarks',
		dataIndex: 'remarks',
		key: 'remarks',
		render: text => text,
	},
	{
		title: 'Last Rem.',
		dataIndex: 'last_rem',
		key: 'last_rem',
		render: text => text,
	},
];

const data = [
	{
		key: '1',
		acc_no: '001',
		acc_holder: 'John Brown',
		collected_rs: 3000,
		prev_balance: 8900,
		final_balance: 9200,
		remarks: '-',
		last_rem: '-',
	},
	{
		key: '1',
		acc_no: '001',
		acc_holder: 'Sally Green',
		collected_rs: 3000,
		prev_balance: 8900,
		final_balance: 9200,
		remarks: '-',
		last_rem: '-',
	},
	{
		key: '1',
		acc_no: '001',
		acc_holder: 'Joe Blue',
		collected_rs: 3000,
		prev_balance: 8900,
		final_balance: 9200,
		remarks: '-',
		last_rem: '-',
	},
	{
		key: '1',
		acc_no: '001',
		acc_holder: 'Alex Red',
		collected_rs: 3000,
		prev_balance: 8900,
		final_balance: 9200,
		remarks: '-',
		last_rem: '-',
	},
];
