import React from 'react';
import { Form, Select } from 'antd';

export default function VdcMun(props) {
    return (
        <Form.Item
            label={
                props.item === 'municipalName'
                    ? 'VDC/Municipality'
                    : 'गाउपालिका/नगरपालिका'
            }
            name={props.name || props.item}
        >
            <Select
                placeholder="Select..."
                showSearch
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {props.municipalities.map((mun, i) => (
                    <Select.Option key={i} value={mun[props.item]}>
                        {mun[props.item]}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
