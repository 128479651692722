import React from 'react';
import { Row, Col, Form, Tabs } from 'antd';
import { GET_END_DATE } from '../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../utils/functions';
import DepositRegistrationForm0 from './DepositRegistrationForm0/DepositRegistrationForm0';

const DepositRegistrationForm = React.forwardRef((props, ref) => {
    const {
        values,
        onValuesChange,
        mode,
        setDefaultValues,
        setEndDate,
        activePanelKey,
        onPanelChange,
        tabPanes,
    } = props;

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    // Set end date
    React.useEffect(() => {
        if (mode !== 'view') {
            if (
                values?.startDate &&
                values?.savingPeriod &&
                values?.periodType
            ) {
                const startDate = values.startDate;
                const savingPeriod = values.savingPeriod;
                const periodType = values.periodType;

                const payload = {
                    dmy: periodType,
                    startDate: startDate,
                    interval: +savingPeriod,
                };
                console.log('payload: ', payload);

                (async () => {
                    const endDate = await GET_END_DATE(payload);
                    console.log('endDate: ', endDate);
                    setEndDate(endDate, mode);
                })();
            }
        }
    }, [
        mode,
        values?.startDate,
        values?.savingPeriod,
        values?.periodType,
        setEndDate,
    ]);

    return (
        <div>
            <Row gutter={20}>
                <Col span={24}>
                    <div className="block-general-titled--body">
                        <Form
                            ref={ref}
                            layout="vertical"
                            className="margin-top"
                            onValuesChange={val => onValuesChange(val, mode)}
                            fields={[
                                { name: 'branchID', value: values?.branchID },
                                {
                                    name: 'branchName',
                                    value: values?.branchName,
                                },
                                {
                                    name: 'userName',
                                    value: values?.userName,
                                },
                                {
                                    name: 'accountNo',
                                    value: values?.accountNo,
                                },
                                {
                                    name: 'entranceDate',
                                    value:
                                        values?.entranceDate &&
                                        ENG_ISO_TO_NEP(values.entranceDate),
                                },
                                {
                                    name: 'aloneOrCombine',
                                    value: values?.aloneOrCombine,
                                },
                                {
                                    name: 'memberNo',
                                    value: values?.memberNo,
                                },
                                {
                                    name: 'memberNo2',
                                    value: values?.memberNo2,
                                },
                                {
                                    name: 'memberNo3',
                                    value: values?.memberNo3,
                                },
                                {
                                    name: 'memberNo4',
                                    value: values?.memberNo4,
                                },
                                {
                                    name: 'operator1',
                                    value: values?.operator1,
                                },
                                {
                                    name: 'operator2',
                                    value: values?.operator2,
                                },
                                {
                                    name: 'operator3',
                                    value: values?.operator3,
                                },
                                {
                                    name: 'operator4',
                                    value: values?.operator4,
                                },
                                {
                                    name: 'opAddress1',
                                    value: values?.opAddress1,
                                },
                                {
                                    name: 'opAddress2',
                                    value: values?.opAddress2,
                                },
                                {
                                    name: 'opAddress3',
                                    value: values?.opAddress3,
                                },
                                {
                                    name: 'opAddress4',
                                    value: values?.opAddress4,
                                },
                                {
                                    name: 'mobileNO1',
                                    value: values?.mobileNO1,
                                },
                                {
                                    name: 'mobileNO2',
                                    value: values?.mobileNO2,
                                },
                                {
                                    name: 'mobileNO3',
                                    value: values?.mobileNO3,
                                },
                                {
                                    name: 'mobileNO4',
                                    value: values?.mobileNO4,
                                },
                                {
                                    name: 'op1Sign',
                                    value: values?.op1Sign,
                                },
                                {
                                    name: 'op2Sign',
                                    value: values?.op2Sign,
                                },
                                {
                                    name: 'op3Sign',
                                    value: values?.op3Sign,
                                },
                                {
                                    name: 'op4Sign',
                                    value: values?.op4Sign,
                                },
                                {
                                    name: 'noofSignRequired',
                                    value: values?.noofSignRequired,
                                },
                                {
                                    name: 'photoLoc',
                                    value: values?.photoLoc,
                                },
                                {
                                    name: 'fullName',
                                    value: values?.fullName,
                                },
                                {
                                    name: 'isOwner',
                                    value: values?.isOwner,
                                },
                                {
                                    name: 'ownerNO',
                                    value: values?.ownerNO,
                                },
                                {
                                    name: 'ownerName',
                                    value: values?.ownerName,
                                },
                                {
                                    name: 'ownerFullAddress',
                                    value: values?.ownerFullAddress,
                                },
                                {
                                    name: 'ownerMobileNO',
                                    value: values?.ownerMobileNO,
                                },
                                {
                                    name: 'staffName',
                                    value: values?.staffName,
                                },
                                {
                                    name: 'staffID',
                                    value: values?.staffID,
                                },
                                {
                                    name: 'savingRate',
                                    value: values?.savingRate + '',
                                },
                                {
                                    name: 'SavingPeriod',
                                    value: values?.SavingPeriod,
                                },
                                {
                                    name: 'PeriodType',
                                    value: values?.PeriodType,
                                },
                                {
                                    name: 'startDate',
                                    value:
                                        values?.startDate &&
                                        ENG_ISO_TO_NEP(values.startDate),
                                },
                                {
                                    name: 'expireDate',
                                    value:
                                        values?.expireDate &&
                                        ENG_ISO_TO_NEP(values.expireDate),
                                },
                                {
                                    name: 'intColDepType',
                                    value: values?.intColDepType,
                                },
                                {
                                    name: 'intColDepTypeName',
                                    value: values?.intColDepTypeName,
                                },
                                {
                                    name: 'transferInterestTo',
                                    value: values?.transferInterestTo,
                                },
                                {
                                    name: 'transferInterestToName',
                                    value: values?.transferInterestToName,
                                },
                                {
                                    name: 'lastModifiedby',
                                    value: values?.lastModifiedby,
                                },
                                {
                                    name: 'interestRate',
                                    value: values?.interestRate,
                                },
                                {
                                    name: 'interestStartDAte',
                                    value:
                                        values?.interestStartDAte &&
                                        ENG_ISO_TO_NEP(
                                            values.interestStartDAte
                                        ),
                                },
                                {
                                    name: 'odFacility',
                                    value: values?.odFacility,
                                },
                                {
                                    name: 'oDlimit',
                                    value: values?.oDlimit,
                                },
                                {
                                    name: 'odIntrate',
                                    value: values?.odIntrate,
                                },
                                {
                                    name: 'odIntStartDate',
                                    value: values?.odIntStartDate,
                                },
                                {
                                    name: 'smsFacility',
                                    value: values?.smsFacility,
                                },
                                {
                                    name: 'mobileNO',
                                    value: values?.mobileNO,
                                },
                                {
                                    name: 'minimumBalance',
                                    value: values?.minimumBalance,
                                },
                                {
                                    name: 'disabled',
                                    value: values?.disabled,
                                },
                                {
                                    name: 'locked',
                                    value: values?.locked,
                                },
                                {
                                    name: 'lockedAmount',
                                    value: values?.lockedAmount,
                                },
                                {
                                    name: 'confidential',
                                    value: values?.confidential,
                                },
                                {
                                    name: 'dormant',
                                    value: values?.dormant,
                                },
                                {
                                    name: 'acno',
                                    value: values?.acno,
                                },
                                {
                                    name: 'acName',
                                    value: values?.acName,
                                },
                                {
                                    name: 'periodType',
                                    value: values?.periodType,
                                },
                                {
                                    name: 'savingPeriod',
                                    value: values?.savingPeriod,
                                },
                                {
                                    name: 'intTransPeriod',
                                    value: values?.intTransPeriod,
                                },
                            ]}
                        >
                            <DepositRegistrationForm0 {...props} />
                            <Tabs
                                activeKey={activePanelKey}
                                onChange={onPanelChange}
                            >
                                {tabPanes.map((pane, i) => {
                                    return (
                                        <Tabs.TabPane tab={pane.title} key={i}>
                                            {React.cloneElement(
                                                pane.component,
                                                {
                                                    ...props,
                                                }
                                            )}
                                        </Tabs.TabPane>
                                    );
                                })}
                            </Tabs>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
});

export default DepositRegistrationForm;
