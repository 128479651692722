import React from 'react';
// Import from dependencies
import { Button, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
// Import utils
import axios, {
    GET_USERS,
    GET_USER_DETAIL,
    GET_COUNTERS_BY_BRANCH,
} from '../../../utils/axios';
import { SET_DEFAULT_VALUES } from '../../../utils/functions';
// Import components
import CounterAssignmentForm from '../../../components/Counter/CounterAssignment/CounterAssignmentForm/CounterAssignmentForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function CounterAssignment() {
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [users, setUsers] = React.useState([]);
    const [assignedCounters, setAssignedCounters] = React.useState(null);
    const [countersPayload, setCountersPayload] = React.useState(null);
    const [branchCounters, setBranchCounters] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            counterBranchID: JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            undefined
        );
    }, []);

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // let values;
        // if (mode === 'view') values = viewValues;
        // else if (mode === 'edit') values = editValues;
        // else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'userName') {
            const user = users.find(user => user.userName === value);
            getUserDetail(user.userID);
            getAssignedCounters(value);
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            return updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // On edit account button click
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setCountersPayload(assignedCounters);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On Edit account modal OK
    const onEditModalOk = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        const payload = {
            ...countersPayload,
            assignedBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
        };
        axios
            .put(
                `${process.env.REACT_APP_HOST}/api/Counters/AssignCounter/${viewValues.userName}`,
                payload
            )
            .then(res => {
                setAssignedCounters(res.data.data);
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Counters updated');
            })
            .catch(err => {
                console.error(err, 'Failed to edit counters');
                message.error('Failed to update counters');
            })
            .finally(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // Get user detail
    const getUserDetail = async userID => {
        const userDetail = await GET_USER_DETAIL(userID);
        setViewValues(userDetail);
    };

    // Get assigned counters
    const getAssignedCounters = async userName => {
        setAssignedCounters(null);
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_HOST}/api/Counters/GetAssignedCounter/${userName}`
            );
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            setAssignedCounters(res.data.data);
        } catch (err) {
            console.error(err, 'Failed to get assigned counters');
            message.error('Something went wrong. Please try again!');
        }
    };

    // Remove counter
    const removeCounter = counterNO => {
        const countersUpdated = {
            ...countersPayload,
            counters: [...countersPayload.counters].filter(
                counter => counter.counterNO !== counterNO
            ),
        };
        setCountersPayload(countersUpdated);
    };

    // Add counter
    const addCounter = counterNO => {
        const counter = branchCounters.find(
            counter => counter.counterNO === counterNO
        );
        const countersUpdated = countersPayload
            ? {
                  ...countersPayload,
                  counters: [...countersPayload.counters, counter],
              }
            : {
                  ...viewValues,
                  counters: [counter],
              };
        setCountersPayload(countersUpdated);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        users,
        assignedCounters: assignedCounters?.counters,
        countersPayload: countersPayload?.counters,
        removeCounter,
        addCounter,
        branchCounters,
        setDefaultValues,
    };

    // Get users
    React.useState(() => {
        // Get users
        (async () => {
            const users = await GET_USERS();
            setUsers(users);
        })();
    }, []);

    // Get counters by branch
    React.useEffect(() => {
        editValues?.counterBranchID &&
            (async () => {
                const counters = await GET_COUNTERS_BY_BRANCH(
                    editValues.counterBranchID
                );
                setBranchCounters(counters);
            })();
    }, [editValues?.counterBranchID]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, [setDefaultValues]);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            Counter assignment
                        </h4>
                        <CounterAssignmentForm
                            {...formProps}
                            mode="view"
                            values={viewValues}
                        />
                        <BottomActionBarFixed>
                            <Link
                                to="/counter-registration"
                                className="ant-btn"
                            >
                                Create New Counter
                            </Link>
                            <Button
                                type="primary"
                                onClick={onEditModalOpen}
                                disabled={!viewValues?.userName}
                            >
                                Edit
                            </Button>
                        </BottomActionBarFixed>
                    </div>
                </div>
            </div>
            {/* Edit account modal */}
            <Modal
                title="Assign counters"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                okText="Update"
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CounterAssignmentForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
        </>
    );
}
