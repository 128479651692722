import React from 'react';
// Import from dependencies
import { Button } from 'antd';
// Import components
import ReceiptEntryTable from '../../ReceiptEntry/ReceiptEntryTable/ReceiptEntryTable';

export default function DailyCollectionTable() {
    const actions = [
        'Fill All Items',
        'Fill Nepali',
        'Clear Items',
        'Add Rows',
        'Print Collection Sheet',
        'Export to Excel',
    ];
    return (
        <div className="daily-collection-table">
            <h4 className="daily-collection-table__heading">
                Collection Details
            </h4>
            <div className="daily-collection-table__actions">
                {actions.map((action, i) => (
                    <Button key={i} type="default" size="small">
                        {action}
                    </Button>
                ))}
            </div>
            <ReceiptEntryTable />
        </div>
    );
}
