import React from 'react';
import { Row, Col, Form, Select, Input, Space, Button } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Branches from '../../../Common/Geography/Branches/Branches';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

export default function RemitPaymentForm(props) {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        members,
        onFormSubmit,
        isProcessing,
        handleNew,
        remits,
    } = props;
    // Global states

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <div>
            <Form
                layout="horizontal"
                className="margin-top"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onValuesChange={val => onValuesChange(val, mode)}
                onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'transDate',
                        value:
                            values?.transDate &&
                            ENG_ISO_TO_NEP(values?.transDate),
                    },
                    {
                        name: 'remitID',
                        value: values?.remitID,
                    },
                    {
                        name: 'remitName',
                        value: values?.remitName,
                    },
                    {
                        name: 'description',
                        value: values?.description,
                    },
                    {
                        name: 'cashNo',
                        value: values?.cashNo,
                    },
                    {
                        name: 'cashName',
                        value: values?.cashName,
                    },
                    {
                        name: 'bankNo',
                        value: values?.bankNo,
                    },
                    {
                        name: 'bankName',
                        value: values?.bankName,
                    },
                    {
                        name: 'otherAcno',
                        value: values?.otherAcno,
                    },
                    {
                        name: 'otherAcName',
                        value: values?.otherAcName,
                    },
                    {
                        name: 'otherNo',
                        value: values?.otherNo,
                    },
                    {
                        name: 'otherName',
                        value: values?.otherName,
                    },
                    {
                        name: 'oldBalance',
                        value: values?.oldBalance,
                    },
                    {
                        name: 'preTotal',
                        value: values?.preTotal,
                    },
                    {
                        name: 'isCash',
                        value: values?.isCash,
                    },
                    {
                        name: 'cashAmount',
                        value: values?.cashAmount,
                    },
                    {
                        name: 'isBank',
                        value: values?.isBank,
                    },
                    {
                        name: 'bankAmount',
                        value: values?.bankAmount,
                    },
                    {
                        name: 'isOther',
                        value: values?.isOther,
                    },
                    {
                        name: 'otherAmount',
                        value: values?.otherAmount,
                    },
                    {
                        name: 'totalAmount',
                        value: values?.totalAmount,
                    },
                    {
                        name: 'totalAmountInWords',
                        value: values?.totalAmountInWords,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'receiptNo',
                        value: values?.receiptNo,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'isClearance',
                        value: values?.isClearance,
                    },
                    {
                        name: 'senderName',
                        value: values?.senderName,
                    },
                    {
                        name: 'receiverName',
                        value: values?.receiverName,
                    },
                    {
                        name: 'remitCode',
                        value: values?.remitCode,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Remit ID"
                                name="remitID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select placeholder="Select...">
                                    {remits.length &&
                                        remits.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.remitID}
                                            >
                                                {item.remitID}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item
                                label="Date"
                                name="transDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Remit Name"
                                name="remitName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select placeholder="Select...">
                                    {remits.length &&
                                        remits.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.remitName}
                                            >
                                                {item.remitName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}></Col>
                        <Col span={6}>
                            <Form.Item label="Receipt No." name="receiptNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Remit Code" name="remitCode">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10}></Col>
                        <Col span={6}>
                            <Form.Item label="Journal No." name="journalNO">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Sender's Name" name="senderName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="Trans No." name="transNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Receiver"
                                name="receiverName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10}></Col>
                        <Col span={6}>
                            <Form.Item name="oldBalance" label="Remit Balance">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="Mobile No" name="mobileNo">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Form.Item
                                label="Sending Amount"
                                name="preTotal"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                                labelCol={{ span: 12 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item label="Description" name="description">
                            <Input />
                        </Form.Item>
                    </Row>
                    <PaymentSource
                        title="Receipt Source"
                        mode="receipt"
                        values={values}
                    />
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        htmlType="submit"
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
