import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import { useRecoilValue } from 'recoil';
import { depositTypesAccounts as depositTypesAccountsAtom } from '../../../recoil/atoms';

export default function DepositTypesGeneralDetail(props) {
    // Global states
    const accounts = useRecoilValue(depositTypesAccountsAtom);
    // Destructuring
    const { values, mode } = props;

    // Set data display element types based on form mode (view|edit|add)
    let acnoInput, acNameInput, nepNameInput, typeCodeInput, govCodeInput;
    switch (mode) {
        case 'view':
            acnoInput = (
                <Select
                    placeholder="Select..."
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {accounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.acno}>
                            {acc.acno}
                        </Select.Option>
                    ))}
                </Select>
            );
            acNameInput = (
                <Select
                    placeholder="Select..."
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {accounts.map((acc, i) => (
                        <Select.Option key={i} value={acc.acName}>
                            {acc.acName}
                        </Select.Option>
                    ))}
                </Select>
            );
            nepNameInput = <p>{values?.nepName || '--'}</p>;
            typeCodeInput = <p>{values?.typeCode || '--'}</p>;
            govCodeInput = <p>{values?.govCode || '--'}</p>;
            break;
        case 'edit':
            acnoInput = <p>{values?.acno}</p>;
            acNameInput = <p>{values?.acName}</p>;
            nepNameInput = <Input />;
            typeCodeInput = <Input />;
            govCodeInput = <Input />;
            break;
        case 'add':
            acnoInput = <p>{values?.acno || 'Loading ...'}</p>;
            acNameInput = <Input />;
            nepNameInput = <Input />;
            typeCodeInput = <Input />;
            govCodeInput = <Input />;
            break;
        default:
    }
    return (
        <div className="deposit-type-general-detail-form-view">
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Account No."
                        name="acno"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {acnoInput}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Saving Name"
                        name="acName"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                {
                                    max: 50,
                                    message: 'Max 50 characters',
                                },
                            ]
                        }
                    >
                        {acNameInput}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="बाचत नाम"
                        name="nepName"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {nepNameInput}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="A/C No Prefix"
                        name="typeCode"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {typeCodeInput}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Gov Code"
                        name="govCode"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {govCodeInput}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
