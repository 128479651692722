import React from 'react';
// Import from dependencies
import { Form, Input, Row, Col, Select, DatePicker } from 'antd';

export default function MemberRegistrationStep01(props) {
    return (
        <>
            <h2 className="member-registration-step__header">{props.header}</h2>
            <div className="member-registration-step__form">
                <Form layout="vertical">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Membership ID">
                                <Input placeholder="Membership ID" />
                            </Form.Item>
                            <Form.Item label="Comp. Saving ID">
                                <Input placeholder="Comp. Saving ID" />
                            </Form.Item>
                            <Form.Item label="Area">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Center</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Branch name">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Panauti</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Entrance Date">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="Shareholders No.">
                                <Input placeholder="Shareholders No." />
                            </Form.Item>
                            <Form.Item label="Group">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Amar Pun</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Branch ID">
                                <Input placeholder="Branch ID" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}
