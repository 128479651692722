import React from 'react';
// Import from dependencies
import { Steps, Button, message } from 'antd';
// Import components
import MemberRegistrationHeader from '../../components/MemberRegistration/MemberRegistrationHeader/MemberRegistrationHeader';
import MemberRegistrationStep01 from '../../components/MemberRegistration/MemberRegistrationStep01/MemberRegistrationStep01';
import MemberRegistrationStep02 from '../../components/MemberRegistration/MemberRegistrationStep02/MemberRegistrationStep02';
import MemberRegistrationStep03 from '../../components/MemberRegistration/MemberRegistrationStep03/MemberRegistrationStep03';
import MemberRegistrationStep04 from '../../components/MemberRegistration/MemberRegistrationStep04/MemberRegistrationStep04';

export default function MemberRegistration() {
    // Local states
    const [current, setCurrent] = React.useState(0);

    // Destructuring
    const { Step } = Steps;

    // Steps title
    const stepsTitle = [
        'Membership Details',
        'Personal Details',
        'Address',
        'Referenced by',
    ];

    // Steps
    const steps = [
        {
            title: stepsTitle[0],
            content: <MemberRegistrationStep01 header={stepsTitle[0]} />,
        },
        {
            title: stepsTitle[1],
            content: <MemberRegistrationStep02 header={stepsTitle[1]} />,
        },
        {
            title: stepsTitle[2],
            content: <MemberRegistrationStep03 header={stepsTitle[2]} />,
        },
        {
            title: stepsTitle[3],
            content: <MemberRegistrationStep04 header={stepsTitle[3]} />,
        },
    ];

    // Handle steps next
    const next = () => {
        setCurrent(current + 1);
    };

    // Handle steps prev
    const prev = () => {
        setCurrent(current - 1);
    };
    return (
        <div className="member-registration">
            <MemberRegistrationHeader />
            <div className="member-registration__steps">
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </div>

            <div className="member-registration__steps-body">
                <div className="steps-content">
                    <div className="member-registration-step">
                        <div className="container container--sm">
                            {steps[current].content}
                        </div>
                    </div>
                </div>
                <div className="steps-action container container--sm">
                    {current > 0 && (
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={() =>
                                message.success('Processing complete!')
                            }
                        >
                            Done
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
