import React from 'react';
import { Modal, Image, Button, Table, Spin, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';

export default function SearchMemberModal(props) {
    // Destructuring
    const { memberDetail, onCancel, memberDetailModal } = props;

    // Variables and constants
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const depositsTableColumns = [
        {
            title: 'Type No',
            dataIndex: 'acno',
            key: 'acno',
            width: '6rem',
        },
        {
            title: 'Deposit Type',
            dataIndex: 'acName',
            key: 'acName',
        },
        {
            title: 'Account No',
            dataIndex: 'accountNO',
            key: 'accountNO',
            width: '8rem',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: '6rem',
        },
        {
            title: 'Interest',
            dataIndex: 'interest',
            key: 'interest',
            width: '6rem',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '3rem',
            render: (_, data) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="0">Statement</Menu.Item>
                        <Menu.Item key="1">
                            <Link
                                to={{
                                    pathname: '/counter-deposit',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            >
                                Deposit
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link
                                to={{
                                    pathname: '/counter-withdraw',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            >
                                Withdraw
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Link
                                to={{
                                    pathname: '/closing-account',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            >
                                Close A/C
                            </Link>
                        </Menu.Item>
                    </Menu>
                );
                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="topLeft"
                    >
                        <div
                            className="ant-dropdown-link"
                            onClick={e => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </div>
                    </Dropdown>
                );
            },
        },
    ];
    const depositsTableData = memberDetail?.depsoitItems.map((item, i) => ({
        key: i,
        ...item,
    }));

    const loansTableColumns = [
        {
            title: 'Type No',
            dataIndex: 'acno',
            key: 'acno',
            width: '6rem',
        },
        {
            title: 'Loan Type',
            dataIndex: 'acName',
            key: 'acName',
        },
        {
            title: 'Loan No',
            dataIndex: 'loanno',
            key: 'loanno',
            width: '8rem',
        },
        {
            title: 'Balance',
            dataIndex: 'loanBalance',
            key: 'loanBalance',
            width: '6rem',
        },
        {
            title: 'Interest',
            dataIndex: 'interest',
            key: 'interest',
            width: '6rem',
        },
        {
            title: 'Penalty',
            dataIndex: 'penalty',
            key: 'penalty',
            width: '6rem',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '3rem',
            render: (_, data) => {
                console.log('data: ', data);
                const menu = (
                    <Menu>
                        <Menu.Item key="1">
                            <Link
                                to={{
                                    pathname: '/loan-granting',
                                    search: `acno=${data.acno}&acName=${data.acName}&loanNo=${data.loanno}`,
                                }}
                                onClick={onCancel}
                            >
                                Issue Loan
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            {/* <Link
                                to={{
                                    pathname: '/counter-withdraw',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            > */}
                            Loan Statement
                            {/* </Link> */}
                        </Menu.Item>
                        <Menu.Item key="3">
                            {/* <Link
                                to={{
                                    pathname: '/closing-account',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            > */}
                            Loan Recovery
                            {/* </Link> */}
                        </Menu.Item>
                        <Menu.Item key="4">
                            {/* <Link
                                to={{
                                    pathname: '/closing-account',
                                    search: `acno=${data.acno}&acName=${data.acName}&accountNo=${data.accountNO}`,
                                }}
                                onClick={onCancel}
                            > */}
                            Close Loan
                            {/* </Link> */}
                        </Menu.Item>
                    </Menu>
                );
                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="topLeft"
                    >
                        <div
                            className="ant-dropdown-link"
                            onClick={e => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </div>
                    </Dropdown>
                );
            },
        },
    ];
    const loansTableData = memberDetail?.loanItems.map((item, i) => ({
        key: i,
        ...item,
    }));

    return (
        <div className="search-member-modal">
            <Modal
                title="Member Detail"
                visible={memberDetailModal.visible}
                onOk={onCancel}
                onCancel={onCancel}
                confirmLoading={memberDetailModal.confirmLoading}
                width={800}
                cancelButtonProps={{ hidden: true }}
                okText="Close"
            >
                <div className="block-general-titled--body margin-top">
                    {memberDetail ? (
                        <>
                            <div className="search-member-modal__info">
                                <div className="search-member-modal__info-left">
                                    <h3 className="color-primary">
                                        {memberDetail.name}
                                    </h3>
                                    <p>{memberDetail.address}</p>
                                    <p>
                                        <strong>Member no: </strong>
                                        {memberDetail.memberNo}
                                    </p>
                                    {memberDetail.ownerNO && (
                                        <p>
                                            <strong>Owner no: </strong>
                                            {memberDetail.ownerNO}
                                        </p>
                                    )}
                                    <p>
                                        <strong>Father's name: </strong>
                                        {memberDetail.memberNo}
                                    </p>
                                    <p>
                                        <strong>Contact no: </strong>
                                        {memberDetail.mobileNo}
                                    </p>
                                    <p>
                                        <strong>Share: </strong>
                                        {memberDetail.shareBalance}
                                        <Button
                                            type="default"
                                            size="small"
                                            style={{ marginLeft: '.5rem' }}
                                        >
                                            Share Ledger
                                        </Button>
                                    </p>
                                </div>
                                <div className="search-member-modal__info-mid">
                                    <Image
                                        width={100}
                                        src={`${process.env.REACT_APP_STATIC_FILES}/${memberDetail.photoloc}`}
                                    />
                                </div>
                                <div className="search-member-modal__info-right">
                                    <Button type="default" size="small">
                                        Show Profile
                                    </Button>
                                    <Link
                                        to={{
                                            pathname: '/receipt-entry',
                                            search: `memberNo=${memberDetail.memberNo}`,
                                        }}
                                        className="ant-btn ant-btn-sm"
                                        onClick={onCancel}
                                    >
                                        Receipt Entry
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: memberDetail.ownerNO
                                                ? '/owner-details'
                                                : '/member-registration',
                                            search: `memberNo=${
                                                memberDetail.ownerNO ||
                                                memberDetail.memberNo
                                            }?edit=true`,
                                        }}
                                        className="ant-btn ant-btn-sm"
                                        onClick={onCancel}
                                    >
                                        Edit Profile
                                    </Link>
                                </div>
                            </div>
                            <div className="search-member-modal__table">
                                <div className="search-member-modal__table-heading">
                                    <p className="search-member-modal__table-title color-primary">
                                        <strong>Deposits</strong>
                                    </p>
                                    <div className="search-member-modal__table-action">
                                        <Button type="default" size="small">
                                            New Account
                                        </Button>
                                    </div>
                                </div>
                                <div className="search-member-modal__table-body">
                                    <Table
                                        className="ant-table-wrapper--collapsed"
                                        dataSource={depositsTableData}
                                        columns={depositsTableColumns}
                                        pagination={false}
                                        scroll={{ y: 200 }}
                                    />
                                </div>
                            </div>
                            <div className="search-member-modal__table">
                                <div className="search-member-modal__table-heading">
                                    <p className="search-member-modal__table-title color-primary">
                                        <strong>Loans</strong>
                                    </p>
                                    <div className="search-member-modal__table-action">
                                        <Button type="default" size="small">
                                            New Loan
                                        </Button>
                                    </div>
                                </div>
                                <div className="search-member-modal__table-body">
                                    <Table
                                        className="ant-table-wrapper--collapsed"
                                        dataSource={loansTableData}
                                        columns={loansTableColumns}
                                        pagination={false}
                                        scroll={{ y: 200 }}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="modal-spinner">
                            <Spin indicator={antIcon} />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
