import React from 'react';

export default function DashboardCompanyInfo() {
	return (
		<div className="dashboard-company-info">
			<div className="block-general mb-0">
				<h6 className="dashboard-company-info__heading">
					Oxpan Microsys Pvt Ltd
				</h6>
				<div className="dashboard-company-info__each">
					<p className="dashboard-company-info__each-heading">
						Head office:
					</p>
					<p>Minbhawan, Kathmandu</p>
				</div>
				<div className="dashboard-company-info__each">
					<p className="dashboard-company-info__each-heading">
						Contact:
					</p>
					<p>014622542, 9801906572, 9801906573</p>
					<p>Birtamod: 9852682940, 9842657940</p>
					<p>Itahari: 9849228967, 9862134389</p>
				</div>
			</div>
		</div>
	);
}
