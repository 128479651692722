import React from 'react';
// Import from dependencies
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import { cashCounters as cashCountersAtom } from '../../../../recoil/atoms';
// Import utils
import axios, {
    GET_BANKS,
    GET_ALL_CASH_ITEMS,
    GET_BANK_DETAIL,
    GET_ITEM_BALANCE,
} from '../../../../utils/axios';
import moment from 'moment';
import { ToWords } from 'to-words';
import {
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
} from '../../../../utils/functions';
// Import components
import WithdrawFromBankForm from '../../../../components/Accounts/Banking/WithdrawFromBank/WithdrawFromBankForm/WithdrawFromBankForm';

export default function RemitPayment() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    // Local states
    const [banks, setBanks] = React.useState([]);
    const [cashItems, setCashItems] = React.useState([]);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [viewValues, setViewValues] = React.useState(null);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        console.log(key, value);
        let values;
        if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'transDate') {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'bankCode' || key === 'bankName') {
            const bankKey = key === 'bankCode' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);
            return updateValues({
                bankCode: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'cashCode' || key === 'cashName') {
            const cashItemKey = key === 'cashCode' ? 'itemCode' : 'itemName';
            const cashItem = cashItems.find(
                cashItem => cashItem[cashItemKey] === value
            );
            return updateValues({
                depositedBy: cashItem.itemName,
                cashCode: cashItem.itemCode,
                cashName: cashItem.itemName,
            });
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = val => {
        // console.log(viewValues, 'viewValues');
        // console.log(val, 'val');
        setIsProcessing(true);
        const payload = {
            transDate: viewValues.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType: 'Bank Deposit',
            description: viewValues?.description,
            debitTrans: [
                {
                    mano: '080',
                    acno: '080.01',
                    bvrcno: viewValues?.chequeNo,
                    itemCode: viewValues?.cashCode,
                    itemName: viewValues?.cashName,
                    itemLocation: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchName,
                    receivedPaidBy: viewValues?.collectedBy,
                    remarks1: '',
                    rate: viewValues?.totalCollection,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: viewValues?.totalCollection,
                    quantity: '1',
                    particulars: `Cash Withdrawn - ${viewValues?.collectedBy}`,
                },
            ],
            creditTrans: [
                {
                    mano: '090',
                    acno: '090.01',
                    bvrcno: viewValues?.chequeNo,
                    itemCode: viewValues?.bankCode,
                    itemName: viewValues?.bankName,
                    itemLocation: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    receivedPaidBy: viewValues?.collectedBy,
                    remarks1: '',
                    rate: viewValues?.totalCollection,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: viewValues?.totalCollection,
                    quantity: '1',
                    particulars: `${viewValues?.bankName} Withdrawn`,
                },
            ],
        };
        console.log('payload: ', payload);
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/BankWithdrawal`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                console.log(res);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to Deposit to Bank');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues('view');
        setIsProcessing(false);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        cashCounters,
        banks,
        onFormSubmit,
        isProcessing,
        handleNew,
        cashItems,
    };

    // Get bank detail
    React.useEffect(() => {
        if (viewValues?.bankCode) {
            (async () => {
                const bankDetail = await GET_BANK_DETAIL(viewValues?.bankCode);
                console.log('bankDetail: ', bankDetail);
                setViewValues(prevValues => ({
                    ...prevValues,
                    bankAcno: bankDetail.bankAccountNo,
                    bankAddress: bankDetail.address,
                }));
            })();
        }
    }, [viewValues?.bankCode]);

    // Get bank balance
    React.useEffect(() => {
        if (viewValues?.bankCode) {
            (async () => {
                // Get bank balance
                const bankBalance = await GET_ITEM_BALANCE(
                    undefined,
                    '090',
                    '090.01',
                    viewValues?.bankCode
                );
                setViewValues(prevValues => ({
                    ...prevValues,
                    bankBalance,
                }));
            })();
        }
    }, [viewValues?.bankCode]);

    // Get cash balance
    React.useEffect(() => {
        if (viewValues?.cashCode) {
            (async () => {
                // Get cash balance
                const cashBalance = await GET_ITEM_BALANCE(
                    undefined,
                    '080',
                    '080.01',
                    viewValues?.cashCode
                );
                setViewValues(prevValues => ({
                    ...prevValues,
                    cashBalance,
                }));
            })();
        }
    }, [viewValues?.cashCode]);

    // Set amount in words
    React.useEffect(() => {
        if (viewValues?.totalCollection) {
            const totalCollection = viewValues?.totalCollection;
            const toWords = new ToWords({
                converterOptions: {
                    currency: true,
                },
            });
            let totalCollectionInWords = toWords.convert(totalCollection);
            setViewValues(prevValues => ({
                ...prevValues,
                totalCollectionInWords,
            }));
        }
    }, [viewValues?.totalCollection]);

    // Getter and Setter
    React.useEffect(() => {
        (async () => {
            // Get banks
            const banks = await GET_BANKS(viewValues?.branchID);
            setBanks(banks);
            // Get cash items
            const cashItems = await GET_ALL_CASH_ITEMS();
            setCashItems(cashItems);
        })();
    }, [setBanks, viewValues?.branchID]);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            Collect Cash to Bank
                        </h4>
                        <WithdrawFromBankForm
                            {...formProps}
                            mode="view"
                            values={viewValues}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
