import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import { GET_LOAN_HOLDER_ACCOUNT_NOS } from '../../../utils/axios';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import Branches from '../../Common/Geography/Branches/Branches';
export default function LoanRegistrationDetailsForm(props) {
    // Destructuring
    const {
        mode,
        values,
        members,
        loanTypeAccounts,
        loanHolderAccounts,
        setLoanHolderAccounts,
        loanNoValidationStatus,
    } = props;

    React.useEffect(() => {
        if (values?.branchID && values?.acno) {
            if (mode !== 'add') {
                (async () => {
                    const accounts = await GET_LOAN_HOLDER_ACCOUNT_NOS(
                        values.branchID,
                        values.acno
                    );
                    setLoanHolderAccounts(accounts);
                })();
            }
        }
    }, [values?.branchID, values?.acno, setLoanHolderAccounts, mode]);
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Branches
                        item="branchName"
                        required
                        disabled={mode === 'edit'}
                    />
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Branches
                        item="branchID"
                        required
                        disabled={mode === 'edit'}
                    />
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Entrance Date"
                        name="entranceDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <NepaliDatePicker inputClassName="ant-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={10}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label=" Loan Type"
                        name="acName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            disabled={mode === 'edit'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {loanTypeAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.acName}>
                                    {acc.acName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="ACNO"
                        name="acno"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            disabled={mode === 'edit'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {loanTypeAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.acno}>
                                    {acc.acno}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Loan No"
                        name="loanNo"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                        validateStatus={loanNoValidationStatus}
                        hasFeedback={mode === 'add' && values?.loanNo}
                        help={
                            loanNoValidationStatus === 'error'
                                ? 'Already taken'
                                : null
                        }
                    >
                        {mode === 'add' ? (
                            <Input />
                        ) : (
                            <Select
                                placeholder="Select..."
                                disabled={mode === 'edit'}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {loanHolderAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.loanNo}>
                                        {acc.loanNo}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={10}></Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label=" Member No"
                        name="memberNo"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members.map((member, i) => (
                                <Select.Option key={i} value={member.memberNO}>
                                    {member.memberNO}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Name" name="name">
                        <Select
                            placeholder="Select..."
                            disabled={mode === 'edit'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members.map((member, i) => (
                                <Select.Option
                                    key={i}
                                    value={member.memberName}
                                >
                                    {member.memberName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item label="नाम" name="nepName">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={10}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Address" name="address">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Email"
                        // name="smsFacility"
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item label="Mobile No" name="mobileNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
