import React from 'react';
// Import from dependencies
import { Form, Input, Row, Col, Select, DatePicker } from 'antd';

export default function MemberRegistrationStep02(props) {
    return (
        <>
            <h2 className="member-registration-step__header">{props.header}</h2>
            <div className="member-registration-step__form">
                <Form layout="vertical">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Full Name">
                                <Input placeholder="Full Name" />
                            </Form.Item>
                            <Form.Item label="Education">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Bachelors</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Occupation">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Business</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Caste group">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Newar</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Citizenship No.">
                                <Input placeholder="Citizenship No." />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Gender">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Male</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Marital status">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>Unmarried</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Monthly income">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option>0 - 10,000</Select.Option>
                                    <Select.Option>
                                        10,000 - 20,000
                                    </Select.Option>
                                    <Select.Option>
                                        20,000 - 30,000
                                    </Select.Option>
                                    <Select.Option>
                                        30,000 - 40,000
                                    </Select.Option>
                                    <Select.Option>
                                        40,000 - 50,000
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Date of Birth">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="Citizenship issue date">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}
