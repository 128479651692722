import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';

export default function DepositTypesNotification(props) {
    // Destructuring
    const { onCheckBoxChange, values, mode } = props;
    return (
        <div className="deposit-type-general-detail-form-view">
            <Form.Item
                label="Account No"
                // name="accountNumber"
            >
                <Row>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="csItem"
                            name="csItem"
                            checked={values?.csItem}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Collection Sheet Item
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="microSaving"
                            name="microSaving"
                            checked={values?.microSaving}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Micro Saving
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="disabled"
                            name="disabled"
                            checked={values?.disabled}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Disabled
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="birthDayNotification"
                            name="birthDayNotification"
                            checked={values?.birthDayNotification}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Birthday Notification
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="maturityNotification"
                            name="maturityNotification"
                            checked={values?.maturityNotification}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Maturity Notification
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Checkbox
                            value="interestTransferable"
                            name="interestTransferable"
                            checked={values?.interestTransferable}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Transfer Interest
                        </Checkbox>
                    </Col>
                </Row>
            </Form.Item>
        </div>
    );
}
