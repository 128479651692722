import React from 'react';
import { Button, Modal, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
// Import utils
import axios, {
    GET_CASTES,
    GET_DISTRICTS,
    GET_MUNICIPALITIES,
    GET_MEMBERS,
    GET_MEMBER_DETAIL,
} from '../../../utils/axios';
import { englishToNepaliNumber, nepaliToEnglishNumber } from 'nepali-number';
import {
    membershipFirstPanelNames,
    membershipSecondPanelNames,
    membershipThirdPanelNames,
} from '../../../utils/helpers';
import moment from 'moment';
import {
    JSON_TO_FORM_DATA,
    UPDATE_AXIOS_AUTH,
    ON_FILE_CHANGE,
    COMPARE_ARRAYS,
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ,
    NEP_TO_ENG_ISO,
    IS_VALID_DATE,
} from '../../../utils/functions';
// Import components
import MemberRegistrationForm from '../../../components/Membership/MemberRegistrationForm/MemberRegistrationForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import PersonalDetailForm from '../../../components/Membership/MemberRegistrationForm/PersonalDetailForm/PersonalDetailForm';
import AddressDetailForm from '../../../components/Membership/MemberRegistrationForm/AddressDetailForm/AddressDetailForm';
import FamilyDetailForm from '../../../components/Membership/MemberRegistrationForm/FamilyDetailForm/FamilyDetailForm';
import OtherDetailForm from '../../../components/Membership/MemberRegistrationForm/OtherDetailForm/OtherDetailForm';
import DocumentsForm from '../../../components/Membership/MemberRegistrationForm/DocumentsForm/DocumentsForm';

export default function MemberRegistration(props) {
    // Local states
    const [members, setMembers] = React.useState([]);
    const [provinces, setProvinces] = React.useState([]);
    const [districtsTmp, setDistrictsTmp] = React.useState([]);
    const [districtsPer, setDistrictsPer] = React.useState([]);
    const [municipalitiesTmp, setMunicipalitiesTmp] = React.useState([]);
    const [municipalitiesPer, setMunicipalitiesPer] = React.useState([]);
    const [casteGroups, setCasteGroups] = React.useState([]);
    const [castes, setCastes] = React.useState([]);
    const [religions, setReligions] = React.useState([]);
    const [maritalStatuses, setMaritalStatuses] = React.useState([]);
    const [education, setEducation] = React.useState([]);
    const [occupation, setOccupation] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [areas, setAreas] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [activePanelKey, setActivePanelKey] = React.useState('0');
    const [memberNOValidationStatus, setMemberNOValidationStatus] =
        React.useState(null); // validating | success | error
    const [regSavIdValidationStatus, setRegSaveIdValidationStatus] =
        React.useState(null); // validating | success | error
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Destructuring
    // Ref
    const formRef = React.useRef();

    // Variables and constants
    const tabPanes = [
        {
            title: 'Personal Detail',
            component: <PersonalDetailForm />,
        },
        {
            title: 'Address Detail',
            component: <AddressDetailForm />,
        },
        {
            title: 'Family Detail',
            component: <FamilyDetailForm />,
        },
        {
            title: 'Other Details',
            component: <OtherDetailForm />,
        },
        {
            title: 'Documents',
            component: <DocumentsForm />,
        },
    ];
    const lastPanelKey = tabPanes.length - 1 + '';
    const location = useLocation();
    const history = useHistory();

    // Utility functions
    // Check if panel is first
    const isPanelFirst = () => {
        return activePanelKey === '0';
    };
    // Check if panel is last
    const isPanelLast = () => {
        return activePanelKey === lastPanelKey;
    };
    // On tabs change
    const onPanelChange = activeKey => {
        setActivePanelKey(activeKey);
    };
    // On Panel Prev
    const onPanelPrev = () => {
        setActivePanelKey(+activePanelKey - 1 + '');
    };
    // Go to next panel
    const goToNextPanel = () => {
        setActivePanelKey(+activePanelKey + 1 + '');
    };
    // On Panel Next
    const onPanelNext = () => {
        formRef.current
            .validateFields()
            .then(() => {
                goToNextPanel();
            })
            .catch(() => {
                message.error('Please fill all required fields!');
                let errorFields = [];
                formRef.current
                    .getFieldsError()
                    .forEach(
                        field =>
                            field.errors.length &&
                            errorFields.push(field.name[0])
                    );
                if (activePanelKey === '0') {
                    if (!COMPARE_ARRAYS(errorFields, membershipFirstPanelNames))
                        goToNextPanel();
                } else if (activePanelKey === '1') {
                    if (
                        !COMPARE_ARRAYS(errorFields, membershipSecondPanelNames)
                    )
                        goToNextPanel();
                } else if (activePanelKey === '2') {
                    if (!COMPARE_ARRAYS(errorFields, membershipThirdPanelNames))
                        goToNextPanel();
                }
            });
    };
    // Get member detail
    const getMemberDetail = memberNo => {
        (async () => {
            const memberDetail = await GET_MEMBER_DETAIL(memberNo);
            console.log('memberDetail: ', memberDetail);
            if (!IS_VALID_DATE(memberDetail.dateOfBirth))
                message.error('Invalid nepali date of birth');
            setViewValues(memberDetail);
            if (location.search.includes('edit=true')) {
                setEditValues(memberDetail);
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: true,
                }));
                history.push('/member-registration');
            }
        })();
    };
    // Get districts
    const getDistricts = async (province, type) => {
        const districts = await GET_DISTRICTS(province);
        if (type === 'tmp') setDistrictsTmp(districts);
        else if (type === 'per') setDistrictsPer(districts);
    };
    // Get districts
    const getMunicipalities = async (district, type) => {
        const municipalities = await GET_MUNICIPALITIES(district);
        if (type === 'tmp') setMunicipalitiesTmp(municipalities);
        if (type === 'per') setMunicipalitiesPer(municipalities);
    };
    // Check member num availability
    const checkMemNoAvailability = async memberNO => {
        if (memberNO) {
            setMemberNOValidationStatus('validating');
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/Member/IsMemberValid/${memberNO}`
                );
                const isAvailable = res.data.data;
                console.log('isAvailable: ', isAvailable);
                if (isAvailable) setMemberNOValidationStatus('success');
                else setMemberNOValidationStatus('error');
            } catch (err) {
                console.error(err, 'Failed to check member no availability');
                message.error('Something went wrong. Please try again!');
            }
        } else {
            setMemberNOValidationStatus(null);
        }
    };
    // Check member num availability
    const checkRegSaveIdAvailability = async regSaveId => {
        if (regSaveId) {
            setRegSaveIdValidationStatus('validating');
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/Member/IsRegSaveIDValid/${regSaveId}`
                );

                const isAvailable = res.data.data;
                if (isAvailable) setRegSaveIdValidationStatus('success');
                else setRegSaveIdValidationStatus('error');
            } catch (err) {
                console.error(err, 'Failed to check reg save ID availability');
                message.error('Something went wrong. Please try again!');
            }
        } else {
            setRegSaveIdValidationStatus(null);
        }
    };
    // Get new IDS
    const getNewIDs = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_HOST}/api/Member/GetNewIDs/${viewValues.branchID}`
            );
            setAddValues(prevValues => ({
                ...prevValues,
                ...res.data.data,
            }));
        } catch (err) {
            console.error(err, 'Failed to get new member registration IDs');
            message.error('Something went wrong. Please try again!');
        }
    };
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            area: 'CENTER',
            group: 'COMMON',
            entryDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(res => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));

                const payload = JSON_TO_FORM_DATA(editValues);
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Member/update/${editValues?.memberNO}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            return message.error(res.data.statusMessage);
                        }

                        setViewValues(res.data.data);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to member details');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => message.error('Please fill all required fields'));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        // Get new ids conditionally
        if (
            JSON.parse(localStorage.getItem('ENTERPRISE_DETAIL'))
                .fetchIDbeforeInsert
        ) {
            getNewIDs();
        }
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = JSON_TO_FORM_DATA(addValues);
                axios
                    .post(
                        `${
                            process.env.REACT_APP_HOST
                        }/api/Member/Insert?MemberNO=${
                            addValues?.memberNO || null
                        }`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setMembers([
                            ...members,
                            {
                                memberNO: data.memberNO,
                                memberName: data.memberName,
                            },
                        ]);
                        setAddValues(null);
                        message.success('New member registered!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to register member');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On values change
    const onValuesChange = (val, mode) => {
        console.log('val: ', val, mode);
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('memberNO')) {
            if (mode === 'add') checkMemNoAvailability(val.memberNO);
        } else if (val.hasOwnProperty('memberName')) {
            return updateValues({
                memberName: val.memberName.toUpperCase(),
            });
        } else if (val.hasOwnProperty('regSaveId')) {
            if (mode === 'add') checkRegSaveIdAvailability(val.regSaveId);
        } else if (val.hasOwnProperty('tmpProvince')) {
            const province = provinces.find(
                province => province.provinceName === val.tmpProvince
            );
            getDistricts(province.provinceName, 'tmp');
            updateValues(
                DLT_KEYS_FROM_OBJ(values, [
                    'tmpDistrict',
                    'untDistrict',
                    'tmpVDCMun',
                    'untvdc',
                ])
            );
            updateValues({
                tmpProvince: province.provinceName,
                untProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('untProvince')) {
            const province = provinces.find(
                province => province.provinceNepali === val.untProvince
            );
            getDistricts(province.provinceName, 'tmp');
            updateValues(
                DLT_KEYS_FROM_OBJ(values, [
                    'tmpDistrict',
                    'untDistrict',
                    'tmpVDCMun',
                    'untvdc',
                ])
            );
            updateValues({
                tmpProvince: province.provinceName,
                untProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('tmpDistrict')) {
            const district = districtsTmp.find(
                district => district.districtName === val.tmpDistrict
            );
            getMunicipalities(district.districtName, 'tmp');
            updateValues(DLT_KEYS_FROM_OBJ(values, ['tmpVDCMun', 'untvdc']));
            updateValues({
                tmpDistrict: district.districtName,
                untDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('untDistrict')) {
            const district = districtsTmp.find(
                district => district.districtNepali === val.untDistrict
            );
            getMunicipalities(district.districtName, 'tmp');
            updateValues(DLT_KEYS_FROM_OBJ(values, ['tmpVDCMun', 'untvdc']));
            updateValues({
                tmpDistrict: district.districtName,
                untDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('tmpVDCMun')) {
            const municipality = municipalitiesTmp.find(
                mun => mun.municipalName === val.tmpVDCMun
            );
            updateValues({
                tmpVDCMun: municipality.municipalName,
                untvdc: municipality.municipalNepali,
            });
        } else if (val.hasOwnProperty('untvdc')) {
            const municipality = municipalitiesTmp.find(
                mun => mun.municipalNepali === val.untvdc
            );
            updateValues({
                tmpVDCMun: municipality.municipalName,
                untvdc: municipality.municipalNepali,
            });
        } else if (val.hasOwnProperty('province')) {
            const province = provinces.find(
                province => province.provinceName === val.province
            );
            getDistricts(province.provinceName, 'per');
            updateValues(
                DLT_KEYS_FROM_OBJ(values, [
                    'district',
                    'unDistrict',
                    'vdcMun',
                    'unvdc',
                ])
            );
            updateValues({
                province: province.provinceName,
                unProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('unProvince')) {
            const province = provinces.find(
                province => province.provinceNepali === val.unProvince
            );
            getDistricts(province.provinceName, 'per');
            updateValues(
                DLT_KEYS_FROM_OBJ(values, [
                    'district',
                    'unDistrict',
                    'vdcMun',
                    'unvdc',
                ])
            );
            updateValues({
                province: province.provinceName,
                unProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('district')) {
            const district = districtsPer.find(
                district => district.districtName === val.district
            );
            getMunicipalities(district.districtName, 'per');
            updateValues(DLT_KEYS_FROM_OBJ(values, ['vdcMun', 'unvdc']));
            updateValues({
                district: district.districtName,
                unDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('unDistrict')) {
            const district = districtsPer.find(
                district => district.districtNepali === val.unDistrict
            );
            getMunicipalities(district.districtName, 'per');
            updateValues(DLT_KEYS_FROM_OBJ(values, ['vdcMun', 'unvdc']));
            updateValues({
                district: district.districtName,
                unDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('vdcMun')) {
            const municipality = municipalitiesPer.find(
                mun => mun.municipalName === val.vdcMun
            );
            updateValues({
                vdcMun: municipality.municipalName,
                unvdc: municipality.municipalNepali,
            });
        } else if (val.hasOwnProperty('unvdc')) {
            const municipality = municipalitiesPer.find(
                mun => mun.municipalNepali === val.unvdc
            );
            updateValues({
                vdcMun: municipality.municipalName,
                unvdc: municipality.municipalNepali,
            });
        } else if (val.hasOwnProperty('casteGroup')) {
            (async () => {
                const castes = await GET_CASTES(val.casteGroup);
                setCastes(castes);
            })();
            updateValues(DLT_KEYS_FROM_OBJ(values, ['caste']));
        } else if (val.hasOwnProperty('headName')) {
            return updateValues({ headName: val.headName.toUpperCase() });
        } else if (val.hasOwnProperty('motherName')) {
            return updateValues({ motherName: val.motherName.toUpperCase() });
        } else if (val.hasOwnProperty('grandHeadName')) {
            return updateValues({
                grandHeadName: val.grandHeadName.toUpperCase(),
            });
        } else if (val.hasOwnProperty('spouse')) {
            return updateValues({
                spouse: val.spouse.toUpperCase(),
            });
        } else if (val.hasOwnProperty('staffName')) {
            const staff = staffs.find(
                staff => staff.staffName === val.staffName
            );
            updateValues(staff);
        } else if (val.hasOwnProperty('staffID')) {
            const staff = staffs.find(staff => staff.staffID === val.staffID);
            updateValues(staff);
        } else if (val.hasOwnProperty('wardNO')) {
            updateValues({ unWardNO: englishToNepaliNumber(val.wardNO) });
        } else if (val.hasOwnProperty('unWardNO')) {
            updateValues({ wardNO: nepaliToEnglishNumber(val.unWardNO) });
        } else if (
            val.hasOwnProperty('entryDate') ||
            val.hasOwnProperty('dateOfBirth') ||
            val.hasOwnProperty('nomDOB') ||
            val.hasOwnProperty('citDate')
        ) {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        }
        updateValues(val);
    };

    // On date change
    const onDateChange = (date, key, mode) => {
        const dateISO = moment(date).format();
        if (mode === 'edit')
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: dateISO,
            }));
        else if (mode === 'add')
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: dateISO,
            }));
    };

    // Handle address same as permanent
    const handleAddressSameAsPermanent = mode => {
        let values;
        if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updatedValues = {
            ...values,
            tmpProvince: values.province,
            tmpDistrict: values.district,
            tmpVDCMun: values.vdcMun,
            tmpWardNO: values.wardNO,
            untProvince: values.unProvince,
            untDistrict: values.unDistrict,
            untvdc: values.unvdc,
            untWardNO: values.unWardNO,
            tmpTole: values.tole,
            untTole: values.unTole,
            tmpBlockNO: values.blockNO,
        };
        if (mode === 'edit') {
            setEditValues(updatedValues);
        } else if (mode === 'add') setAddValues(updatedValues);
    };

    // On file change
    const onFileChange = (e, mode) => {
        ON_FILE_CHANGE(e, mode, setEditValues, setAddValues);
    };

    // Get member detail
    React.useEffect(() => {
        if (viewValues?.memberNO) {
            getMemberDetail(viewValues.memberNO);
        }
    }, [viewValues?.memberNO]); // Do not include getMemberDetail dependency

    // Form props
    const formProps = {
        ref: formRef,
        tabPanes,
        onPanelChange,
        activePanelKey,
        members,
        provinces,
        setProvinces,
        districtsTmp,
        districtsPer,
        municipalitiesTmp,
        municipalitiesPer,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        education,
        setEducation,
        occupation,
        setOccupation,
        groups,
        setGroups,
        areas,
        setAreas,
        staffs,
        setStaffs,
        onValuesChange,
        onDateChange,
        handleAddressSameAsPermanent,
        onFileChange,
        setDefaultValues,
        memberNOValidationStatus,
        regSavIdValidationStatus,
    };

    // On component mount
    React.useEffect(() => {
        // GET MEMBERS
        (async () => {
            const members = await GET_MEMBERS();
            setMembers(members);
        })();
    }, []);

    // Extract member no from query param
    React.useEffect(() => {
        if (location.search) {
            // setIsPageLoading(true);
            const string = location.search;
            const memberNO = string.slice(
                string.indexOf('=') + 1,
                string.lastIndexOf('?')
            );
            if (viewValues?.memberNO === memberNO) {
                return getMemberDetail(memberNO);
            }
            setViewValues(prevValues => ({
                ...prevValues,
                memberNO,
            }));
        }
    }, [location.search]); // Do not include getMemberDetail and viewValues?.memberNO dependencies

    return (
        <div className="staff-registration">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">
                    Member Registration
                </h3>
                <MemberRegistrationForm
                    {...formProps}
                    mode="view"
                    values={viewValues}
                />
                {/* Edit account modal */}
                <Modal
                    title="Edit account"
                    visible={editModal.visible}
                    confirmLoading={editModal.confirmLoading}
                    footer={[
                        <Button
                            type="default"
                            onClick={isPanelFirst() ? undefined : onPanelPrev}
                            disabled={
                                isPanelFirst() || editModal.confirmLoading
                            }
                        >
                            Prev
                        </Button>,
                        <Button
                            type="primary"
                            onClick={
                                isPanelLast() ? onEditModalOk : onPanelNext
                            }
                        >
                            {isPanelLast() ? 'Update' : 'Next'}
                        </Button>,
                    ]}
                    onCancel={onEditModalCancel}
                    destroyOnClose
                    maskClosable={false}
                    width={1200}
                >
                    <MemberRegistrationForm
                        {...formProps}
                        mode="edit"
                        values={editValues}
                    />
                </Modal>
                {/* Add account modal */}
                <Modal
                    title="Add account"
                    visible={addModal.visible}
                    confirmLoading={addModal.confirmLoading}
                    footer={[
                        <Button
                            type="default"
                            onClick={isPanelFirst() ? undefined : onPanelPrev}
                            disabled={isPanelFirst() || addModal.confirmLoading}
                        >
                            Prev
                        </Button>,
                        <Button
                            type="primary"
                            onClick={isPanelLast() ? onAddModalOk : onPanelNext}
                        >
                            {isPanelLast() ? 'Create' : 'Next'}
                        </Button>,
                    ]}
                    onCancel={onAddModalCancel}
                    destroyOnClose
                    maskClosable={false}
                    width={1200}
                >
                    <MemberRegistrationForm
                        {...formProps}
                        mode="add"
                        values={addValues}
                    />
                </Modal>
                <BottomActionBarFixed>
                    <Button
                        type="primary"
                        onClick={onEditModalOpen}
                        disabled={!viewValues?.memberNO}
                    >
                        Edit
                    </Button>
                    <Button type="primary" onClick={onAddModalOpen}>
                        Add
                    </Button>
                </BottomActionBarFixed>
            </div>
        </div>
    );
}
