import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Button,
    Space,
    Radio,
    Checkbox,
} from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

export default function ClosingAccountForm(props) {
    // Destructuring
    const {
        depTypeAccounts,
        savingTypeAccounts,
        values,
        onValuesChange,
        getDepShortInfoQuick,
        isProcessing,
        handleNew,
        onFormSubmit,
        chequeNos,
        handleShowSignature,
    } = props;

    // Local states
    return (
        <div>
            <Form
                layout="horizontal"
                className="margin-top"
                onValuesChange={onValuesChange}
                onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'accountNo',
                        value: values?.accountNo,
                    },
                    {
                        name: 'accountNoAlt',
                        value: values?.accountNoAlt,
                    },
                    {
                        name: 'fullName',
                        value: values?.fullName,
                    },
                    {
                        name: 'memberNo',
                        value: values?.memberNo,
                    },
                    {
                        name: 'address',
                        value: values?.address,
                    },
                    {
                        name: 'expireDate',
                        value:
                            values?.expireDate &&
                            ENG_ISO_TO_NEP(values.expireDate),
                    },
                    {
                        name: 'receivedPaidBy',
                        value: values?.receivedPaidBy,
                    },
                    {
                        name: 'transDate',
                        value:
                            values?.transDate &&
                            ENG_ISO_TO_NEP(values.transDate),
                    },
                    {
                        name: 'startDate',
                        value:
                            values?.startDate &&
                            ENG_ISO_TO_NEP(values.startDate),
                    },
                    {
                        name: 'preTotal',
                        value: values?.preTotal,
                    },
                    {
                        name: 'cashNo',
                        value: values?.cashNo,
                    },
                    {
                        name: 'cashName',
                        value: values?.cashName,
                    },
                    {
                        name: 'cashAmount',
                        value: values?.cashAmount,
                    },
                    {
                        name: 'bankNo',
                        value: values?.bankNo,
                    },
                    {
                        name: 'bankName',
                        value: values?.bankName,
                    },
                    {
                        name: 'bankAmount',
                        value: values?.bankAmount,
                    },
                    {
                        name: 'otherNo',
                        value: values?.otherNo,
                    },
                    {
                        name: 'otherName',
                        value: values?.otherName,
                    },
                    {
                        name: 'otherAmount',
                        value: values?.otherAmount,
                    },
                    {
                        name: 'otherAcno',
                        value: values?.otherAcno,
                    },
                    {
                        name: 'otherAcName',
                        value: values?.otherAcName,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'receiptNo',
                        value: values?.receiptNo,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                    {
                        name: 'totalAmount',
                        value: values?.totalAmount,
                    },
                    {
                        name: 'totalAmountInWords',
                        value: values?.totalAmountInWords,
                    },

                    {
                        name: 'actualBal',
                        value: values?.actualBal,
                    },
                    {
                        name: 'isCash',
                        value: values?.isCash,
                    },
                    {
                        name: 'locked',
                        value: values?.locked,
                    },
                    {
                        name: 'disabled',
                        value: values?.disabled,
                    },
                    {
                        name: 'compulsory',
                        value: values?.style === 'Compulsory',
                    },
                    {
                        name: 'fixed',
                        value: values?.style === 'Fixed',
                    },
                    {
                        name: 'balance',
                        value: values?.balance,
                    },
                    {
                        name: 'unpaidInterest',
                        value: values?.unpaidInterest,
                    },
                    {
                        name: 'intProvision',
                        value: values?.intProvision,
                    },
                    {
                        name: 'provisionTax',
                        value: values?.provisionTax,
                    },
                    {
                        name: 'interest',
                        value: values?.interest,
                    },
                    {
                        name: 'tax',
                        value: values?.tax,
                    },
                    {
                        name: 'calculatedODInterest',
                        value: values?.calculatedODInterest,
                    },
                    {
                        name: 'odInterest',
                        value: values?.odInterest,
                    },
                    {
                        name: 'closingCharge',
                        value: values?.closingCharge,
                    },
                    {
                        name: 'untimelyFine',
                        value: values?.untimelyFine,
                    },
                    {
                        name: 'payRec',
                        value: values?.payRec,
                    },
                    {
                        name: 'odFacility',
                        value: values?.odFacility,
                    },
                    {
                        name: 'chequeNo',
                        value: values?.chequeNo,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Account No"
                                name="accountNoAlt"
                                labelCol={{ span: 9 }}
                            >
                                <Input
                                    className="ant-input--highlight-secondary"
                                    onPressEnter={getDepShortInfoQuick}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={6}>
                            <Form.Item label="Account Status"></Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Date"
                                name="transDate"
                                labelCol={{ span: 11 }}
                            >
                                <NepaliDatePicker inputClassName="ant-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label="Deposit Type"
                                name="acName"
                                labelCol={{ span: 7 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.length &&
                                        depTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="acno"
                                labelCol={{ span: 9 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.length &&
                                        depTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="locked" valuePropName="checked">
                                <Checkbox disabled>Locked</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="disabled" valuePropName="checked">
                                <Checkbox disabled>Disabled</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Journal No" name="journalNO">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Account No"
                                name="accountNo"
                                labelCol={{ span: 9 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccounts?.length &&
                                        savingTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.accountNo}
                                            >
                                                {acc.accountNo}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Member ID"
                                name="memberNo"
                                labelCol={{ span: 8 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="compulsory"
                                valuePropName="checked"
                            >
                                <Checkbox disabled>Compulsory</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="fixed" valuePropName="checked">
                                <Checkbox disabled>Fixed</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Trans No"
                                name="transNo"
                                labelCol={{ span: 11 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label="Account Holder"
                                name="fullName"
                                labelCol={{ span: 7 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccounts?.length &&
                                        savingTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.fullName}
                                            >
                                                {acc.fullName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                labelCol={{ span: 9 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Maturity Date"
                                name="expireDate"
                                labelCol={{ span: 11 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Deposit Balance"
                                name="balance"
                                labelCol={{ span: 9 }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Unpaid Interest"
                                name="unpaidInterest"
                                labelCol={{ span: 11 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Interest Provision"
                                name="intProvision"
                                labelCol={{ span: 12 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Provision TDS"
                                name="provisionTax"
                                labelCol={{ span: 10 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                name="selfCalculation"
                                valuePropName="checked"
                            >
                                <Checkbox>Self Calculation</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Current Interest"
                                name="interest"
                                labelCol={{ span: 11 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Current TDS"
                                name="tax"
                                labelCol={{ span: 12 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                name="odFacility"
                                valuePropName="checked"
                            >
                                <Checkbox disabled>OD Facility</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Regular OD Interest"
                                name="odInterest"
                            >
                                <Input
                                    disabled={
                                        !(
                                            values?.selfCalculation &&
                                            values?.odFacility
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Calculated OD Interest"
                                name="calculatedODInterest"
                                labelCol={{ span: 12 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <Form.Item
                                label="Close Options"
                                name="closeOptions"
                            >
                                <Radio.Group>
                                    <Radio value="disabled">Disable</Radio>
                                    <Radio value="remove">Remove</Radio>
                                    <Radio value="unclose">Leave Unclose</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={6}>
                            <Form.Item
                                label="Untime Fine"
                                name="untimelyFine"
                                labelCol={{ span: 12 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Closing Charge Rs"
                                name="closingCharge"
                                labelCol={{ span: 11 }}
                            >
                                <Input disabled={!values?.selfCalculation} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Divider /> */}
                    <Row gutter={20}>
                        <Col span={5}>
                            <Form.Item label="Cheque No" name="chequeNo">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {chequeNos?.length &&
                                        chequeNos.map((item, i) => (
                                            <Select.Option key={i} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Button
                                type="default"
                                onClick={handleShowSignature}
                            >
                                Show Signature
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Total Payable/Receivable"
                                name="payRec"
                                labelCol={{ span: 10 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={3}></Col>
                        <Col span={5}>
                            <Form.Item
                                label={`${
                                    +values?.payRec > 0 ? 'Pay' : 'Received'
                                } Rs`}
                                name="preTotal"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={20}>
                        <Col span={15}>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={6}>
                                    <Form.Item>
                                        <Button type="default" className="btns">
                                            Statement
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={6}>
                                    <Form.Item>
                                        <Button type="default" className="btns">
                                            Notes
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={9}>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={6} lg={24}>
                                    <br></br>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Current Balance"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="actualBal">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={8}>
                                    <Form.Item label="Deposit Rs"></Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={16}>
                                    <Form.Item name="preTotal">
                                        <Input
                                            // disabled
                                            className="ant-input--highlight-primary"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    <PaymentSource
                        title={`${
                            +values?.payRec < 0 ? 'Receipt' : 'Payment'
                        } Source`}
                        mode={`${+values?.payRec < 0 ? 'receipt' : 'payment'}`}
                        values={values}
                    />
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label=" " colon={false}>
                                <Space style={{ marginTop: '1.5rem' }}>
                                    <Button
                                        htmlType="submit"
                                        disabled={isProcessing}
                                    >
                                        Save
                                    </Button>
                                    <Button>Print Receipt</Button>
                                    <Button>Deno</Button>
                                    <Button onClick={handleNew}>New</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
