import defaultAxios from 'axios';
import { message } from 'antd';
import moment from 'moment';

// Create an instance of axios with Authorization header

export const axios = defaultAxios.create({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('USER_ACCESS')}`,
    },
});

export const GET_CASTE_GROUPS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/CasteGroup`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get caste groups');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CASTES = async casteGroup => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Caste/${casteGroup}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get castes');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RELIGIONS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Religion`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get religions');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MARITAL_STATUSES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MaritalStatus`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get marital statues');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PROVINCES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/Province`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get provinces');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_DISTRICTS = async province => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/${province}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get provinces');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MUNICIPALITIES = async district => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/Municipal/${district}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get municipalities');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBERS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMembers/${
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
            }`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNERS = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/GetOwner?BranchID=${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get owners');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNER_TYPES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/OwnerType`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get owner types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_DETAIL = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMemberDetail/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNER_DETAIL = async ownerNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/GetOwnerDetail/${ownerNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EDUCATION = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/OtherDetail/Education`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get education types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OCCUPATION = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/OtherDetail/Occupation`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get occupation types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RELATION_TYPES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Relations`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get relation types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GROUPS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Group`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get groups');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_AREAS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Area`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get areas');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_STAFFS_BY_BRANCH = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Staff/GetByBranchID/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get staffs');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_COLLECTORS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/GetCollectors/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get collectors');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_KISTA_PERIODS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/Kistaperiod`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get kista periods');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetAccountNos`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNTS_FULL = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetAccountNos`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACC_INFO = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanTypeInfo/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type account info');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNT_DETAIL = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetDetail/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_HOLDER_ACCOUNT_NOS = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetLoanNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan holder accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_HOLDER_ACCOUNT_DETAIL = async (acno, loanNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanDetail/${acno}/${loanNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan holder account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_LOAN_TYPE_ACC_NO = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/NewLoantypecode`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new loan type account number');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_MEMBERS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetLoanMember/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_MEMBER_INFO_SHORT = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    loanNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanInfoShort/${branchID}/${loanNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan member info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_SAVING_TYPE_ACC_NO = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetNewAccountNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new Saving type account number');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ENTERPRISE_DETAIL = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/enterpriseDetail`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get enterprise detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GUARDIAN_DETAIL = async memberNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/Guardianinfo?Memberno=${memberNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get guardian detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BRANCHES = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/branch`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get branches');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_USERS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Users`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get users');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_USER_DETAIL = async userID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Users/${userID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get user detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/ActiveDepositType`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Active deposit type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNTS = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetAccounts/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNTS_FULL = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountFullID/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNT_DETAIL = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountDetail/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACCOUNT_INFO_SHORT = async (
    branchID,
    accountNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountInfoShort/${branchID}/${accountNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_FIXED_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/FixedAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Fixed accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_FIXED_ACCOUNT_INFO_SHORT = async (
    branchID,
    accountNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/FixedAccountInfo/${branchID}/${accountNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Fixed account info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBERS_BY_BRANCH = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMembers/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INFO_SHORT = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberInfoShort/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACC_BASIC_INFO = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/BaseInfo/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member basic info');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_DEPOSIT_INFO_SHORT = async memberNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberDepositInfoShort/${memberNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member deposit info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/GetTransferable`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Deposit type transferable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNERS_BY_MEMBER_NO = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetOwnerID/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Owners');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACC_MEM_LIST = async (memberNo, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/MemberAccountList/${memberNo}/${acno}`
        );
        // if (res.data.statusCode !== 0)
        //     return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving account members list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_LOAN_NO = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetNewAccountNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new loan no');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_END_DATE = async payload => {
    if (payload.dmy === 'Not Mentioned') return payload.startDate;
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Dates/AddDates`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get end date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_DATE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Dates/InstallmentDays`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_PAYMENT_SCHEDULE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanPayemntSchedule`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_COUNTERS_BY_BRANCH = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Counters/GetBranchCounter/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ASSIGNED_COUNTERS = async userName => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Counters/GetAssignedCounter/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get assigned counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CASH_COUNTERS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/CashCounter/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get cash counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANKS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/Banks/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get banks');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANK_ACCOUNTS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/Banks/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get banks');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANK_DETAIL = async itemCode => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Banks/Detail/${itemCode}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get bank detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OTHER_ACCOUNTS = async (branchID, userName) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/OtherAccounts/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get bank accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OTHER_ITEMS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/GetItems/${branchID}/${userName}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get other items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACCOUNT_CHEQUE_NOS = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/ChequeNos/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get saving account cheque nos');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ALL_CASH_ITEMS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/CashItems/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get saving account cheque nos');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEM_BALANCE = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    mano,
    acno,
    itemCode
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${branchID}/${mano}/${acno}/${itemCode}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get item balance');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_REMITS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Remit/GetRemit/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get remits');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PAYABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/PayableAccounts`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get payable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PAYABLE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetPayable/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get payable item');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_HOUSE_ITEMS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/RentedOffice/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get house items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MONTHS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Dates/MonthsList`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get months');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEP_MONTH = async dateISO => {
    const date = moment(dateISO).format('YYYY-MM-DD');
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Dates/CurrentMonth/${date}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get nepali month');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MAIN_ACCOUNTS_FOR_RECEIVABLES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/MANOForReceivable`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get main accounts for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACCOUNTS_FOR_RECEIVABLES = async mano => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ACNOForReceivables/${mano}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get accounts for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEMS_FOR_RECEIVABLES = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ItemsforReceivables/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get items for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RECEIVABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ReceivablesAccounts`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get receivable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RECEIVABLE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ReceivablesItems/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get receivable items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INCOME_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetIncomeAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get income accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INCOME_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetIncome/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get income items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPENDITURE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetExpenseAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get expenditure accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPENDITURE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetExpense/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get expenditure items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INVESTMENT_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetInvestmentAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get investment accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INVESTMENT_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetInvestment/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get investment items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CLOSING_ACCOUNT_INFO = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/DepositClosing`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get closing account info');
        message.error('Something went wrong. Please try again!');
    }
};

export const SEARCH_MEMBER = async (
    value,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/FindMember/MemberSearch/${userName}/${value}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to search member');
        message.error('Something went wrong. Please try again!');
    }
};

export const SEARCH_MEMBER_DETAIL = async (memberno, isOwner) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/FindMember/FindMember/${memberno}?isOwner=${isOwner}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export default axios;
