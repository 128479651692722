import React from 'react';
import moment from 'moment';
import axios from '../../../utils/axios';
// Import components
import TrialBalanceForm from '../../../components/Reports/TrialBalance/TrialBalanceForm/TrialBalanceForm';
import { NEP_TO_ENG_ISO } from '../../../utils/functions';
import { message } from 'antd';

export default function TrialBalance() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [trialBalance, setTrialBalance] = React.useState(null);

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            acHeadTotal: true,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return updateValues({
                [key]: NEP_TO_ENG_ISO(value),
            });
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        trialBalance,
    };

    React.useEffect(() => {
        if (viewValues) {
            const {
                tillDate,
                fromDate,
                suspenseAccount,
                odLoanSide,
                acHeadTotal,
                branchID,
            } = viewValues;
            const payload = {
                tillDate,
                fromDate,
                suspenseAccount,
                odLoanSide,
                total: acHeadTotal,
                branchID,
            };
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/TrialBalance/ALLTrailBalance`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    console.log(res.data.data);
                    setTrialBalance(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get trial balance');
                    message.error(err, 'Failed to get trial balance');
                });
        }
    }, [viewValues]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    return (
        <div className="owner-details">
            <div className="block-general-titled">
                <h3 className="block-general-titled--title">Trial Balance</h3>
                <div
                    className="container-fluid"
                    style={{ marginLeft: 'unset' }}
                >
                    <TrialBalanceForm {...formProps} />
                </div>
            </div>
        </div>
    );
}
