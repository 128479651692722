import React from 'react';
import {
    Row,
    Col,
    Input,
    Button,
    Form,
    Space,
    Select,
    Table,
    Popconfirm,
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';

export default function CounterCashDistributionForm(props) {
    // Destructuring
    const {
        counterData,
        onCounterDataChange,
        getCounterData,
        handleCounterDataAdd,
        values,
        onValuesChange,
        cashItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        handleFillAll,
        handleCounterRemove,
    } = props;

    const columns = [
        {
            title: 'Counter ID',
            dataIndex: 'itemCode',
            key: 'itemCode',
            render: (text, data) => (
                <Input
                    name="itemCode"
                    value={text}
                    onChange={e => onCounterDataChange(e, data)}
                    onPressEnter={e => getCounterData(e, data)}
                />
            ),
        },
        {
            title: 'Counter Name',
            dataIndex: 'itemName',
            key: 'itemName',
            render: text => <Input value={text} disabled />,
        },
        {
            title: 'Counter Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: text => <Input value={text} disabled />,
        },
        {
            title: 'Deposit Balance',
            dataIndex: 'depositBalance',
            key: 'depositBalance',
            render: (text, data) => (
                <Input
                    name="depositBalance"
                    value={text}
                    onChange={e => onCounterDataChange(e, data)}
                    onPressEnter={e => handleCounterDataAdd(e)}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'deno',
            key: 'deno',
            render: () => <Button>Deno</Button>,
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, data) => (
                <Popconfirm
                    title="Remove counter?"
                    onConfirm={() => handleCounterRemove(data.key)}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button shape="circle">
                        <DeleteFilled />
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    return (
        <div>
            <Form
                layout="vertical"
                className="margin-top"
                onValuesChange={onValuesChange}
                onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'preTotal',
                        value: values?.preTotal,
                    },
                    {
                        name: 'grandTotal',
                        value: values?.grandTotal,
                    },
                    {
                        name: 'itemCode',
                        value: values?.itemCode,
                    },
                    {
                        name: 'itemName',
                        value: values?.itemName,
                    },
                    {
                        name: 'currentBalance',
                        value: values?.currentBalance,
                    },
                    {
                        name: 'journalNO',
                        value: values?.journalNO,
                    },
                    {
                        name: 'transNo',
                        value: values?.transNo,
                    },
                ]}
            >
                <div className="block-general-titled--body">
                    <Row gutter={20}>
                        <Col span={4}>Counters</Col>
                        <Col span={4}>
                            <Space>
                                <Button type="primary" onClick={handleFillAll}>
                                    Fill all
                                </Button>
                                <Button type="primary" onClick={handleNew}>
                                    Clear
                                </Button>
                            </Space>
                        </Col>
                        <Col span={4}></Col>
                        <Col className="form-item-inline" span={6}>
                            <Form.Item
                                label="Trans No:"
                                name="transNo"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col className="form-item-inline" span={6}>
                            <Form.Item
                                label="Journal No:"
                                name="journalNO"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '1rem' }}>
                        <Table
                            size="small"
                            dataSource={counterData}
                            columns={columns}
                            pagination={false}
                        />
                    </div>
                    <Row gutter={20}>
                        <Col span={6}></Col>
                        <Col span={6}></Col>
                        <Col span={6}>
                            <Form.Item label="Total Cash"></Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="preTotal">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Cash Account"
                                name="itemCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select placeholder="Select...">
                                    {cashItems.length &&
                                        cashItems.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.itemCode}
                                            >
                                                {item.itemCode}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Cash Account Name"
                                name="itemName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select placeholder="Select...">
                                    {cashItems.length &&
                                        cashItems.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.itemName}
                                            >
                                                {item.itemName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="Current Balance"
                                name="currentBalance"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Deposit Balance"
                                name="grandTotal"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue(
                                                    'currentBalance'
                                                ) >= value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('Amount overflow')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space style={{ marginTop: '1rem' }}>
                        <Button htmlType="submit" disabled={isProcessing}>
                            Save
                        </Button>
                        <Button onClick={handleNew}>New</Button>
                        <Button>Deno</Button>
                        <Button>Print</Button>
                    </Space>
                </div>
            </Form>
        </div>
    );
}
