import React from 'react';
import { Form, Select, Input, Row, Col, Button } from 'antd';
// Import utils
import { GET_PROVINCES } from '../../../../utils/axios';

export default function AddressDetailForm(props) {
    // Destructing
    const {
        mode,
        provinces,
        setProvinces,
        districtsTmp,
        districtsPer,
        municipalitiesTmp,
        municipalitiesPer,
        handleAddressSameAsPermanent,
    } = props;

    // On component mount
    React.useEffect(() => {
        // Get provinces
        !provinces?.length &&
            (async () => {
                const provinces = await GET_PROVINCES();
                setProvinces(provinces);
            })();
    }, [provinces?.length, setProvinces]);

    return (
        <div className="address-detail-form">
            <h3 className="colored-title permanent-address-title">
                Permanent Address
            </h3>

            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="Province."
                        name="province"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provinces?.map((province, i) => (
                                <Select.Option
                                    key={i}
                                    value={province.provinceName}
                                >
                                    {province.provinceName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="District"
                        name="district"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {districtsPer?.map((district, i) => (
                                <Select.Option
                                    key={i}
                                    value={district.districtName}
                                >
                                    {district.districtName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="VDC/Municipality"
                        name="vdcMun"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {municipalitiesPer?.map((mun, i) => (
                                <Select.Option
                                    key={i}
                                    value={mun.municipalName}
                                >
                                    {mun.municipalName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Form.Item
                        label="Ward No."
                        name="wardNO"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="प्रदेश"
                        name="unProvince"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provinces?.map((province, i) => (
                                <Select.Option
                                    key={i}
                                    value={province.provinceNepali}
                                >
                                    {province.provinceNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="जिल्ला"
                        name="unDistrict"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {districtsPer?.map((district, i) => (
                                <Select.Option
                                    key={i}
                                    value={district.districtNepali}
                                >
                                    {district.districtNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label="गाउपालिका/नगरपालिका"
                        name="unvdc"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {municipalitiesPer?.map((mun, i) => (
                                <Select.Option
                                    key={i}
                                    value={mun.municipalNepali}
                                >
                                    {mun.municipalNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={3}>
                    <Form.Item
                        label="वडा न."
                        name="unWardNO"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Tole" name="tole">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="टोल" name="unTole">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Block No." name="blockNO">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
            </Row>
            <h3 className="colored-title">Temporary Address</h3>
            {mode !== 'view' && (
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                className="ant-btn-secondary"
                                size="small"
                                onClick={() =>
                                    handleAddressSameAsPermanent(mode)
                                }
                            >
                                Set same as Permanent address
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Province." name="tmpProvince">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provinces?.map((province, i) => (
                                <Select.Option
                                    key={i}
                                    value={province.provinceName}
                                >
                                    {province.provinceName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="District" name="tmpDistrict">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {districtsTmp?.map((district, i) => (
                                <Select.Option
                                    key={i}
                                    value={district.districtName}
                                >
                                    {district.districtName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="VDC/Municipality" name="tmpVDCMun">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {municipalitiesTmp?.map((mun, i) => (
                                <Select.Option
                                    key={i}
                                    value={mun.municipalName}
                                >
                                    {mun.municipalName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Form.Item label="Ward No." name="tmpWardNO">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="प्रदेश" name="untProvince">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provinces?.map((province, i) => (
                                <Select.Option
                                    key={i}
                                    value={province.provinceNepali}
                                >
                                    {province.provinceNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="जिल्ला" name="untDistrict">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {districtsTmp?.map((district, i) => (
                                <Select.Option
                                    key={i}
                                    value={district.districtNepali}
                                >
                                    {district.districtNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="गाउपालिका/नगरपालिका" name="untvdc">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {municipalitiesTmp?.map((mun, i) => (
                                <Select.Option
                                    key={i}
                                    value={mun.municipalNepali}
                                >
                                    {mun.municipalNepali}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={3}>
                    <Form.Item label="वडा न." name="untWardNO">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Tole" name="tmpTole">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="टोल" name="untTole">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item label="Block No." name="tmpBlockNO">
                        <Input placeholder="Enter..." />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
