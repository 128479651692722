import React from 'react';
// Import from dependencies
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherAccounts as otherAccountsAtom,
    otherItems as otherItemsAtom,
} from '../../../recoil/atoms';
// Import utils
import axios, {
    GET_MEMBERS_BY_BRANCH,
    GET_MEMBER_DEPOSIT_INFO_SHORT,
    GET_REMITS,
} from '../../../utils/axios';
import moment from 'moment';
import {
    SET_DEFAULT_VALUES,
    NEP_TO_ENG_ISO,
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
// Import components
import RemitSendingForm from '../../../components/Remittance/RemitSending/RemitSendingForm/RemitSendingForm';

export default function RemitPayment() {
    // Global states
    const [remits, setRemits] = React.useState([]);
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [isProcessing, setIsProcessing] = React.useState(false);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [members, setMembers] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'remitID' || key === 'remitName') {
            const remit = remits.find(remit => remit[key] === value);
            console.log('remit: ', remit);
            updateValues({
                remitID: remit.remitID,
                remitName: remit.remitName,
                remitAddress: remit.address,
                senderName: remit.remitName,
            });
            // handleNew();
        } else if (key === 'memberNO' || key === 'memberName') {
            const member = members.find(member => member[key] === value);
            console.log('member: ', member);
            updateValues(member);
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);
            console.log('acc: ', acc);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherName', 'otherNo']),
                otherAcno: acc.acno,
                otherAcName: acc.acName,
                otherMano: acc.mano,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'transDate') {
            return updateValues({ [key]: NEP_TO_ENG_ISO(value) });
        } else if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            paidBy: 'Self',
            isClearance: false,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = val => {
        // console.log(viewValues, 'viewValues');
        // console.log(val, 'val');
        setIsProcessing(true);
        const payload = {
            transDate: viewValues.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType: 'Remittance Transaction',
            description: val.description,
            creditTrans: [
                {
                    mano: '120',
                    acno: '120.15',
                    bvrcno: viewValues?.remitCode,
                    itemCode: val.remitID,
                    itemName: val.remitName,
                    itemLocation: viewValues?.remitAddress,
                    receivedPaidBy: viewValues?.senderName,
                    remarks1: '',
                    remarks2: viewValues?.receiverName,
                    remarks3: viewValues?.mobileNo,
                    remarks4: viewValues?.remitCode,
                    rate: viewValues?.preTotal,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: val.preTotal,
                    quantity: '1',
                    particulars: `Remit Transfered`,
                },
            ],
            debitTrans: [
                ...(val.isCash
                    ? [
                          {
                              mano: '080',
                              acno: '080.01',
                              bvrcno: val.cashBvrcno,
                              itemCode: val.cashNo,
                              itemName: val.cashName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.senderName,
                              remarks1: '0',
                              rate: val.cashAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: val.cashAmount,
                              quantity: '1',
                              particulars: `Cash remit sent`,
                          },
                      ]
                    : []),
                ...(val.isBank
                    ? [
                          {
                              mano: bankAccounts[0].mano,
                              acno: bankAccounts[0].acno,
                              bvrcno: val.bankBvrcno,
                              itemCode: val.bankNo,
                              itemName: val.bankName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.senderName,
                              remarks1: '0',
                              rate: val.bankAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: val.bankAmount,
                              quantity: '1',
                              particulars: `${val.bankName} remit sent`,
                          },
                      ]
                    : []),
                ...(val.isOther
                    ? [
                          {
                              mano: viewValues.otherMano,
                              acno: viewValues.otherAcno,
                              bvrcno: '',
                              itemCode: val.otherNo,
                              itemName: val.otherName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.senderName,
                              remarks1: '0',
                              rate: val.otherAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: val.otherAmount,
                              quantity: '1',
                              particulars: `${viewValues.otherAcName} remit sent`,
                          },
                      ]
                    : []),
            ],
        };
        console.log('payload: ', payload);
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/RemitSending`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to process remit sending');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues('view');
        setIsProcessing(false);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        members,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        remits,
    };

    // Get member detail
    React.useEffect(() => {
        if (viewValues?.memberNO) {
            (async () => {
                const memberDetail = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    viewValues?.memberNO
                );
                console.log('memberDetail: ', memberDetail);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...memberDetail,
                }));
            })();
        }
    }, [viewValues?.memberNO]);

    // Get members by branch
    React.useEffect(() => {
        if (viewValues?.branchID) {
            // Get members by branch
            (async () => {
                const members = await GET_MEMBERS_BY_BRANCH(
                    viewValues?.branchID
                );
                setMembers(members);
            })();
        }
    }, [viewValues?.branchID]);

    // Get Current share
    React.useEffect(() => {
        if (viewValues?.remitID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/CheckBalance/ItemBalance/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .branchID
                    }/120/120.15/${viewValues.remitID}`
                )
                .then(res => {
                    console.log(res);
                    setViewValues(prevValues => ({
                        ...prevValues,
                        oldBalance: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to get Current share amount');
                    message.error('Failed to get Current share amount');
                });
        }
    }, [viewValues?.remitID]);

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Get and set Remits
    React.useEffect(() => {
        (async () => {
            const remits = await GET_REMITS(viewValues?.branchID);
            setRemits(remits);
        })();
    }, [setRemits, viewValues?.branchID]);

    return (
        <>
            <div className="general-account">
                <div className="">
                    <div className="block-general-titled">
                        <h4 className="block-general-titled--title">
                            Remit Sending
                        </h4>
                        <RemitSendingForm
                            {...formProps}
                            mode="view"
                            values={viewValues}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
